import * as React from "react";
import { Link, Route, Routes } from "react-router-dom";
import HolidayPage from "./pages/HolidayPage";
import AgencyActionsPage from "./pages/AgencyActionsPage";
import HotelActionsPage from "./pages/HotelActionsPage";
import HotelOwnerPage from "./pages/HotelOwnerPage";
import TouroperatorActionsPage from "./pages/TouroperatorActionsPage";
import TopsellerPage from "./pages/TopsellerPage";
import TeaserPage from "./pages/TeaserPage";
import SuggestionsPage from "./pages/SuggestionsPage";
import GiataInfosPage from "./pages/GiataInfosPage";
import GiataDataPage from "./pages/GiataDataPage";
import OperatorRoomnamesPage from "./pages/OperatorRoomnamesPage";
import AgencyRoomnamesPage from "./pages/AgencyRoomnamesPage";
import GalleryPage from "./pages/GalleryPage";
import SettingsPage from "./pages/SettingsPage";
import ContainerPage from "./pages/ContainerPage";
import MouseoverPage from "./pages/MouseoverPage";
import TranslationPage from "./pages/TranslationPage";
import ShortcutPage from "./pages/ShortcutPage";
import WebaccessPage from "./pages/WebaccessPage";
import HotellistsPage from "./pages/HotellistsPage";
import FaqsPage from "./pages/FaqsPage";
import OfferstopperPage from "./pages/OfferstopperPage";
import CouponPage from "./pages/CouponPage";
import UserPage from "./pages/UserPage";
import AllUserPage from "./pages/AllUserPage";
import ScanPage from "./pages/ScanPage";
import HelpPage from "./pages/HelpPage";
import StatisticPage from "./pages/StatisticPage";
import TouroperatorPage from "./pages/TouroperatorPage";
import AirportsPage from "./pages/AirportsPage";
import AirlinesPage from "./pages/AirlinesPage";
import BookingsPage from "./pages/BookingsPage";
import AskOpenaiPage from "./pages/AskOpenaiPage";
import LogfilesPage from "./pages/LogfilesPage";
import HelpadminPage from "./pages/HelpadminPage";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";



import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  CircularProgress,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { changeAgency, logout } from "./features/systemSlice";
import { clearData } from "./features/dataSlice";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import PerformancelogPage from "./pages/PerformancelogPage";
import { UnfoldMoreDoubleSharp } from "@mui/icons-material";
import { getAgencyproperties } from "./features/dataApiCalls";


const modules = [
  { label: "Gallery", link: "gallery", toplevel: "Agency", roles: "user,agencyadmin,admin,master" },
  { label: "HotelLists", link: "hotellists", toplevel: "Agency", roles: "agencyadmin,admin,master" },
  { label: "AgencyActions", link: "agencyactions", toplevel: "Agency", roles: "agencyadmin,admin,master" },
  { label: "Deeplink Tool", link: "topseller", toplevel: "Agency", roles: "agencyadmin,admin,master" },
  { label: "Favorite Destinations", link: "teaser", toplevel: "Agency", roles: "agencyadmin,admin,master" },
  { label: "SuggestBox Plus", link: "suggestions", toplevel: "Agency", roles: "agencyadmin,admin,master" },
  { label: "Stopper", link: "offerstopper", toplevel: "Agency", roles: "agencyadmin,admin,master" },
  { label: "Coupons", link: "coupons", toplevel: "Agency", roles: "agencyadmin,admin,master" },
  { label: "FAQs", link: "faqs", toplevel: "Agency", roles: "agencyadmin,admin,master" },
  { label: "GiataHotelData", link: "giatadata", toplevel: "Agency", roles: "agencyadmin,admin,master" },
  { label: "Mouseovers/Infotexte", link: "mouseovers", toplevel: "Agency", roles: "agencyadmin,admin,master" },
  { label: "Shortcuts", link: "shortcuts", toplevel: "Agency", roles: "agencyadmin,admin,master" },
  { label: "Employees", link: "user", toplevel: "Agency", roles: "agencyadmin,admin,master" },
  { label: "Container", link: "container", toplevel: "Agency", roles: "agencyadmin,admin,master" },
  { label: "Settings", link: "settings", toplevel: "Agency", roles: "agencyadmin,admin,master" },

  { label: "TouroperatorActions", link: "touroperatoractions", toplevel: "Public", roles: "admin,master" },
  { label: "HotelActions", link: "hotelactions", toplevel: "Public", roles: "admin,master" },
  { label: "Hotels", link: "hotelowner", toplevel: "Public", roles: "master,hotelowner" },
  { label: "PublicHotelLists", link: "hotellistspublic", toplevel: "Public", roles: "admin,master" },


  { label: "GiataInfos", link: "giatainfos", toplevel: "Infos", roles: "agencyadmin,admin,master" },
  { label: "Touroperators", link: "touroperators", toplevel: "Infos", roles: "agencyadmin,admin,master" },
  { label: "Airlines", link: "airlines", toplevel: "Infos", roles: "agencyadmin,admin,master" },
  { label: "Airports", link: "airports", toplevel: "Infos", roles: "agencyadmin,admin,master" },
  { label: "Holidays", link: "holidays", toplevel: "Infos", roles: "agencyadmin,admin,master" },
  { label: "WebAccess", link: "webaccess", toplevel: "Infos", roles: "agencyadmin,admin,master" },
  { label: "Bookings", link: "bookings", toplevel: "Infos", roles: "agencyadmin,admin,master" },
  { label: "Statistics", link: "statistics", toplevel: "Infos", roles: "agencyadmin,admin,master" },

  { label: "Translations", link: "translations", toplevel: "Editorial", roles: "admin,master" },
  { label: "OperatorRoomnames", link: "operatorroomnames", toplevel: "Editorial", roles: "admin,master" },
  { label: "AgencyRoomnames", link: "agencyroomnames", toplevel: "Editorial", roles: "admin,master" },
  { label: "HelpText", link: "helpadmin", toplevel: "Editorial", roles: "master" }, 

  { label: "PerformanceLog", link: "performancelog", toplevel: "Supervisor", roles: "master" },
  { label: "Logfiles", link: "logfiles", toplevel: "Supervisor", roles: "master" },
  { label: "Chat", link: "openai", toplevel: "Supervisor", roles: "master" },
  { label: "allUser", link: "alluser", toplevel: "Supervisor", roles: "master" },
  { label: "QR", link: "qrcode", toplevel: "Supervisor", roles: "master" },
];

const toplevel = [];
for (let i = 0; i < modules.length; i++) {
  if (!modules[i].toplevel) continue;
  if (!toplevel.includes(modules[i].toplevel)) {
    toplevel.push(modules[i].toplevel);
  }
}

export default function App(props) {
  //test
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.system.loading);
  const agencies = useSelector((state) => state.system.agencies);
  const token = useSelector((state) => state.system.token);
  const nickname = useSelector((state) => state.system.nickname);
  const role = useSelector((state) => state.system.role);
  const agencyname = useSelector((state) => state.system.agencyname);

  const [showNavDrawer, setShowNavDrawer] = React.useState(false);

  const [openLevel, setOpenLevel] = React.useState("");

  const [activeModule, setActiveModule] = React.useState("mainmenue");

  const navigatefromdrawer = (module) => {
    navigate("/" + module.link);
    setActiveModule(module.label);
    setShowNavDrawer(false);
  };

  const [showLogout, setShowLogout] = React.useState(false);
  const [showHelp, setShowHelp] = React.useState(false);
  return (
    <div className="App">
      
      <Dialog
        fullWidth={true}
        open={showLogout}
        onClose={() => setShowLogout(false)}
      >
        <DialogTitle>Logout</DialogTitle>
        <DialogContent>Logout from system?</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(logout());
              dispatch(clearData());
              navigate("/");
              setActiveModule("mainmenue");
              setShowLogout(false);
            }}
          >
            logout
          </Button>

          <Button
            onClick={() => {
              setShowLogout(false);
            }}
          >
            cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        open={showHelp}
        onClose={() => setShowHelp(false)}
      >
        <DialogTitle><span   style={{ color: "lightgrey", fontSize: 18 }} >Hilfe für Modul</span> <span   style={{ color: "darkgrey", fontSize: 18 }}>{activeModule}</span></DialogTitle>
        <DialogContent><HelpPage module={activeModule}/> </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
             setShowHelp(false);
            }}
          >
            OK
          </Button>

       
        </DialogActions>
          </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 100001 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ flexGrow: 1 }}>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                if (token) setShowNavDrawer(!showNavDrawer);
              }}
            >
              <MenuIcon />
            </IconButton>

            <Typography variant="h6" component="div">
              HoliAdmin
            </Typography>
            <Typography component="div" sx={{ flexGrow: 1 }}>
              &nbsp;{token ? "/" : ""} {token ? agencyname : null}
              {" ->> "} {token ? nickname : null} {token ? "(" + role + ")" : null} {activeModule ? "/" : ""}{" "}
              {activeModule} &nbsp;&nbsp;
              {token && agencies.length > 1 && (
                <Select
                  labelId="select-typesl"
                  id="select-types"
                  variant="standard"
                  onChange={(evt) => {
                    console.log(evt.target.value);
                    dispatch(clearData());
                    dispatch(
                      changeAgency({
                        ...agencies.find(
                          (e) => e.agencyid === evt.target.value
                        ),
                      })
                    );
                    dispatch(getAgencyproperties());
                    navigate("/home")
                  }}
                >
                  {agencies.map((o) => (
                    <MenuItem key={o.agencyid} value={o.agencyid}>
                      {o.agencyname}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Typography>

            {token && (
              <>
              {role=="master" && 
                <Button
                  color="inherit"
                  onClick={() => {
                    setShowHelp(true);
                  }}
                >
                  Hilfe
                </Button>}
                <Button
                  color="inherit"
                  onClick={() => {
                    setShowLogout(true);
                  }}
                >
                  Logout
                </Button>
              </>
            )}
            {!token && (
              <>
                <Button color="inherit" onClick={() => navigate("/signup")}>
                  Signup
                </Button>
                <Button color="inherit" onClick={() => navigate("/")}>
                  Login
                </Button>
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer

        anchor={"left"}
        open={showNavDrawer}
        onClose={() => setShowNavDrawer(false)}
      >
        {token && (
          <List dense style={{ width: 300 }}>
            {toplevel.map((tl, i) => {
              return (
                <>
                  <ListItem key={tl}>
                    <ListItemButton
                      onClick={() => setOpenLevel(openLevel === tl ? "" : tl)}
                    >
                      <>{openLevel !== tl ? "+" : "-"} &nbsp;</>
                      {tl}
                    </ListItemButton>
                  </ListItem>
                  {modules.map((mod, i) => {
                    return (
                      mod.toplevel === openLevel &&
                      mod.roles.indexOf(role) > -1 &&
                      tl === openLevel && (
                        <ListItem key={i}>
                          <ListItemButton
                            onClick={() => navigatefromdrawer(mod)}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{mod.label}
                          </ListItemButton>
                        </ListItem>
                      )
                    );
                  })}
                </>
              );
            })}
            {modules.map((mod, i) => {
              return (
                !mod.toplevel &&
                mod.roles.indexOf(role) > -1 &&
                (
                  <ListItem key={i}>
                    <ListItemButton onClick={() => navigatefromdrawer(mod)}>
                      {mod.label}
                    </ListItemButton>
                  </ListItem>
                )
              );
            })}
          </List>
        )}
      </Drawer>

      <div style={{ marginTop: 72, paddingLeft: 8, paddingRight: 8 }}>
        <Routes>
          <Route
            path="/home"
            element={<><br /><br /><br /><br /><br /><br /><h1><center>H&nbsp; O&nbsp; M&nbsp; E&nbsp; &nbsp;&nbsp;&nbsp;   O&nbsp; F  &nbsp;&nbsp; &nbsp;&nbsp;  A&nbsp; D&nbsp; M &nbsp;I&nbsp; N</center></h1></>}
          />
          <Route
            path="/"
            element={<LoginPage setActiveModule={setActiveModule} />}
          />
          <Route
            path="/shortcuts"
            element={
              <ProtectedRoute>
                <ShortcutPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/translations"
            element={
              <ProtectedRoute>
                <TranslationPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/logfiles"
            element={
              <ProtectedRoute>
                <LogfilesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/faqs"
            element={
              <ProtectedRoute>
                <FaqsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/agencyactions"
            element={
              <ProtectedRoute>
                <AgencyActionsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hotelactions"
            element={
              <ProtectedRoute>
                <HotelActionsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/touroperatoractions"
            element={
              <ProtectedRoute>
                <TouroperatorActionsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/topseller"
            element={
              <ProtectedRoute>
                <TopsellerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/teaser"
            element={
              <ProtectedRoute>
                <TeaserPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/suggestions"
            element={
              <ProtectedRoute>
                <SuggestionsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/statistics"
            element={
              <ProtectedRoute>
                <StatisticPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/helpadmin"
            element={
              <ProtectedRoute>
                <HelpadminPage />
              </ProtectedRoute>
            }
          />          
          <Route
            path="/qrcode"
            element={
              <ProtectedRoute>
                <ScanPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/giatainfos"
            element={
              <ProtectedRoute>
                <GiataInfosPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/giatadata"
            element={
              <ProtectedRoute>
                <GiataDataPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/touroperators"
            element={
              <ProtectedRoute>
                <TouroperatorPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/openai"
            element={
              <ProtectedRoute>
                <AskOpenaiPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/airports"
            element={
              <ProtectedRoute>
                <AirportsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/airlines"
            element={
              <ProtectedRoute>
                <AirlinesPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/hotellists"
            element={
              <ProtectedRoute>
                <HotellistsPage publiclist={false} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hotellistspublic"
            element={
              <ProtectedRoute>
                <HotellistsPage publiclist={true} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/operatorroomnames"
            element={
              <ProtectedRoute>
                <OperatorRoomnamesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/agencyroomnames"
            element={
              <ProtectedRoute>
                <AgencyRoomnamesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/holidays"
            element={
              <ProtectedRoute>
                <HolidayPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/coupons"
            element={
              <ProtectedRoute>
                <CouponPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/hotelowner"
            element={
              <ProtectedRoute>
                <HotelOwnerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/gallery"
            element={
              <ProtectedRoute>
                <GalleryPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bookings"
            element={
              <ProtectedRoute>
                <BookingsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mouseovers"
            element={
              <ProtectedRoute>
                <MouseoverPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user"
            element={
              <ProtectedRoute>
                <UserPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/alluser"
            element={
              <ProtectedRoute>
                <AllUserPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/performancelog"
            element={
              <ProtectedRoute>
                <PerformancelogPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/webaccess"
            element={
              <ProtectedRoute>
                <WebaccessPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <SettingsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/container"
            element={
              <ProtectedRoute>
                <ContainerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/offerstopper"
            element={
              <ProtectedRoute>
                <OfferstopperPage />
              </ProtectedRoute>
            }
          />
          <Route path="/signup" element={<SignupPage />} />
        </Routes>
      </div>
    </div>
  );
}
