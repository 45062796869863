import * as React from "react";
import Button from "@mui/material/Button";
import { FormattedDate, FormattedTime } from "react-intl";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select
} from "@mui/material";
//https://mui.com/x/api/data-grid/data-grid/
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from "react-redux";
import { getStatistics, getStatistic } from "../features/dataApiCalls";


export default function StatisticPage(props) {
  const dispatch = useDispatch();

  const statisticresult = useSelector((state) => state.data.statisticresult);
  const statistics = useSelector((state) => state.data.statistics);
  const [currentstat, setCurrentstat] = React.useState("");

  let rows = [];
  let columns = [];
  let currentStatistic = "";
  let fields = [];

  let tmp = statistics.map((o, i) => {
    if (o.id === currentstat) {
      currentStatistic = o.label;
      if (o.properties?.fields) fields = o.properties.fields
    }
  })

  if (Array.isArray(statisticresult) && statisticresult.length > 0) {
    let keys = Object.keys(statisticresult[0]);
    console.log(keys);
    rows = statisticresult;
    if (fields.length == 0) {
      for (var i = 0; i < keys.length; i++) {
        columns.push({ "field": keys[i], "headerName": keys[i], "autosizeOnMount": true, "autoPageSize": true, "autoHeight": true })
      }
    }
    else
    {
      columns = fields;
    }
  }



  React.useEffect(() => {
    dispatch(getStatistics());
  }, []);
  let odd = true;
  console.log(statisticresult);
  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <div style={{ marginLeft: 16 }}>
          <Select
            fullWidth
            labelId="select-typesl"
            id="select-types"
            value={statistics}
            variant="standard"
            onChange={(evt) => {
              setCurrentstat(evt.target.value);
            }}
          >
            {statistics.map((o) => (
              <MenuItem key={o.id} value={o.id}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div style={{ marginLeft: 16 }}>
          {currentStatistic}
          <Button
            style={{ marginBottom: 16 }}
            onClick={() => dispatch(getStatistic({ "id": currentstat }))}
            variant="contained"
          >
            Get Data
          </Button>
        </div>

      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div style={{ height: '100%', width: '100%' }}>
          <DataGrid rows={rows} columns={columns} />
        </div>
      </Paper>
    </>
  );
}
