import {
  createSlice,
  createSelector,
  isPending,
  isRejected,
  isFulfilled,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import {
  getAgencyactions,
  getHotelactions,
  getAgencyRoomNames,
  getCityInfo,
  getHotellists,
  getHotellistitems,
  getFilelist,
  getFiles,
  getFolders,
  getGiatainfos,
  getHolidays,
  getProperty,
  getContainer,
  getRoomNames,
  getTopSellerInfos,
  getTeaserInfos,
  getTouroperatoractions,
  getAllbookings,
  searchFile,
  getMouseovertexts,
  getPerformancelog,
  getPictures,
  getSuggestions,
  getShortcuts,
  getFaqs,
  getTranslations,
  getAgencyproperties,
  getWebaccess,
  getOfferstopper,
  getCoupons,
  getCouponcodes,
  getUsers,
  getAllUsers,
  getTouroperators,
  getAirports,
  getAirlines,
  askOpenAI,
  getLogfiles,
  readLogfile,
  getLogpaths,
  getStatistics,
  getStatistic,
  getSystemhelp,
} from "./dataApiCalls";

const initialState = {
  chatanswer: "",
  holidays: [],
  agencyactions: [],
  hotelactions: [],
  hotellists: [],
  hotellistitems: [],
  touroperatoractions: [],
  giatainfos: [],
  topsellers: [],
  suggestions: [],
  offerstopper: [],
  operatorroomnames: [],
  agencyroomnames: [],
  agencyproperties: {},
  filelist: [],
  properties: {},
  searchedfiles: [],
  folders: [],
  files: [],
  faqs: [],
  pictures: {},
  performancelog: [],
  openedfolders: [],
  mouseovertexts: [],
  shortcuts: [],
  translations: [],
  webaccess: [],
  container: [],
  users: [],
  allusers: [],
  bookings: [],
  coupons: [],
  couponcodes: [],
  logfiles: [],
  logfile :"",
  logpaths : [],
  statistics : [],
  statisticresult : [],
  systemhelp : [],
  logpath:""
};

export const dataSlice = createSlice({
  name: "data",
  initialState,

  reducers: {

    clearData: (state, action) => {
      state.holidays = [];
      state.agencyactions = [];
      state.hotelactions = [];
      state.touroperatoractions = [];
      state.giatainfos = [];
      state.hotellists = [];
      state.hotellistitems = [];
      state.topsellers = [];
      state.teasers = [];
      state.suggestions = [];
      state.operatorroomnames = [];
      state.agencyroomnames = [];
      state.agencyproperties = {};
      state.filelist = [];
      state.offerstopper = [];
      state.searchedfiles = [];
      state.folders = [];
      state.files = [];
      state.faqs = [];
      state.pictures = [];
      state.performancelog = [];
      state.openedfolders = [];
      state.mouseovertexts = [];
      state.shortcuts = [];
      state.translations = [];
      state.bookings = [];
      state.webaccess = [];
      state.container = [];
      state.users = [];
      state.allusers = [];
      state.touroperators = [];
      state.airports = [];
      state.airlines = [];
      state.coupons = [];
      state.couponcodes = [];
      state.logfiles = [];
      state.logfile = "";
      state.logpaths = [];
      state.statistics = [];
      state.statisticresult = [];
      state.systemhelp = [];
      state.logpath = "";
      state.userhash = "";

    },
    setTranslations: (state, action) => {
      state.translations = action.payload;
    },
    setContainer: (state, action) => {
      state.container = action.payload;
    },
    setAgencyproperties: (state, action) => {
      state.agencyproperties = action.payload;
    },
    setBookings: (state, action) => {
      state.bookings = action.payload;
    },
    setShortcuts: (state, action) => {
      state.shortcuts = action.payload;
    },
    setMouseovertexts: (state, action) => {
      state.mouseovertexts = action.payload;
    },
    setGiatainfos: (state, action) => {
      state.giatainfos = action.payload;
    },
    setTopsellers: (state, action) => {
      state.topsellers = action.payload;
    },
    setTeasers: (state, action) => {
      state.teasers = action.payload;
    },
    setSuggestions: (state, action) => {
      state.suggestions = action.payload;
    },
    setOfferstopper: (state, action) => {
      state.offerstopper = action.payload;
    },
    setCoupons: (state, action) => {
      state.coupons = action.payload;
    },
    setCouponcodes: (state, action) => {
      state.couponcodes = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setAllUsers: (state, action) => {
      state.allusers = action.payload;
    },
    setOperatorRoomnames: (state, action) => {
      state.operatorroomnames = action.payload;
    },
    setAgencyRoomnames: (state, action) => {
      state.agencyroomnames = action.payload;
    },
    setAgencyactions: (state, action) => {
      state.agencyactions = action.payload;
    },
    setHotelactions: (state, action) => {
      state.hotelactions = action.payload;
    },
    setHotellists: (state, action) => {
      state.hotellists = action.payload;
    },
    setTouroperatoractions: (state, action) => {
      state.touroperatoractions = action.payload;
    },
    setOpenedfolders: (state, action) => {
      state.openedfolders = action.payload;
    },
    setFolders: (state, action) => {
      state.folders = action.payload;
    },
    setFaqs: (state, action) => {
      state.faqs = action.payload;
    },
    setLogpath: (state, action) => {
      state.logpath = action.payload;
    },  
    setLogfile: (state, action) => {
      state.logfile = action.payload;
    },    
    setSystemhelp: (state, action) => {
      state.systemhelp = action.payload;
    },  
    setFiles: (state, action) => {
      state.files = action.payload;
    },              
  },
  extraReducers: (builder) => {
    builder.addCase(searchFile.pending, (state, action) => {
      state.searchedfiles = [];
    });
    builder.addCase(searchFile.rejected, (state, action) => {
      state.searchedfiles = [];
    });
    builder.addCase(searchFile.fulfilled, (state, { payload, meta }) => {
      state.searchedfiles = payload.ret;
    });

    builder.addCase(getFolders.pending, (state, action) => { });
    builder.addCase(getFolders.rejected, (state, action) => {
      state.folders = [];
    });
    builder.addCase(getFolders.fulfilled, (state, { payload, meta }) => {
      let ids = payload.ret.map((e) => e.folderid);
      let tmp = [...state.folders];
      tmp = tmp.filter((e) => !ids.includes(e.folderid));
      console.log(payload.ret);
      state.folders = [...tmp, ...payload.ret];
    });

    builder.addCase(getOfferstopper.pending, (state, action) => {
      state.offerstopper = [];
    });
    builder.addCase(getOfferstopper.rejected, (state, action) => {
      state.offerstopper = [];
    });
    builder.addCase(getOfferstopper.fulfilled, (state, { payload, meta }) => {
      if (Array.isArray(payload.data) && payload.data.length > 0)
      {
      state.offerstopper = [...payload.data, { agencyid: payload.data[0].agencyid, properties: {}, mobilehtml: "<b>MobileHtml</>", desktophtml: "<b>DesktopHtml</b>", id: "new" }];
      }
      else{
        state.offerstopper = [];
      }
    });


    builder.addCase(getLogpaths.pending, (state, action) => {
      state.logpaths = [];
    });
    builder.addCase(getLogpaths.rejected, (state, action) => {
      state.logpaths = [];
    });
    builder.addCase(getLogpaths.fulfilled, (state, { payload, meta }) => {
      state.logpaths = [...payload.data];
    });


    builder.addCase(getSystemhelp.pending, (state, action) => {
      state.systemhelp = [];
    });
    builder.addCase(getSystemhelp.rejected, (state, action) => {
      state.systemhelp = [];
    });
    builder.addCase(getSystemhelp.fulfilled, (state, { payload, meta }) => {
      state.systemhelp = [...payload.data];
    });



    builder.addCase(getStatistics.pending, (state, action) => {
      state.statistics = [];
    });
    builder.addCase(getStatistics.rejected, (state, action) => {
      state.statistics = [];
    });
    builder.addCase(getStatistics.fulfilled, (state, { payload, meta }) => {
      state.statistics = payload.data;
    });


    builder.addCase(getStatistic.pending, (state, action) => {
      state.statisticresult = [];
    });
    builder.addCase(getStatistic.rejected, (state, action) => {
      state.statisticresult = [];
    });
    builder.addCase(getStatistic.fulfilled, (state, { payload, meta }) => {
      state.statisticresult = payload.data;
    });


    builder.addCase(getLogfiles.pending, (state, action) => {
      state.logfiles = [];
    });
    builder.addCase(getLogfiles.rejected, (state, action) => {
      state.logfiles = [];
    });
    builder.addCase(getLogfiles.fulfilled, (state, { payload, meta }) => {
      state.logfiles = [...payload.data];
    });

    builder.addCase(readLogfile.pending, (state, action) => {
      state.logfile = [];
    });
    builder.addCase(readLogfile.rejected, (state, action) => {
      state.logfile = [];
    });
    builder.addCase(readLogfile.fulfilled, (state, { payload, meta }) => {
      state.logfile = [...payload.data.content];
    });

    builder.addCase(getCoupons.pending, (state, action) => {
      state.coupons = [];
    });
    builder.addCase(getCoupons.rejected, (state, action) => {
      state.coupons = [];
    });
    builder.addCase(getCoupons.fulfilled, (state, { payload, meta }) => {
      state.coupons = [...payload.data];
    });

    builder.addCase(getCouponcodes.pending, (state, action) => {
      state.couponcodes = [];
    });
    builder.addCase(getCouponcodes.rejected, (state, action) => {
      state.couponcodes = [];
    });
    builder.addCase(getCouponcodes.fulfilled, (state, { payload, meta }) => {
      state.couponcodes = [...payload.data];
    });



    builder.addCase(getUsers.pending, (state, action) => {
      state.users = [];
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.users = [];
    });
    builder.addCase(getUsers.fulfilled, (state, { payload, meta }) => {
      state.users = payload.ret;
    });



    builder.addCase(getTouroperators.pending, (state, action) => {
      state.touroperators = [];
    });
    builder.addCase(getTouroperators.rejected, (state, action) => {
      state.touroperators = [];
    });
    builder.addCase(getTouroperators.fulfilled, (state, { payload, meta }) => {
      state.touroperators = payload.ret;
    });


    builder.addCase(getAirports.pending, (state, action) => {
      state.airports = [];
    });
    builder.addCase(getAirports.rejected, (state, action) => {
      state.airports = [];
    });
    builder.addCase(getAirports.fulfilled, (state, { payload, meta }) => {
      state.airports = payload.ret;
    });



    builder.addCase(getAirlines.pending, (state, action) => {
      state.airlines = [];
    });
    builder.addCase(getAirlines.rejected, (state, action) => {
      state.airlines = [];
    });
    builder.addCase(getAirlines.fulfilled, (state, { payload, meta }) => {
      state.airlines = payload.ret;
    });


    builder.addCase(getAllUsers.pending, (state, action) => {
      state.allusers = [];
    });
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.allusers = [];
    });
    builder.addCase(getAllUsers.fulfilled, (state, { payload, meta }) => {
      state.allusers = payload.ret;
    });

    builder.addCase(getHotellists.pending, (state, action) => {
      state.hotellists = [];
    });
    builder.addCase(getHotellists.rejected, (state, action) => {
      state.hotellists = [];
    });
    builder.addCase(getHotellists.fulfilled, (state, { payload, meta }) => {
      state.hotellists = payload.ret;
    });

    builder.addCase(getHotellistitems.pending, (state, action) => {
      state.hotellistitems = [];
    });
    builder.addCase(getHotellistitems.rejected, (state, action) => {
      state.hotellistitems = [];
    });
    builder.addCase(getHotellistitems.fulfilled, (state, { payload, meta }) => {
      state.hotellistitems = payload.ret;
    });
    builder.addCase(getFiles.pending, (state, action) => {
      state.files = [];
    });
    builder.addCase(getFiles.rejected, (state, action) => {
      state.files = [];
    });
    builder.addCase(getFiles.fulfilled, (state, { payload, meta }) => {
      state.files = payload.ret;
    });

    builder.addCase(getAgencyproperties.pending, (state, action) => {
      state.agencyproperties = {};
    });
    builder.addCase(getAgencyproperties.rejected, (state, action) => {
      state.agencyproperties = {};
    });
    builder.addCase(getAgencyproperties.fulfilled, (state, { payload, meta }) => {
      if (Array.isArray(payload.ret) && payload.ret.length > 0)
        state.agencyproperties = payload.ret[0];
    });

    builder.addCase(getContainer.pending, (state, action) => {
      state.container = [];
    });
    builder.addCase(getContainer.rejected, (state, action) => {
      state.container = [];
    });
    builder.addCase(getContainer.fulfilled, (state, { payload, meta }) => {
      state.container = payload.ret;
    });


    builder.addCase(getAllbookings.pending, (state, action) => {
      state.bookings = [];
    });
    builder.addCase(getAllbookings.rejected, (state, action) => {
      state.bookings = [];
    });
    builder.addCase(getAllbookings.fulfilled, (state, { payload, meta }) => {
      state.bookings = payload.ret;
    });

    builder.addCase(getShortcuts.pending, (state, action) => {
      state.shortcuts = [];
    });
    builder.addCase(getShortcuts.rejected, (state, action) => {
      state.shortcuts = [];
    });
    builder.addCase(getShortcuts.fulfilled, (state, { payload, meta }) => {
      state.shortcuts = payload.ret;
    });

    builder.addCase(getFaqs.pending, (state, action) => {
      state.faqs = [];
    });
    builder.addCase(getFaqs.rejected, (state, action) => {
      state.faqs = [];
    });
    builder.addCase(getFaqs.fulfilled, (state, { payload, meta }) => {
      state.faqs = payload.ret;
    });



    builder.addCase(getTranslations.pending, (state, action) => {
      state.translations = [];
    });
    builder.addCase(getTranslations.rejected, (state, action) => {
      state.translations = [];
    });
    builder.addCase(getTranslations.fulfilled, (state, { payload, meta }) => {
      state.translations = payload.ret;
    });


    builder.addCase(getTeaserInfos.pending, (state, action) => {
      state.teasers = [];
    });
    builder.addCase(getTeaserInfos.rejected, (state, action) => {
      state.teasers = [];
    });
    builder.addCase(getTeaserInfos.fulfilled, (state, { payload, meta }) => {
      state.teasers = payload.ret;
    });


    builder.addCase(getSuggestions.pending, (state, action) => {
      state.suggestions = [];
    });
    builder.addCase(getSuggestions.rejected, (state, action) => {
      state.suggestions = [];
    });
    builder.addCase(getSuggestions.fulfilled, (state, { payload, meta }) => {
      state.suggestions = payload.ret;
    });


    builder.addCase(getPictures.pending, (state, action) => {
      //state.pictures = [];
    });
    builder.addCase(getPictures.rejected, (state, action) => {
      //state.pictures = [];
    });
    builder.addCase(getPictures.fulfilled, (state, { payload, meta }) => {
      state.pictures = { ...state.pictures, [meta.arg.folderid]: payload.ret };
    });
    builder.addCase(getHolidays.pending, (state, action) => {
      state.holidays = [];
    });
    builder.addCase(getHolidays.rejected, (state, action) => {
      state.holidays = [];
    });
    builder.addCase(getHolidays.fulfilled, (state, { payload, meta }) => {
      state.holidays = payload.ret;
    });

    builder.addCase(getProperty.pending, (state, action) => { });
    builder.addCase(getProperty.rejected, (state, action) => { });
    builder.addCase(getProperty.fulfilled, (state, { payload, meta }) => {
      state.properties = {
        ...state.properties,
        [meta.arg.tag]: payload.properties,
      };
    });

    builder.addCase(getMouseovertexts.pending, (state, action) => {
      state.mouseovertexts = [];
    });
    builder.addCase(getMouseovertexts.rejected, (state, action) => {
      state.mouseovertexts = [];
    });
    builder.addCase(getMouseovertexts.fulfilled, (state, { payload, meta }) => {
      state.mouseovertexts = payload.ret;
    });

    builder.addCase(getGiatainfos.pending, (state, action) => {
      state.giatainfos = [];
    });
    builder.addCase(getGiatainfos.rejected, (state, action) => {
      state.giatainfos = [];
    });
    builder.addCase(getGiatainfos.fulfilled, (state, { payload, meta }) => {
      state.giatainfos = payload.ret;
    });

    builder.addCase(getTopSellerInfos.pending, (state, action) => {
      state.topsellers = [];
    });
    builder.addCase(getTopSellerInfos.rejected, (state, action) => {
      state.topsellers = [];
    });
    builder.addCase(getTopSellerInfos.fulfilled, (state, { payload, meta }) => {

      state.topsellers = payload.ret;
    });

    builder.addCase(getRoomNames.pending, (state, action) => {
      state.operatorroomnames = [];
    });
    builder.addCase(getRoomNames.rejected, (state, action) => {
      state.operatorroomnames = [];
    });
    builder.addCase(getRoomNames.fulfilled, (state, { payload, meta }) => {
      state.operatorroomnames = payload.ret;
    });

    builder.addCase(getPerformancelog.pending, (state, action) => {
      state.performancelog = [];
    });
    builder.addCase(getPerformancelog.rejected, (state, action) => {
      state.performancelog = [];
    });
    builder.addCase(getPerformancelog.fulfilled, (state, { payload, meta }) => {
      state.performancelog = payload.ret;
    });

    builder.addCase(getWebaccess.pending, (state, action) => {
      state.webaccess = [];
    });
    builder.addCase(getWebaccess.rejected, (state, action) => {
      state.webaccess = [];
    });
    builder.addCase(getWebaccess.fulfilled, (state, { payload, meta }) => {
      state.webaccess = payload.ret;
    });

    builder.addCase(getAgencyRoomNames.pending, (state, action) => {
      state.agencyroomnames = [];
    });
    builder.addCase(getAgencyRoomNames.rejected, (state, action) => {
      state.agencyroomnames = [];
    });
    builder.addCase(
      getAgencyRoomNames.fulfilled,
      (state, { payload, meta }) => {
        state.agencyroomnames = payload.ret;
      }
    );


    builder.addCase(
      askOpenAI.fulfilled,
      (state, { payload, meta }) => {
        console.log(payload);
        state.chatanswer = payload.ret;
      }
    );

    builder.addCase(getAgencyactions.pending, (state, action) => {
      state.agencyactions = [];
    });
    builder.addCase(getAgencyactions.rejected, (state, action) => {
      state.agencyactions = [];
    });
    builder.addCase(getAgencyactions.fulfilled, (state, { payload, meta }) => {
      //payload.ret = payload.ret.map((e) => { return { ...e, properties: { ...e.properties, styles: e?.properties?.styles } } });
      console.log(payload.ret);
      state.agencyactions = payload.ret;
    });


    builder.addCase(getHotelactions.pending, (state, action) => {
      state.hotelactions = [];
    });
    builder.addCase(getHotelactions.rejected, (state, action) => {
      state.hotelactions = [];
    });
    builder.addCase(getHotelactions.fulfilled, (state, { payload, meta }) => {
      state.hotelactions = payload.ret;
    });


    builder.addCase(getTouroperatoractions.pending, (state, action) => {
      state.touroperatoractions = [];
    });
    builder.addCase(getTouroperatoractions.rejected, (state, action) => {
      state.touroperatoractions = [];
    });
    builder.addCase(
      getTouroperatoractions.fulfilled,
      (state, { payload, meta }) => {
        state.touroperatoractions = payload.ret;
      }
    );

    builder.addCase(getCityInfo.fulfilled, (state, { payload, meta }) => {
      state.giatainfos = [
        ...state.giatainfos.map((e) =>
          e.cityid === meta.arg.cityid && e.giataid === meta.arg.giataid
            ? {
              ...e,
              cityproperties: {
                ...e.cityproperties,
                [meta.arg.context]: payload.ret,
              },
            }
            : e
        ),
      ];
    });

    builder.addCase(getFilelist.pending, (state, action) => {
      state.filelist = [];
    });
    builder.addCase(getFilelist.rejected, (state, action) => {
      state.filelist = [];
    });
    builder.addCase(getFilelist.fulfilled, (state, { payload, meta }) => {
      state.filelist = payload.data;
    });
  },
});

export const {
  setGiatainfos,
  setTopsellers,
  setTeasers,
  setFaqs,
  setOperatorRoomnames,
  setAgencyRoomnames,
  setAgencyactions,
  setHotelactions,
  setHotellists,
  setHotellistitems,
  setAgencyproperties,
  setTouroperatoractions,
  setSuggestions,
  setFolders,
  setOpenedfolders,
  setMouseovertexts,
  setShortcuts,
  setTranslations,
  setContainer,
  setOfferstopper,
  setUsers,
  setAllUsers,
  setCoupons,
  setFiles,
  setLogpath,
  setLogfile,
  setSystemhelp,
  clearData,
} = dataSlice.actions;

export default dataSlice.reducer;
