import * as React from "react";
import Button from "@mui/material/Button";
import { FormattedDate } from "react-intl";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getTouroperators } from "../features/dataApiCalls";

export default function TouroperatorPage(props) {
  const dispatch = useDispatch();

  const touroperators = useSelector((state) => state.data.touroperators);
  const { tourOperators, touroperatorfilter } = props;
  const [touroperatorsort, settourOperatorsort] = React.useState({});
  let odd = true;
  React.useEffect(() => {
    dispatch(getTouroperators());
  }, []);

  return (
    <>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ width: "5%", cursor: "pointer" }}
                  onClick={() =>
                    settourOperatorsort({
                      column: "id",
                      direction: touroperatorsort.direction
                        ? touroperatorsort.direction * -1
                        : 1,
                    })
                  }
                >
                  id
                </TableCell>
                <TableCell
                  style={{ width: "25%", cursor: "pointer" }}
                  onClick={() =>
                    settourOperatorsort({
                      column: "name",
                      direction: touroperatorsort.direction
                        ? touroperatorsort.direction * -1
                        : 1,
                    })
                  }
                >
                  name
                </TableCell>
                <TableCell style={{ width: "10%" }}>active</TableCell>
                <TableCell style={{ width: "50%" }}>properties</TableCell>
                <TableCell style={{ width: "10%" }}>giata</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(touroperators) &&
                touroperators
                  .filter((o) =>
                    touroperatorfilter
                      ? 1 === 1
                      : 1 === 1
                  )

                  .sort((a, b) => {
                    if (!touroperatorsort.column) return 0;

                    return touroperatorsort.direction == 1
                      ? a[touroperatorsort.column] > b[touroperatorsort.column]
                      : a[touroperatorsort.column] < b[touroperatorsort.column];
                  }
                  )

                  .map((obj, idx) => {
                    odd = !odd;
                    return (
                      <TableRow
                        key={idx}
                        style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell style={{ width: "5%" }}>
                          {obj.id ? obj.id : ""}
                        </TableCell>
                        <TableCell style={{ width: "25%" }}>
                          {obj.name ? obj.name : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {obj.active ? obj.active : ""}
                        </TableCell>
                        <TableCell style={{ width: "50%" }}>
                          {obj.properties ? JSON.stringify(obj.properties) : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {obj.giata ? obj.giata : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
