import { Button, Grid, TextField } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { doSignup } from "../features/systemSlice";

export default function LoginPage(props) {
  const loginRef = React.useRef();
  const pwdRef = React.useRef();
  const nicknameRef = React.useRef();
  const emailRef = React.useRef();  
  const phoneRef = React.useRef();  
  const authkeyRef = React.useRef();  
  const baseagencyRef = React.useRef();  
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [showPassword,setShowPassword] = React.useState(false);

  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault();
        dispatch(
          doSignup({
            login: loginRef.current.value,
            pwd: pwdRef.current.value,
            nickname: nicknameRef.current.value,
            email: emailRef.current.value,
            phone: phoneRef.current.value,
            baseagency: baseagencyRef.current.value,
          })
        )
          .then(unwrapResult)
          .then((originalPromiseResult) => {
            //console.log(originalPromiseResult);
           
            if (originalPromiseResult.token) navigate("/agencyactions");
          })
          .catch((rejectedValueOrSerializedError) => {
            console.log(rejectedValueOrSerializedError);
          });
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={1} md={5}></Grid>{" "}
        <Grid item xs={10} md={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField fullWidth label="login" inputRef={loginRef} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="password"
                inputRef={pwdRef}
                type={showPassword?"text":"password"}
              />
              <Button onClick={()=>setShowPassword(!showPassword)}>show</Button>
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="nickname" inputRef={nicknameRef} />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="agency" inputRef={baseagencyRef} />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="email" inputRef={emailRef} />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="phone" inputRef={phoneRef} />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained">
                signup
              </Button>
            </Grid>
          </Grid>{" "}
        </Grid>
        <Grid item xs={1} md={5}></Grid>
      </Grid>
    </form>
    
  );
}
