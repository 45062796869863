import { Button, Grid, TextField } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import * as React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFolders } from "../features/dataSlice";

import { doLogin } from "../features/systemSlice";

export default function LoginPage(props) {
  const loginRef = React.useRef();
  const pwdRef = React.useRef();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [showPassword,setShowPassword] = React.useState(false);
  const error = useSelector((state) => state.system.error);

  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault();
        dispatch(
          doLogin({
            login: loginRef.current.value,
            pwd: pwdRef.current.value,
          })
        )
          .then(unwrapResult)
          .then((originalPromiseResult) => {
            //console.log(originalPromiseResult);
            dispatch(
              setFolders([
                {
                  agencyid: originalPromiseResult.agencyid,
                  folderid: originalPromiseResult.agencyid,
                  parentid: "",
                  level: -1,
                  name: "Agency",
                },
              ])
            );
            if (originalPromiseResult.token) navigate("/home");

          })
          .catch((rejectedValueOrSerializedError) => {
            console.log(rejectedValueOrSerializedError);
          });
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={1} md={5}></Grid>
        <Grid item xs={10} md={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {error ? error : <span>&nbsp;</span>}
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="username" inputRef={loginRef} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="password"
                inputRef={pwdRef}
                type={showPassword?"text":"password"}
              />
               <button onClick={()=>setShowPassword(!showPassword)}>show</button>
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained">
                login
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={5}></Grid>
      </Grid>
    </form>
  );
}
