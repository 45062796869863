import * as React from "react";
import Button from "@mui/material/Button";


import {

  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

export default function HotellistsGrid(props) {
  const { currentHotellistid, hotellistitems, deleteHotellistitem, getHotellistitems } = props;
  const [currentId, setCurrentId] = React.useState("");
  const dispatch = useDispatch();
  let odd = true;

  return (
    <Paper
      style={{ marginBottom: 8 }}
      sx={{ width: "100%", overflow: "hidden" }}
    >
      <TableContainer sx={{ maxHeight: 200 }}>
        <Table
          size="small"
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Name</TableCell>
              <TableCell style={{ width: "20%" }}>Region</TableCell>
              <TableCell style={{ width: "10%" }}>Giataid</TableCell>
              <TableCell style={{ width: "30%" }}>Conditions</TableCell>
              <TableCell style={{ width: "15%" }}>BlackoutConditions</TableCell>
              <TableCell style={{ width: "5%" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(hotellistitems) &&
              hotellistitems.map((e, idx) => {
                odd = !odd;
                return (
                  <TableRow
                    onClick={() => {
                      setCurrentId(e.id);
                    }}

                    key={idx}
                    style={{
                      backgroundColor:
                        currentId === e.id
                          ? "#029aee"
                          : odd
                            ? "#f0f0f0"
                            : undefined,
                    }}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >

                    <TableCell style={{ width: "20%" }}>
                      {e.name ? e.name : ""}
                    </TableCell>
                    <TableCell style={{ width: "20%" }}>
                      {e.region ? e.region : ""}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {e.giataid ? e.giataid : ""}
                    </TableCell>
                    <TableCell style={{ width: "30%" }}>
                      {e.additionalcondition ? e.additionalcondition : ""}
                    </TableCell>
                    <TableCell style={{ width: "15%" }}>
                      {e.blackoutdate ? e.blackoutdate : ""}
                    </TableCell>
                    <TableCell style={{ width: "5%" }}>
                      <Button
                        onClick={() => {
                          dispatch(deleteHotellistitem({ id: e.id })).then(() =>
                            dispatch(getHotellistitems({ hotellistid: currentHotellistid })));;
                        }}
                      >
                        del
                      </Button>

                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
