import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
    const token = useSelector((state) => state.system.token);


    if (!token) {
        // user is not authenticated
        return <Navigate to="/" />;
    }
    return children;
};