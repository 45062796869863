import * as React from "react";

import { FormattedDate } from "react-intl";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getHost } from "../utils";
import { getSystemhelp, saveSystemhelp } from "../features/dataApiCalls";
import { setSystemhelp } from "../features/dataSlice";

export default function HelpadminPage(props) {
  const dispatch = useDispatch();

  const systemhelp = useSelector((state) => state.data.systemhelp);
  const [dialog, setDialog] = React.useState({});
  const [editField, setEditField] = React.useState("");
  const role = useSelector((state) => state.system.role);

  let dialogObject = {};

  React.useEffect(() => {
    dispatch(getSystemhelp({ admin: true }));
  }, []);


  if (dialog.keyvalue) {
    dialogObject = systemhelp.find((o) => o[dialog.key] === dialog.keyvalue);
  }
  return (
    <>
      {dialog.keyvalue && (
        <EditDialog
          setDialog={setDialog}
          dialog={dialog}
          systemhelp={systemhelp}
          dialogObject={dialogObject}
          editField={editField}
          role={role}
        />
      )}

      <DataTable
        setDialog={setDialog}
        dialog={dialog}
        systemhelp={systemhelp}
        setEditField={setEditField}
        role={role}
      />
    </>
  );
}



const EditDialog = (props) => {
  const { setDialog, dialog, systemhelp, dialogObject, editField, role } = props;
  const dispatch = useDispatch();
  const [dialogEditField, setDialogEditField] = React.useState(editField);

  React.useEffect(() => {
    setDialogEditField(editField);
  }, [editField]);

  return (
    <>
      {dialog.keyvalue && (
        <Dialog
          fullWidth={true}
          open={dialog.keyvalue ? true : false}
          onClose={() => setDialog({})}
        >
          <DialogTitle>{dialog.field}</DialogTitle>
          <DialogContent>
            <TextField
              multiline
              fullWidth
              rows={30}

              value={
                dialogEditField

              }
              onChange={(evt) => {

                setDialogEditField(evt.target.value);


                return;

              }}
            />
          </DialogContent>
          <DialogActions>

            <Button
              onClick={() => {
                try {



                  if (dialog.field === "body") {
                    let jsontext = JSON.parse(dialogEditField);

                    dispatch(
                      setSystemhelp(
                        [...systemhelp].map((o) =>
                          o[dialog.key] === dialog.keyvalue
                            ? { ...o, body: jsontext, dirty: true }
                            : o
                        )
                      )
                    );
                  }
                  if (dialog.field === "question" || dialog.field === "answer") {
                    let text = dialogEditField;
                    dispatch(
                      setSystemhelp(
                        [...systemhelp].map((o) =>
                          o[dialog.key] === dialog.keyvalue
                            ? { ...o, [dialog.field]: text, dirty: true }
                            : o
                        )

                      )
                    );
                  }

                  setDialog({});
                } catch (e) { }
              }}
            >
              save
            </Button>

            <Button
              onClick={() => {
                setDialog({});
              }}
            >
              close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const DataTable = (props) => {
  const { setDialog, dialog, systemhelp, setEditField, role } = props;
  const dispatch = useDispatch();
  const [systemhelpFilter, setSystemhelpfilter] = React.useState("");
  let filtered = systemhelp.filter(
    (e) =>
      e.module.toLowerCase().indexOf(systemhelpFilter.toLowerCase()) >
      -1
  );


  let odd = true;
  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <div>
          <ButtonGroup style={{ marginBottom: 16 }} variant="contained">
            <Button onClick={() => dispatch(getSystemhelp({ admin: true }))}>Get Data</Button>
            {(role == "admin" || role == "master") && (
              <>
                <Button onClick={() => dispatch(saveSystemhelp(systemhelp))}>Save Data</Button>
              </>
            )}
          </ButtonGroup>
        </div>
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "10%" }}>
                  module
                  <TextField
                    variant="standard"
                    onChange={(e) => setSystemhelpfilter(e.target.value)}
                  ></TextField>
                </TableCell>

                <TableCell style={{ width: "8%" }}>user</TableCell>
                <TableCell style={{ width: "7%" }}>doi</TableCell>
                <TableCell style={{ width: "25%" }}>question</TableCell>
                <TableCell style={{ width: "7%" }}>doc</TableCell>
                <TableCell style={{ width: "30%" }}>answer</TableCell>
                <TableCell style={{ width: "4%" }}>question</TableCell>
                <TableCell style={{ width: "4%" }}>answer</TableCell>
                <TableCell style={{ width: "4%" }}>rank</TableCell>
                <TableCell style={{ width: "4%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(filtered) &&
                filtered.map((e, idx) => {
                  odd = !odd;
                  return (
                    <TableRow
                      key={idx}
                      style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell style={{ width: "10%" }}>
                        {e.module ? e.module : ""}
                      </TableCell>
                      <TableCell style={{ width: "8%" }}>
                        {e.nickname ? e.nickname : ""}
                      </TableCell>

                      <TableCell style={{ width: "7%" }}>
                        {e.doit ? e.doit : ""}
                      </TableCell>
                      <TableCell style={{ width: "25%" }}>
                        {e.question ? e.question : ""}
                      </TableCell>
                      <TableCell style={{ width: "7%" }}>
                        {e.doct ? e.doct : ""}
                      </TableCell>
                      <TableCell style={{ width: "25%" }}>
                        {e.answer ? e.answer.substring(0, 40) + "..." : ""}
                      </TableCell>

                      <TableCell style={{ width: "4%" }}>
                        <Button
                          style={{ color: e.header ? "blue" : undefined }}
                          onClick={() => {
                            setDialog({
                              keyvalue: e.id,
                              key: "id",
                              field: "question",
                            });
                            setEditField(e.question);
                          }}
                        >
                          edit
                        </Button>
                      </TableCell>

                      <TableCell style={{ width: "4%" }}>
                        <Button
                          style={{ color: e.footer ? "blue" : undefined }}
                          onClick={() => {
                            setDialog({
                              keyvalue: e.id,
                              key: "id",
                              field: "answer",
                            });
                            setEditField(e.answer);
                          }}
                        >
                          edit
                        </Button>
                      </TableCell>
                      <TableCell style={{ width: "4%" }}>
                        {e.rank ? e.rank : ""}
                      </TableCell>
                      <TableCell style={{ width: "4%" }}>
                        <Button disabled={!e.dirty}
                          onClick={() => {
                            dispatch(saveSystemhelp({ data: e }));
                            dispatch(
                              setSystemhelp(
                                [...systemhelp].map((o) =>
                                  o.id === e.id
                                    ? { ...o, dirty: false }
                                    : o
                                )

                              )
                            );
                          }}
                        >
                          save
                        </Button>

                      </TableCell>

                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
