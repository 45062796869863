import * as React from "react";

import { FormattedDate } from "react-intl";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";


import { addHotellistitem, getGiatainfos } from "../features/dataApiCalls";


export default function HotellistSearchhotel(props) {
  const { getHotellistitems, currentHotellistid } = props;
  const dispatch = useDispatch();
  const giatainfos = useSelector((state) => state.data.giatainfos);
  const [hotels, setHotels] = React.useState(false);

  return (
    <>

      <Search setHotels={setHotels} hotels={hotels} />
      <DataTable

        giatainfos={giatainfos}
        getHotellistitems={getHotellistitems}
        currentHotellistid={currentHotellistid}

      />
    </>
  );
}

const Search = (props) => {
  const { getHotellistitems, currentHotellistid } = props;
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState("");
  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault();
        dispatch(getGiatainfos({ search: search, hotels: true }));
      }}
    >
      <div style={{ display: "flex" }}>

        <div style={{ flexGrow: 1 }}>
          <TextField
            variant="standard"
            label={"search"}
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div style={{ marginLeft: 16 }}>
          <Button
            style={{ marginBottom: 16 }}
            type="submit"
            variant="contained"
          >
            Get Data
          </Button>
        </div>
      </div>
    </form>
  );
};



const DataTable = (props) => {
  const { getHotellistitems, currentHotellistid, giatainfos } = props;
  const dispatch = useDispatch();

  let odd = true;
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
        <Table
          size="small"
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "10%" }}>Hotel</TableCell>
              <TableCell style={{ width: "20%" }}>fullname</TableCell>
              <TableCell style={{ width: "10%" }}>groupID</TableCell>
              <TableCell style={{ width: "10%" }}>regionID</TableCell>
              <TableCell style={{ width: "10%" }}>GiataID</TableCell>


            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(giatainfos) &&
              giatainfos.map((e, idx) => {
                odd = !odd;
                return (
                  <TableRow
                    key={idx}
                    style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ width: "10%" }}>
                      {e.hotelname ? e.hotelname : ""}
                    </TableCell>
                    <TableCell style={{ width: "20%" }}>
                      {e.fullname ? e.fullname : ""}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {e.groupid ? e.groupid : ""}
                    </TableCell>

                    <TableCell style={{ width: "10%" }}>
                      {e.regionid ? e.regionid : ""}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {e.giataid ? e.giataid : ""}
                    </TableCell>

                    <TableCell style={{ width: "5%" }}>
                      <Button
                        onClick={() => {
                          dispatch(addHotellistitem({ hotellistid: currentHotellistid, giataid: e.giataid })).then(() =>
                            dispatch(getHotellistitems({ hotellistid: currentHotellistid })));;
                        }}
                      >
                        add
                      </Button>
                    </TableCell>

                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
