import * as React from "react";
import Button from "@mui/material/Button";
import { FormattedDate } from "react-intl";
import {
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getLogfiles, deleteLogfile, deleteLogfiles, readLogfile, getLogpaths } from "../features/dataApiCalls";
import { setLogpath, setLogfile } from "../features/dataSlice";

export default function LogfilesPage(props) {
  const dispatch = useDispatch();
  const logfiles = useSelector((state) => state.data.logfiles);
  const logfile = useSelector((state) => state.data.logfile);
  const logpaths = useSelector((state) => state.data.logpaths);
  const logpath = useSelector((state) => state.data.logpath);
  const [currentId, setCurrentId] = React.useState("");
  const [currentFile, setCurrentFile] = React.useState("");


  React.useEffect(() => {
    dispatch(getLogpaths());
  }, []);

  let odd = true;

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
          <div style={{ marginLeft: 16 }}>
            <Button
              style={{ marginBottom: 16 }}
              onClick={() => {
                dispatch(getLogpaths());
                dispatch(getLogfiles({ path: logpath }))
              }}
              variant="contained"
            >
              Get Data
            </Button>
          </div>

          <div style={{ marginLeft: 16 }}>
            <Select
              fullWidth
              labelId="select-typesl"
              id="select-types"
              value={logpath}
              variant="standard"
              onChange={(evt) => {
                console.log(evt.target);
                dispatch(setLogfile(""));
                dispatch(setLogpath(evt.target.value));
                dispatch(getLogfiles({ path: evt.target.value }))
              }}
            >
              {logpaths.map((o) => (
                <MenuItem key={o.root} value={o.root}>
                  {o.root}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div style={{ marginLeft: 16 }}>
            <Button
              style={{ marginBottom: 16 }}
              onClick={() => {
                dispatch(deleteLogfiles());
                dispatch(getLogfiles({ path: logpath }))
              }}
              variant="contained"
            >
              DeleteFiles
            </Button>
           
          </div>
        </div>
      
      <Paper
        style={{ marginBottom: 8 }}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        <TableContainer>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "40%" }}>filename</TableCell>
                <TableCell style={{ width: "20%" }}>size</TableCell>
                <TableCell style={{ width: "5%" }}>dir</TableCell>
                <TableCell style={{ width: "15%" }}></TableCell>
                <TableCell style={{ width: "20%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(logfiles) &&
                logfiles.
                  map((e, idx) => {
                    odd = !odd;
                    return (
                      <TableRow
                        onClick={() => {
                          setCurrentId(e.filename);
                        }}
                        key={idx}
                        style={{
                          backgroundColor:
                            currentId === e.filename
                              ? "#029aee"
                              : odd
                                ? "#f0f0f0"
                                : undefined,
                        }}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell style={{ width: "40%" }}>
                          {e.filename ? e.filename : ""}
                        </TableCell>

                        <TableCell style={{ width: "20%" }}>
                          {e.ST_SIZE ? e.ST_SIZE : ""}
                        </TableCell>
                        <TableCell style={{ width: "5%" }}>
                          {e.isdir ? "dir" : ""}
                        </TableCell>
                        <TableCell style={{ width: "15%" }}>
                          {!e.isdir && (
                            <Button style={{ color: e.delete ? "red" : undefined }} onClick={(evt) => {
                              dispatch(readLogfile({ filename: (e.filename) }));
                              setCurrentFile(e.filename);
                            }} >ansehen</Button>)}
                        </TableCell>
                        <TableCell style={{ width: "20%" }}>
                          {!e.isdir && (
                            <Button style={{ color: e.delete ? "red" : undefined }} onClick={(evt) => {
                              dispatch(deleteLogfile({ filename: (e.filename) })).then(dispatch(setLogfile(""))).then(
                                dispatch(getLogfiles({ path: logpath })));
                            }} >löschen</Button>)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <h3>{logpath}/{currentFile}</h3>
        <pre>{logfile}</pre>
      </Paper>
    </>
  );
}
