import * as React from "react";

import { useSelector } from 'react-redux';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { dateToInputString } from "../utils";

import {
  makeStyles}
 from "@mui/styles";

const useStyles = makeStyles(theme => ({
  textarea: {
    resize: "both"
  }
}));


export default function TouroperatorActionsGrid(props) {
  const pictures = useSelector((state) => state.data.pictures);
  const classes = useStyles();
  const {
    touroperatoractions,
    currentTouroperatorAction,
    setTouroperatoractions,
    touroperatoractiontyp,
    onpage,
    hotellists,
    setEditField,
    setDialog,
  } = props;

  return (
    <Paper style={{ padding: 16 }}>
      <Grid container spacing={3}>
        <Grid item md={1} xs={12}>
          <TextField
            fullWidth
            variant="standard"
            label="status"
            value={
              currentTouroperatorAction.status
                ? currentTouroperatorAction.status
                : ""
            }
            onChange={(evt) => {
              let act = touroperatoractions.map((ele) =>
                ele.id === currentTouroperatorAction.id
                  ? { ...ele, status: evt.target.value, dirty: true }
                  : ele
              );
              setTouroperatoractions(act);
            }}
          />
        </Grid>
        <Grid item md={1} xs={12}>
          <TextField
            fullWidth
            variant="standard"
            label="operator"
            value={
              currentTouroperatorAction.touroperator
                ? currentTouroperatorAction.touroperator
                : ""
            }
            onChange={(evt) => {
              let act = touroperatoractions.map((ele) =>
                ele.id === currentTouroperatorAction.id
                  ? { ...ele, touroperator: evt.target.value, dirty: true }
                  : ele
              );
              setTouroperatoractions(act);
            }}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <TextField
            fullWidth
            variant="standard"
            label="operator coupon"
            value={
              currentTouroperatorAction.touroperatorcoupon
                ? currentTouroperatorAction.touroperatorcoupon
                : ""
            }
            onChange={(evt) => {
              let act = touroperatoractions.map((ele) =>
                ele.id === currentTouroperatorAction.id
                  ? { ...ele, touroperatorcoupon: evt.target.value, dirty: true }
                  : ele
              );
              setTouroperatoractions(act);
            }}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <TextField
            fullWidth
            variant="standard"
            label="label (MarketingBox)"
            value={
              currentTouroperatorAction.label
                ? currentTouroperatorAction.label
                : ""
            }
            onChange={(evt) => {
              let act = touroperatoractions.map((ele) =>
                ele.id === currentTouroperatorAction.id
                  ? { ...ele, label: evt.target.value, dirty: true }
                  : ele
              );
              setTouroperatoractions(act);
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="select-typesl">Typ</InputLabel>
            <Select
              fullWidth
              labelId="select-typesl"
              id="select-types"
              value={
                currentTouroperatorAction.actiontyp
                  ? currentTouroperatorAction.actiontyp
                  : ""
              }
              variant="standard"
              onChange={(evt) => {
                let act = touroperatoractions.map((ele) =>
                  ele.id === currentTouroperatorAction.id
                    ? {
                      ...ele,

                      actiontyp: evt.target.value,

                      dirty: true,
                    }
                    : ele
                );
                setTouroperatoractions(act);
              }}
            >
              {touroperatoractiontyp.map((o) => (
                <MenuItem key={o.propertyid} value={o.propertyid}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={3} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="select-typid">Plazierung</InputLabel>
            <Select
              fullWidth
              labelId="select-onpage"
              id="select-onpage"
              value={
                currentTouroperatorAction.onpage
                  ? currentTouroperatorAction.onpage
                  : ""
              }
              variant="standard"
              onChange={(evt) => {
                let act = touroperatoractions.map((ele) =>
                  ele.id === currentTouroperatorAction.id
                    ? {
                      ...ele,

                      onpage: evt.target.value,
                      dirty: true,
                    }
                    : ele
                );
                setTouroperatoractions(act);
              }}
            >
              {onpage.map((o) => (
                <MenuItem key={o.propertyid} value={o.propertyid}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={2} xs={12}>
          <TextField
            fullWidth
            variant="standard"
            label="text (Hotel Voucher)"
            value={
              currentTouroperatorAction.shorttext
                ? currentTouroperatorAction.shorttext
                : ""
            }
            onChange={(evt) => {
              let act = touroperatoractions.map((ele) =>
                ele.id === currentTouroperatorAction.id
                  ? {
                    ...ele,

                    shorttext: evt.target.value,

                    dirty: true,
                  }
                  : ele
              );
              setTouroperatoractions(act);
            }}
          />
        </Grid>
        <Grid item md={3} sm={4}>
          <TextField
            fullWidth
            variant="standard"
            label="description (booking form)"
            value={
              currentTouroperatorAction.longtext
                ? currentTouroperatorAction.longtext
                : ""
            }
            onChange={(evt) => {
              let act = touroperatoractions.map((ele) =>
                ele.id === currentTouroperatorAction.id
                  ? {
                    ...ele,

                    longtext: evt.target.value,

                    dirty: true,
                  }
                  : ele
              );
              setTouroperatoractions(act);
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            fullWidth
            multiline
            rows={2}

            variant="standard"
            label="mouseover"
            value={
              currentTouroperatorAction.mouseover
                ? currentTouroperatorAction.mouseover
                : ""
            }
            onChange={(evt) => {
              let act = touroperatoractions.map((ele) =>
                ele.id === currentTouroperatorAction.id
                  ? {
                    ...ele,

                    mouseover: evt.target.value,

                    dirty: true,
                  }
                  : ele
              );
              setTouroperatoractions(act);
            }}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <InputLabel id="select-typid">Sticker</InputLabel>
          <Select
            fullWidth
            labelId="select-typesl"
            id="select-types"
            value={
              currentTouroperatorAction.sticker ? currentTouroperatorAction.sticker : ""
            }
            variant="standard"

            onChange={(evt) => {
              let act = touroperatoractions.map((ele, i) => {
                if (ele.id === currentTouroperatorAction.id) {
                  return {
                    ...ele,
                    sticker: evt.target.value,
                    dirty: true
                  };
                } else {
                  return ele;
                }
              });
              setTouroperatoractions(act);
            }}
          >
            {(Array.isArray(pictures[currentTouroperatorAction.folderid]) ? pictures[currentTouroperatorAction.folderid] : []).map((o) => (
              <MenuItem key={o.url} value={o.url}>
                {o.filename}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel id="select-listid">Hotellist</InputLabel>
            <Select
              fullWidth
              labelId="select-listid"
              id="select-list-id"
              value={currentTouroperatorAction.hotellistid ? currentTouroperatorAction.hotellistid : ""}
              variant="standard"
              onChange={(evt) => {
                let act = touroperatoractions.map((ele) =>
                  ele.id === currentTouroperatorAction.id
                    ? {
                      ...ele,

                      hotellistid: evt.target.value,
                      dirty: true,
                    }
                    : ele
                );
                setTouroperatoractions(act);
              }}
            >
              {hotellists.map((o) => (
                <MenuItem key={o.id} value={o.id}>
                  {o.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} xs={12} sm={2}>
          <TextField
            fullWidth
            variant="standard"
            label="bookingFrom"
            type="date"
            value={
              currentTouroperatorAction.booking_from
                ? dateToInputString(currentTouroperatorAction.booking_from)
                : ""
            }
            onKeyDown={(e) => e.preventDefault()}
            onChange={(evt) => {
              try {
                let tmp = new Date(evt.target.value);
                let act = touroperatoractions.map((ele) =>
                  ele.id === currentTouroperatorAction.id
                    ? {
                      ...ele,
                      booking_from: tmp,
                      dirty: true,
                    }
                    : ele
                );
                setTouroperatoractions(act);
              } catch (e) { }
            }}
          />
        </Grid>
        <Grid item md={2} xs={12} sm={2}>
          <TextField
            fullWidth
            variant="standard"
            label="bookingUntil"
            type="date"
            value={
              currentTouroperatorAction.booking_until
                ? dateToInputString(currentTouroperatorAction.booking_until)
                : ""
            }
            onKeyDown={(e) => e.preventDefault()}
            onChange={(evt) => {
              try {
                let tmp = new Date(evt.target.value);
                let act = touroperatoractions.map((ele) =>
                  ele.id === currentTouroperatorAction.id
                    ? {
                      ...ele,
                      booking_until: tmp,
                      dirty: true,
                    }
                    : ele
                );
                setTouroperatoractions(act);
              } catch (e) { }
            }}
          />
        </Grid>

        <Grid item md={2} >
          <TextField
            fullWidth
            variant="standard"
            label="usingFrom"
            type="date"
            value={
              currentTouroperatorAction.using_from
                ? dateToInputString(currentTouroperatorAction.using_from)
                : ""
            }
            onKeyDown={(e) => e.preventDefault()}
            onChange={(evt) => {
              try {
                let tmp = new Date(evt.target.value);
                let act = touroperatoractions.map((ele) =>
                  ele.id === currentTouroperatorAction.id
                    ? {
                      ...ele,
                      using_from: tmp,
                      dirty: true,
                    }
                    : ele
                );
                setTouroperatoractions(act);
              } catch (e) { }
            }}
          />
        </Grid>
        <Grid item md={2} >
          <TextField
            fullWidth
            variant="standard"
            label="usingUntil"
            type="date"
            value={
              currentTouroperatorAction.using_until
                ? dateToInputString(currentTouroperatorAction.using_until)
                : ""
            }
            onKeyDown={(e) => e.preventDefault()}
            onChange={(evt) => {
              try {
                let tmp = new Date(evt.target.value);
                let act = touroperatoractions.map((ele) =>
                  ele.id === currentTouroperatorAction.id
                    ? {
                      ...ele,
                      using_until: tmp,
                      dirty: true,
                    }
                    : ele
                );
                setTouroperatoractions(act);
              } catch (e) { }
            }}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField
            fullWidth
            label="actiongroup"
            value={currentTouroperatorAction.actiongroup ? currentTouroperatorAction.actiongroup : ""}
            onChange={(evt) => {
              let act = touroperatoractions.map((ele) =>
                ele.id === currentTouroperatorAction.id
                  ? { ...ele, actiongroup: evt.target.value, dirty: true }
                  : ele
              );
              setTouroperatoractions(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField
            fullWidth
            label="maxCashback"
            value={currentTouroperatorAction.maxvalue ? currentTouroperatorAction.maxvalue : ""}
            onChange={(evt) => {
              let act = touroperatoractions.map((ele) =>
                ele.id === currentTouroperatorAction.id
                  ? { ...ele, maxvalue: evt.target.value, dirty: true }
                  : ele
              );
              setTouroperatoractions(act);
            }}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            multiline
            rows={6}
            label={`bool condition"  ( ... tripduration > 3 and 'arrivaldate'::date <= '2023-10-31' )`}
            value={
              currentTouroperatorAction.boolcondition
                ? currentTouroperatorAction.boolcondition
                : ""
            }
            onChange={(evt) => {
              let act = touroperatoractions.map((ele) =>
                ele.id === currentTouroperatorAction.id
                  ? {
                    ...ele,
                    boolcondition: evt.target.value,
                    dirty: true,
                  }
                  : ele
              );
              setTouroperatoractions(act);
            }}
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <TextField
            fullWidth
            multiline
            rows={6}
            label={`cashback condition (case when price >  1000 then '{"text":"75 Euro Cashback","coupon":"DERSUMMER75","value":75}' ...  else '{}' end`}
            value={
              currentTouroperatorAction.varcondition
                ? currentTouroperatorAction.varcondition
                : ""
            }
            onChange={(evt) => {
              let act = touroperatoractions.map((ele) =>
                ele.id === currentTouroperatorAction.id
                  ? {
                    ...ele,
                    varcondition: evt.target.value,
                    dirty: true,
                  }
                  : ele
              );
              setTouroperatoractions(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            multiline
            rows={2}
            label="request"
            value={currentTouroperatorAction.request ? currentTouroperatorAction.request : ""}
            onChange={(evt) => {
              let act = touroperatoractions.map((ele) =>
                ele.id === currentTouroperatorAction.id
                  ? {
                    ...ele,
                    request: evt.target.value,
                    dirty: true,
                  }
                  : ele
              );
              setTouroperatoractions(act);
            }}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            multiline
            rows={2}
            label="properties"
            value={
              currentTouroperatorAction.properties
                ? JSON.stringify(currentTouroperatorAction.properties)
                : ""
            }
          />
          <Button
            style={{
              color: currentTouroperatorAction.properties ? "blue" : undefined,
            }}
            onClick={() => {
              setDialog({
                number: currentTouroperatorAction.id,
                data: "touroperatoraction",
                key: "id",
                field: "properties",
              });
              setEditField(
                JSON.stringify(currentTouroperatorAction.properties, null, 4)
              );
            }}
          >
            bearbeiten
          </Button>
        </Grid>
        <Grid item md={12} xs={12}>
          {currentTouroperatorAction.flexoptionen}
        </Grid>

        <Grid item md={12} xs={12}>
          {currentTouroperatorAction.errormsg}
        </Grid>
      </Grid>
    </Paper>
  );
}
