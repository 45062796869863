import * as React from "react";

import { FormattedDate } from "react-intl";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getHost } from "../utils";
import { getAllbookings } from "../features/dataApiCalls";
import { setBookings } from "../features/dataSlice";

export default function BookingsPage(props) {
  const dispatch = useDispatch();
  const bookings = useSelector((state) => state.data.bookings);
  const [dialog, setDialog] = React.useState({});
  const [editField, setEditField] = React.useState("");
  const role = useSelector((state) => state.system.role);
  const [allitems, setAllitems] = React.useState("0");
  let dialogObject = {};
  console.log(bookings);
  if (dialog.keyvalue) {
    dialogObject = bookings.find((o) => o[dialog.key] === dialog.keyvalue);
  }
  return (
    <>
      {dialog.keyvalue && (
        <EditDialog
          setDialog={setDialog}
          dialog={dialog}
          bookings={bookings}
          dialogObject={dialogObject}
          editField={editField}
          role={role}
        />
      )}
      <Search setAllitems={setAllitems} allitems={allitems} />
      <DataTable
        setDialog={setDialog}
        dialog={dialog}
        bookings={bookings}
        setEditField={setEditField}
      />
    </>
  );
}

const Search = (props) => {
  const { setAllitems, allitems } = props;
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState("");
  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault();
        dispatch(getAllbookings({ search: search, allitems: allitems }));
      }}
    >
      <div style={{ display: "flex" }}>

        <div style={{ flexGrow: 1 }}>
          <TextField
            variant="standard"
            label={"search"}
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div><FormControlLabel control={<Checkbox checked={allitems==="1"} onChange={() => setAllitems(allitems=="0"?"1":"0")} />} label="allitems" /></div>
        <div style={{ marginLeft: 16 }}>
          <Button
            style={{ marginBottom: 16 }}
            type="submit"
            variant="contained"
          >
            Get Data
          </Button>
        </div>
      </div>
    </form>
  );
};

const EditDialog = (props) => {
  const { setDialog, dialog, bookings, dialogObject, editField, role } = props;
  const dispatch = useDispatch();
  const [dialogEditField, setDialogEditField] = React.useState(editField);

  React.useEffect(() => {
    setDialogEditField(editField);
  }, [editField]);

  return (
    <>
      {dialog.keyvalue && (
        <Dialog
          fullWidth={true}
          open={dialog.keyvalue ? true : false}
          onClose={() => setDialog({})}
        >
          <DialogTitle>{dialog.field}</DialogTitle>
          <DialogContent>
            <TextField
              multiline
              fullWidth
              rows={30}

              value={
                dialog.field === "properties"
                  ? dialogEditField
                  : dialogObject[dialog.field]
              }

            />
          </DialogContent>
          <DialogActions> <Button
            onClick={() => {
              setDialog({});
            }}
          >
            close
          </Button></DialogActions>
        </Dialog>
      )}
    </>
  );
};

const DataTable = (props) => {
  const { setDialog, dialog, bookings, setEditField } = props;
  const dispatch = useDispatch();

  let odd = true;
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
        <Table
          size="small"
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "20%" }}>zeitpunkt</TableCell>
              <TableCell style={{ width: "80%" }}>eigenschaften</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(bookings) &&
              bookings.map((e, idx) => {
                odd = !odd;
                return (
                  <TableRow
                    key={idx}
                    style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ width: "20%" }}>
                      {e.doi ? e.doi : ""}
                    </TableCell>

                    <TableCell style={{ width: "10%" }}>

                      <Button
                        style={{
                          color: e.properties ? "blue" : undefined,
                        }}
                        onClick={() => {
                          setDialog({
                            keyvalue: e.id,
                            key: "id",
                            field: "properties",
                          });
                          setEditField(
                            JSON.stringify(e.properties, null, 4)
                          );
                        }}
                      >
                        show
                      </Button>

                    </TableCell>

                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
