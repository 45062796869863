import * as React from 'react';
import Button from '@mui/material/Button';
import { FormattedDate } from 'react-intl';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAgencyRoomNames, saveAgencyRoomNames } from '../features/dataApiCalls';
import { setAgencyRoomnames } from '../features/dataSlice';


export default function AgencyRoomnamesPage(props) {



    const dispatch = useDispatch();
    const [search, setSearch] = React.useState("");
    const agencyroomnames = useSelector((state) => state.data.agencyroomnames);
    const role = useSelector((state) => state.system.role);
    let odd = true;
    return (
        < >
            <form onSubmit={(evt) => {
                evt.preventDefault();
                dispatch(getAgencyRoomNames({ search: search }));
            }
            } ><div style={{ display: "flex" }}>
                    <div style={{ flexGrow: 1 }}><TextField value={search} fullWidth label="search or type new (+word) or today or refresh" variant="standard" onChange={(e) => setSearch(e.target.value)} /></div><div style={{ marginLeft: 16 }}>
                        <Button style={{ marginBottom: 16 }} type="submit" variant="contained">Get Data</Button></div></div></form>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
                    <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                        <TableHead>
                            <TableRow><TableCell style={{ width: "20%" }}>agencyroomname</TableCell>
                                <TableCell style={{ width: "20%" }}>agencyroomname NEW</TableCell>
                                <TableCell style={{ width: "25%" }}>mouseover</TableCell>
                                <TableCell style={{ width: "5%" }}>save</TableCell>
                                <TableCell style={{ width: "5%" }}>used</TableCell>
                                <TableCell style={{ width: "25%" }}>OperatorNamen</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(agencyroomnames) &&
                                agencyroomnames.map((obj, idx) => {
                                    odd = !odd;
                                    return (
                                        <TableRow
                                            key={idx}
                                            style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell style={{ width: "20%" }}>
                                                {obj.agencyroomname ? obj.agencyroomname : ""}
                                            </TableCell>
                                            <TableCell style={{ width: "20%" }}>
                                                <TextField
                                                    fullWidth
                                                    disabled={role != "master"}
                                                    value={obj.newagencyroomname ? obj.newagencyroomname : ""}
                                                    onChange={(evt) => {
                                                        let tmp = agencyroomnames.map((o, i) => {
                                                            if (o.agencyroomname === obj.agencyroomname) {
                                                                return {
                                                                    ...o,
                                                                    newagencyroomname: evt.target.value,
                                                                    dirty: true
                                                                };
                                                            } else {
                                                                return o;
                                                            }
                                                        });
                                                        dispatch(setAgencyRoomnames(tmp));
                                                    }}

                                                />
                                            </TableCell>
                                            <TableCell style={{ width: "25%" }}>

                                                <TextField
                                                    fullWidth
                                                    disabled={role != "master"}
                                                    value={obj.mouseover ? obj.mouseover : ""}
                                                    onChange={(evt) => {
                                                        let tmp = agencyroomnames.map((o, i) => {
                                                            if (o.agencyroomname === obj.agencyroomname) {
                                                                return {
                                                                    ...o,
                                                                    mouseover: evt.target.value,
                                                                    dirty: true
                                                                };
                                                            } else {
                                                                return o;
                                                            }
                                                        });
                                                        dispatch(setAgencyRoomnames(tmp));
                                                    }}


                                                />
                                            </TableCell>
                                            <TableCell style={{ width: "5%" }}>
                                                <Button disabled={!obj.dirty || role != "master"}
                                                    onClick={() => {
                                                        dispatch(saveAgencyRoomNames({ data: obj }));

                                                    }}
                                                >
                                                    save
                                                </Button>

                                            </TableCell>


                                            <TableCell style={{ width: "5%" }}>
                                                {obj.used ? obj.used : ""}
                                            </TableCell>
                                            <TableCell style={{ width: "25%", color: obj.operatornames.length > 60 ? 'blue' : undefined }} onClick={() => { }
                                            }>
                                                {obj.operatornames ? (obj.operatornames.length > 40 ? obj.operatornames.substring(0, 40) + '...' : obj.operatornames) : ""}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper></>
    )
}