import * as React from "react";
import Button from "@mui/material/Button";
import { FormattedDate } from "react-intl";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getHolidays } from "../features/dataApiCalls";

export default function HolidayPage(props) {
  const dispatch = useDispatch();

  const holidays = useSelector((state) => state.data.holidays);
  const { holiDays, holidayfilter } = props;
  const [holidaysort, setHolidaysort] = React.useState({});
  let odd = true;

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <div>
          <Button
            style={{ marginBottom: 16 }}
            onClick={() => dispatch(getHolidays())}
            variant="contained"
          >
            Get Data
          </Button>
        </div>
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ width: "35%", cursor: "pointer" }}
                  onClick={() =>
                    setHolidaysort({
                      column: "land",
                      direction: holidaysort.direction
                        ? holidaysort.direction * -1
                        : 1,
                    })
                  }
                >
                  state
                </TableCell>
                <TableCell
                  style={{ width: "35%", cursor: "pointer" }}
                  onClick={() =>
                    setHolidaysort({
                      column: "name",
                      direction: holidaysort.direction
                        ? holidaysort.direction * -1
                        : 1,
                    })
                  }
                >
                  name
                </TableCell>
                <TableCell
                  style={{ width: "10%", cursor: "pointer" }}
                  onClick={() =>
                    setHolidaysort({
                      column: "startdate",
                      direction: holidaysort.direction
                        ? holidaysort.direction * -1
                        : 1,
                    })
                  }
                >
                  start
                </TableCell>
                <TableCell style={{ width: "10%" }}>end</TableCell>
                <TableCell style={{ width: "10%" }}>level</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(holidays) &&
                holidays
                  .filter((o) =>
                    holidayfilter
                      ? o.remark === "Ferien"
                      : o.remark !== "Ferien"
                  )
                  .sort((a, b) => {
                    return a.land > b.land;
                  })
                  .sort((a, b) => {
                    return (
                      new Date(a.startdate).getTime() >
                      new Date(b.startdate).getTime()
                    );
                  })
                  .sort((a, b) => {
                    if (!holidaysort.column) return 0;
                    if (holidaysort.column === "name") {
                      return holidaysort.direction == 1
                        ? a[holidaysort.column] > b[holidaysort.column]
                        : a[holidaysort.column] < b[holidaysort.column];
                    }
                    if (holidaysort.column === "land") {
                      return holidaysort.direction == 1
                        ? a[holidaysort.column] > b[holidaysort.column]
                        : a[holidaysort.column] < b[holidaysort.column];
                    }
                    if (holidaysort.column === "startdate") {
                      return holidaysort.direction == 1
                        ? new Date(a.startdate).getTime() >
                            new Date(b.startdate).getTime()
                        : new Date(a.startdate).getTime() <
                            new Date(b.startdate).getTime();
                    }
                  })

                  .map((obj, idx) => {
                    odd = !odd;
                    return (
                      <TableRow
                        key={idx}
                        style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell style={{ width: "35%" }}>
                          {obj.land ? obj.land : ""}
                        </TableCell>
                        <TableCell style={{ width: "35%" }}>
                          {obj.name ? obj.name : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {obj.startdate ? (
                            <FormattedDate
                              value={obj.startdate}
                              year="2-digit"
                              month="2-digit"
                              day="2-digit"
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {obj.enddate ? (
                            <FormattedDate
                              value={obj.enddate}
                              year="2-digit"
                              month="2-digit"
                              day="2-digit"
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {obj.remark ? obj.remark : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
