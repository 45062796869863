import * as React from "react";
import Button from "@mui/material/Button";
import { FormattedDate, FormattedTime } from "react-intl";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getWebaccess} from "../features/dataApiCalls";

export default function WebaccessPage(props) {
  const dispatch = useDispatch();

  const webaccess = useSelector((state) => state.data.webaccess);
  //const { holiDays, holidayfilter } = props;
  //const [holidaysort, setHolidaysort] = React.useState({});
  let odd = true;
  console.log(webaccess);
  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <div style={{ marginLeft: 16 }}>
          <Button
            style={{ marginBottom: 16 }}
            onClick={() => dispatch(getWebaccess())}
            variant="contained"
          >
            Get Data
          </Button>
        </div>

      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "15%", cursor: "pointer" }}>
                  date
                </TableCell>
                <TableCell style={{ width: "50%", cursor: "pointer" }}>
                  region
                </TableCell>
                <TableCell style={{ width: "15%", cursor: "pointer" }}>
                  count
                </TableCell>
                <TableCell style={{ width: "20%", cursor: "pointer" }}>
                  sessions
                </TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(webaccess) &&
                webaccess.map((obj, idx) => {
                  odd = !odd;
                  return (
                    <TableRow
                      key={idx}
                      style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell style={{ width: "15%" }}>
                        {obj.date ? obj.date : ""}
                      </TableCell>
                      <TableCell style={{ width: "50%" }}>
                        {obj.region ? obj.region : ""}
                      </TableCell>
                      <TableCell style={{ width: "15%" }}>
                        {obj.count ? obj.count : ""}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {obj.sessions ? obj.sessions : ""}
                      </TableCell>
                      

                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
