import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getHost } from "../utils";



export const getMouseovertexts = createAsyncThunk(
  "data/getMouseovertexts",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getmouseovertexts?agencyid=" +
        state.system.agencyid +
        "&language=de"
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const askOpenAI = createAsyncThunk(
  "data/askOpenAI",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/askOpenAI?agencyid=" +
        state.system.agencyid +
        "&language=de" + "&prompt=" + payload
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);



export const getHolidays = createAsyncThunk(
  "data/getHolidays",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(getHost() + "/getholidays");

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const getAirports = createAsyncThunk(
  "data/getAirports",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(getHost() + "/getairports" +
        "?search=" + encodeURIComponent(payload.search));

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const getAirlines = createAsyncThunk(
  "data/getAirlines",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(getHost() + "/getairlines");

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const getTouroperators = createAsyncThunk(
  "data/getTouroperators",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(getHost() + "/gettouroperators");

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const getPerformancelog = createAsyncThunk(
  "data/getperformancelog",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(getHost() + "/getperformancelog");

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getWebaccess = createAsyncThunk(
  "data/getwebaccess",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(getHost() + "/getwebaccess?agencyid=" +
        state.system.agencyid);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const getStatistics = createAsyncThunk(
  "data/getstatistics",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(getHost() + "/getstatistics?agencyid=" +
        state.system.agencyid);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const getStatistic = createAsyncThunk(
  "data/getstatistic",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(
        getHost() + "/getstatistic", { ...payload, "agencyid": state.system.agencyid }
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const getGiatainfos = createAsyncThunk(
  "data/getgiatainfos",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getgiatainfos?agencyid=" +
        state.system.agencyid +
        "&search=" +
        encodeURIComponent(payload.search) + "&hotels=" + payload.hotels
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);



export const setHotellistprivate = createAsyncThunk(
  "data/sethotellistprivate",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/sethotellistprivate?hotellistid=" + payload.hotellistid + "&agencyid=" + state.system.agencyid
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const setHotellistpublic = createAsyncThunk(
  "data/sethotellistpublic",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/sethotellistpublic?hotellistid=" + payload.hotellistid + "&agenycid=" + state.system.agencyid
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const addHotellistitem = createAsyncThunk(
  "data/addhotellistitem",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/addhotellistitem?hotellistid=" +
        payload.hotellistid +
        "&giataid=" +
        payload.giataid
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);



export const getRoomNames = createAsyncThunk(
  "data/getroomnames",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getroomnames?agencyid=" +
        state.system.agencyid +
        "&search=" +
        encodeURIComponent(payload.search)
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getFaqs = createAsyncThunk(
  "data/getfaqs",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/getfaqs?agencyid=" + state.system.agencyid + "&language=de"
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getShortcuts = createAsyncThunk(
  "data/getshortcuts",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/getshortcuts?agencyid=" + state.system.agencyid
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getTranslations = createAsyncThunk(
  "data/gettranslations",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/gettranslations?agencyid=" +
        state.system.agencyid +
        "&search=" +
        encodeURIComponent(payload.search)
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const getLogpaths = createAsyncThunk(
  "data/getlogpaths",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getlogpaths"
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const readLogfile = createAsyncThunk(
  "data/readLogfile",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/readlogfile?filename=" + encodeURIComponent(state.data.logpath) + "/" + encodeURIComponent(payload.filename)
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const deleteLogfile = createAsyncThunk(
  "data/deletelogfile",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    try {
      const response = await axios.get(
        getHost() + "/deletelogfile?filename=" + encodeURIComponent(state.data.logpath) + "/" + encodeURIComponent(payload.filename)
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const deleteLogfiles = createAsyncThunk(
  "data/deletelogfile",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    try {
      const response = await axios.get(
        getHost() + "/deletelogfiles?path=" + encodeURIComponent(state.data.logpath)
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const getLogfiles = createAsyncThunk(
  "data/getlogfiles",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(getHost() + "/getlogfiles?path=" + encodeURIComponent(state.data.logpath));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);



export const getAgencyRoomNames = createAsyncThunk(
  "data/getagencyroomnames",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getagencyroomnames?agencyid=" +
        state.system.agencyid +
        "&search=" +
        encodeURIComponent(payload.search)
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getAgencyactions = createAsyncThunk(
  "data/getagencyactions",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getagencyactions?agencyid=" + state.system.agencyid + "&allitems=" + payload.allitems
      );

      if (Array.isArray(response.data.ret)) {
        for (let i = 0; i < response.data.ret.length; i++) {
          try {
            response.data.ret[i].booking_from = response.data.ret[i]
              .booking_from
              ? new Date(response.data.ret[i].booking_from)
              : response.data.ret[i].booking_from;
            response.data.ret[i].booking_until = response.data.ret[i]
              .booking_until
              ? new Date(response.data.ret[i].booking_until)
              : response.data.ret[i].booking_until;
            response.data.ret[i].using_from = response.data.ret[i].using_from
              ? new Date(response.data.ret[i].using_from)
              : response.data.ret[i].using_from;
            response.data.ret[i].using_until = response.data.ret[i].using_until
              ? new Date(response.data.ret[i].using_until)
              : response.data.ret[i].using_until;

            response.data.ret[i].valid_from = response.data.ret[i].valid_from
              ? new Date(response.data.ret[i].valid_from)
              : response.data.ret[i].valid_from;
            response.data.ret[i].valid_until = response.data.ret[i].valid_until
              ? new Date(response.data.ret[i].valid_until)
              : response.data.ret[i].valid_until;
          } catch (e) { }
        }
      }
      if (Array.isArray(response.data.ret)) {
        thunkAPI.dispatch(
          getPictures({ folderid: "stickers", dropdown: "1" })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getHotelactions = createAsyncThunk(
  "data/gethotelactions",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getagencyactions?agencyid=00000000-0000-0000-0000-000000000000" + "&allitems=" + payload.allitems

      );

      if (Array.isArray(response.data.ret)) {
        for (let i = 0; i < response.data.ret.length; i++) {
          try {
            response.data.ret[i].agencyid = state.system.agencyid;
            response.data.ret[i].booking_from = response.data.ret[i]
              .booking_from
              ? new Date(response.data.ret[i].booking_from)
              : response.data.ret[i].booking_from;
            response.data.ret[i].booking_until = response.data.ret[i]
              .booking_until
              ? new Date(response.data.ret[i].booking_until)
              : response.data.ret[i].booking_until;
            response.data.ret[i].using_from = response.data.ret[i].using_from
              ? new Date(response.data.ret[i].using_from)
              : response.data.ret[i].using_from;
            response.data.ret[i].using_until = response.data.ret[i].using_until
              ? new Date(response.data.ret[i].using_until)
              : response.data.ret[i].using_until;

            response.data.ret[i].valid_from = response.data.ret[i].valid_from
              ? new Date(response.data.ret[i].valid_from)
              : response.data.ret[i].valid_from;
            response.data.ret[i].valid_until = response.data.ret[i].valid_until
              ? new Date(response.data.ret[i].valid_until)
              : response.data.ret[i].valid_until;
          } catch (e) { }
        }
      }
      if (Array.isArray(response.data.ret)) {

        thunkAPI.dispatch(
          getPictures({ folderid: "stickers", dropdown: "1" })
        );

      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const getHotelowneractions = createAsyncThunk(
  "data/gethotelowneractions",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/getagencyactions?giataid=" + (payload.giataid)

      );

      if (Array.isArray(response.data.ret)) {
        for (let i = 0; i < response.data.ret.length; i++) {
          try {
            response.data.ret[i].agencyid = state.system.agencyid;
            response.data.ret[i].booking_from = response.data.ret[i]
              .booking_from
              ? new Date(response.data.ret[i].booking_from)
              : response.data.ret[i].booking_from;
            response.data.ret[i].booking_until = response.data.ret[i]
              .booking_until
              ? new Date(response.data.ret[i].booking_until)
              : response.data.ret[i].booking_until;
            response.data.ret[i].using_from = response.data.ret[i].using_from
              ? new Date(response.data.ret[i].using_from)
              : response.data.ret[i].using_from;
            response.data.ret[i].using_until = response.data.ret[i].using_until
              ? new Date(response.data.ret[i].using_until)
              : response.data.ret[i].using_until;

            response.data.ret[i].valid_from = response.data.ret[i].valid_from
              ? new Date(response.data.ret[i].valid_from)
              : response.data.ret[i].valid_from;
            response.data.ret[i].valid_until = response.data.ret[i].valid_until
              ? new Date(response.data.ret[i].valid_until)
              : response.data.ret[i].valid_until;
          } catch (e) { }
        }
      }
      if (Array.isArray(response.data.ret)) {

        thunkAPI.dispatch(
          getPictures({ folderid: "stickers", dropdown: "1" })
        );

      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const resetPwd = createAsyncThunk(
  "data/resetpwd",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/resetpwd?userhash=" + payload
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const getHotellists = createAsyncThunk(
  "data/gethotellists",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/gethotellists?agencyid=" + payload
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getHotellistitems = createAsyncThunk(
  "data/gethotellistitems",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/gethotellistitems?agencyid=" + state.system.agencyid + "&hotellistid=" + payload.hotellistid
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);



export const deleteTopseller = createAsyncThunk(
  "data/deletetopseller",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/deletetopseller?id=" + payload.id

      );

      return response.data;
    } catch (error) {
      alert(error?.response?.data ? error.response.data : error.message);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);



export const deleteHotellist = createAsyncThunk(
  "data/deletehotellist",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/deletehotellist?hotellistid=" +
        payload.id

      );

      return response.data;
    } catch (error) {
      alert(error?.response?.data ? error.response.data : error.message);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);



export const deleteHotellistitem = createAsyncThunk(
  "data/deletehotellistitem",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/deletehotellistitem?hotellistitemid=" + payload.id

      );

      return response.data;
    } catch (error) {
      alert(error?.response?.data ? error.response.data : error.message);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);



export const getContainer = createAsyncThunk(
  "data/getcontainer",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/getcontainer?agencyid=" + state.system.agencyid
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const getAgencyproperties = createAsyncThunk(
  "data/getagencyproperties",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/getagencyproperties?agencyid=" + state.system.agencyid
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getTouroperatoractions = createAsyncThunk(
  "data/gettouroperatoractions",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/gettouroperatoractions?agencyid=" + state.system.agencyid + "&allitems=" + payload.allitems

      );
      if (Array.isArray(response.data.ret)) {
        for (let i = 0; i < response.data.ret.length; i++) {
          try {

            response.data.ret[i].agencyid = state.system.agencyid;
            response.data.ret[i].booking_from = response.data.ret[i]
              .booking_from
              ? new Date(response.data.ret[i].booking_from)
              : response.data.ret[i].booking_from;
            response.data.ret[i].booking_until = response.data.ret[i]
              .booking_until
              ? new Date(response.data.ret[i].booking_until)
              : response.data.ret[i].booking_until;
            response.data.ret[i].using_from = response.data.ret[i].using_from
              ? new Date(response.data.ret[i].using_from)
              : response.data.ret[i].using_from;
            response.data.ret[i].using_until = response.data.ret[i].using_until
              ? new Date(response.data.ret[i].using_until)
              : response.data.ret[i].using_until;

            response.data.ret[i].valid_from = response.data.ret[i].valid_from
              ? new Date(response.data.ret[i].valid_from)
              : response.data.ret[i].valid_from;
            response.data.ret[i].valid_until = response.data.ret[i].valid_until
              ? new Date(response.data.ret[i].valid_until)
              : response.data.ret[i].valid_until;
          } catch (e) { }
        }
      }
      if (Array.isArray(response.data.ret)) {

        thunkAPI.dispatch(
          getPictures({ folderid: "stickers", dropdown: "1" })
        );

      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getAllbookings = createAsyncThunk(
  "data/getallbookings",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getallbookings?agencyid=" + state.system.agencyid + "&allitems=" + payload.allitems + (payload.search ? ("&search=" + payload.search) : "")

      );
      if (Array.isArray(response.data.ret)) {
        for (let i = 0; i < response.data.ret.length; i++) {
          try {
            response.data.ret[i].booking_from = response.data.ret[i]
              .booking_from
              ? new Date(response.data.ret[i].booking_from)
              : response.data.ret[i].booking_from;
            response.data.ret[i].booking_until = response.data.ret[i]
              .booking_until
              ? new Date(response.data.ret[i].booking_until)
              : response.data.ret[i].booking_until;
            response.data.ret[i].using_from = response.data.ret[i].using_from
              ? new Date(response.data.ret[i].using_from)
              : response.data.ret[i].using_from;
            response.data.ret[i].using_until = response.data.ret[i].using_until
              ? new Date(response.data.ret[i].using_until)
              : response.data.ret[i].using_until;

            response.data.ret[i].valid_from = response.data.ret[i].valid_from
              ? new Date(response.data.ret[i].valid_from)
              : response.data.ret[i].valid_from;
            response.data.ret[i].valid_until = response.data.ret[i].valid_until
              ? new Date(response.data.ret[i].valid_until)
              : response.data.ret[i].valid_until;
          } catch (e) { }
        }
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const saveTranslations = createAsyncThunk(
  "data/savetranslations",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(
        getHost() + "/settranslations",
        [...state.data.translations].filter((e) => e.dirty)
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const saveAllUsers = createAsyncThunk(
  "data/saveallusers",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(
        getHost() + "/setusers",
        state.data.allusers.map(e => { return { ...e, allusers: 1 } }).filter((e) => e.dirty)
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const saveUsers = createAsyncThunk(
  "data/saveusers",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(
        getHost() + "/setusers",
        [...state.data.users].filter((e) => e.dirty)
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const saveFaqs = createAsyncThunk(
  "data/savefaqs",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(
        getHost() + "/setfaqs",
        [...state.data.faqs].filter((e) => e.dirty)
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const saveShortcuts = createAsyncThunk(
  "data/saveshortcuts",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(
        getHost() + "/setshortcuts",
        [...state.data.shortcuts].filter((e) => e.dirty)
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const saveContainer = createAsyncThunk(
  "data/savecontainer",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(
        getHost() + "/setcontainer",
        [...state.data.container.filter(e => e.dirty)]
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const saveOfferstopper = createAsyncThunk(
  "data/saveofferstopper",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(
        getHost() + "/setofferstopper",
        [...state.data.offerstopper.filter(e => e.dirty)]
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);



export const saveCoupons = createAsyncThunk(
  "data/saveCoupons",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(
        getHost() + "/setcoupons",
        [...state.data.coupons.filter(e => e.dirty)]
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const saveCouponcodes = createAsyncThunk(
  "data/saveCouponcodes",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(
        getHost() + "/setcouponcodes",
        [...state.data.coupons.filter(e => e.dirty)]
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const saveAlternatetext = createAsyncThunk(
  "data/savealternatetext",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    try {
      const response = await axios.get(
        getHost() + "/setalternatetext?id=" + payload.id +"&alternatetext=" + encodeURIComponent(payload.alternatetext)

      );
      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const deleteCouponcode = createAsyncThunk(
  "data/deletecouponcode",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/deletecouponcode?couponcodeid=" + payload.id

      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const saveMouseovertexts = createAsyncThunk(
  "data/savemouseovertexts",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(
        getHost() + "/setmouseovertexts",
        [...state.data.mouseovertexts].filter((e) => e.dirty)
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const saveAgencyproperties = createAsyncThunk(
  "data/saveagencyproperties",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    console.log(state.data.agencyproperties);
    try {
      const response = await axios.post(
        getHost() + "/setagencyproperties",
        state.data.agencyproperties
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const saveAgencyactions = createAsyncThunk(
  "data/saveagencyactions",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(
        getHost() + "/setagencyactions",
        [...state.data.agencyactions].filter((e) => e.dirty)
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const usesystemhelp = createAsyncThunk(
  "data/usesystemhelp",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(getHost() + "/usesystemhelp?id=" + payload.id  );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const saveSystemhelp = createAsyncThunk(
  "data/savesystemhelp",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    if(!Array.isArray(payload)) payload = [];
    try {
      const response = await axios.post(
        getHost() + "/setsystemhelp",
        [...state.data.systemhelp,...payload].filter((e) => e.dirty)
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const saveHotellists = createAsyncThunk(
  "data/savehotellists",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(
        getHost() + "/sethotellists",
        [...state.data.hotellists].filter((e) => e.dirty)
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const saveHotelactions = createAsyncThunk(
  "data/saveagencyactions",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(
        getHost() + "/setagencyactions",
        [...state.data.hotelactions].filter((e) => e.dirty)
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const savetouroperatoractions = createAsyncThunk(
  "data/savetouroperatoractions",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(
        getHost() + "/settouroperatoractions",
        [...state.data.touroperatoractions].filter((e) => e.dirty)
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const saveRoomNames = createAsyncThunk(
  "data/saveroomnames",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(getHost() + "/setroomnames", [
        payload.data,
      ]);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const saveAgencyRoomNames = createAsyncThunk(
  "data/saveagencyroomnames",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(getHost() + "/setagencyroomnames", [
        payload.data,
      ]);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const saveCityProperties = createAsyncThunk(
  "data/savecityproperties",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(getHost() + "/setcityproperties", {
        properties: payload.data,
        id: payload.id,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const saveHotelProperties = createAsyncThunk(
  "data/savehotelproperties",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(getHost() + "/sethotelproperties", {
        ...payload,
        agencyid: state.system.agencyid,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getCityInfo = createAsyncThunk(
  "data/getcityinfo",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/cityinfo?context=" +
        encodeURIComponent(payload.context) +
        "&id=" +
        payload.cityid
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getSuggestions = createAsyncThunk(
  "data/getsuggestions",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(getHost() + "/getsuggestions", {
        allitems: payload.allitems,
        agencyid: state.system.agencyid,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const scanOffers = createAsyncThunk(
  "data/scanoffers",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(getHost() + "/scanoffers");

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);



export const getSystemhelp = createAsyncThunk(
  "data/getsystemhelp",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(getHost() + "/getsystemhelp?module=" + payload.module + (payload.admin ? "&admin=" + payload.admin:""));

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const getTopSellerInfos = createAsyncThunk(
  "data/gettopsellerinfos",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/gettopsellerinfos?agencyid=" + state.system.agencyid + "&allitems=" + payload.allitems
      );
      if (Array.isArray(response.data.ret)) {
        for (let i = 0; i < response.data.ret.length; i++) {
          thunkAPI.dispatch(
            getPictures({ folderid: response.data.ret[i].folderid })
          );
        }
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getTeaserInfos = createAsyncThunk(
  "data/getteaserinfos",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getteaserinfos?agencyid=" + state.system.agencyid + "&allitems=" + payload.allitems
      );
      if (Array.isArray(response.data.ret)) {
        for (let i = 0; i < response.data.ret.length; i++) {
          thunkAPI.dispatch(
            getPictures({ folderid: response.data.ret[i].folderid })
          );
        }
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getFilelist = createAsyncThunk(
  "data/getfilelist",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/getfilelist?agencyid=" + state.system.agencyid
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getProperty = createAsyncThunk(
  "data/getproperty",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getproperties?agencyid=" +
        state.system.agencyid +
        "&language=" +
        state.system.language +
        "&tag=" +
        encodeURIComponent(payload.tag)
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const searchFile = createAsyncThunk(
  "data/searchfile",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/searchfiles?agencyid=" +
        state.system.agencyid +
        "&search=" +
        encodeURIComponent(payload.search)
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const deleteFile = createAsyncThunk(
  "data/deletefile",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/deletefile?folderid=" +
        encodeURIComponent(payload.folderid) +
        "&documentid=" +
        payload.documentid +
        "&agencyid=" +
        state.system.agencyid
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const shareFile = createAsyncThunk(
  "data/sharefile",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/sharefile?folderid=" +
        encodeURIComponent(payload.folderid) +
        "&documentid=" +
        payload.documentid +
        "&agencyid=" +
        state.system.agencyid
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);




export const newTopseller = createAsyncThunk(
  "data/newtopseller",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(getHost() + "/newtopseller", {
        ...payload,
        agencyid: state.system.agencyid,
      }
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const saveSuggestions = createAsyncThunk(
  "data/savesuggestions",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    console.log(state.data.suggestions);
    try {
      const response = await axios.post(getHost() + "/setsuggestions",
        [...state.data.suggestions].filter((e) => e.dirty)
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const saveTeasers = createAsyncThunk(
  "data/saveteasers",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    console.log(state.data.teasers);
    try {
      const response = await axios.post(getHost() + "/setteaserinfos",
        [...state.data.teasers].filter((e) => e.dirty),
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const saveTopsellers = createAsyncThunk(
  "data/savetopsellers",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    console.log(state.data.topsellers);
    try {
      const response = await axios.post(getHost() + "/settopsellerinfos",
        [...state.data.topsellers].filter((e) => e.dirty),
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const openwrk = createAsyncThunk(
  "data/openwrk",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(getHost() + "/openwrk");
      alert(JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const duplicateHotellist = createAsyncThunk(
  "data/duplicatehotellist",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/duplicatehotellist?id=" + payload.id
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);



export const duplicateSuggestion = createAsyncThunk(
  "data/duplicatesuggestion",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/duplicatesuggestion?id=" + payload.id
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const duplicateOfferstopper = createAsyncThunk(
  "data/duplicateofferstopper",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/duplicateofferstopper?id=" + payload.id
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);



export const duplicateCoupon = createAsyncThunk(
  "data/duplicatecoupon",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/duplicatecoupon?id=" + payload.id
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);



export const duplicateTeaser = createAsyncThunk(
  "data/duplicateteaser",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/duplicateteaser?id=" + payload.id
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const duplicateTopseller = createAsyncThunk(
  "data/duplicatetopseller",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/duplicatetopseller?id=" + payload.id
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const duplicateTouroperatoraction = createAsyncThunk(
  "data/duplicatetouroperatoraction",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/duplicatetouroperatoraction?id=" + payload.id
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const duplicateAgencyaction = createAsyncThunk(
  "data/duplicateagencyaction",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() + "/duplicateagencyaction?id=" + payload.id
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getFolders = createAsyncThunk(
  "data/getfolders",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    let level = "agencyid=" + state.system.agencyid;

    if (payload?.folderid) {
      level = "folderid=" + payload.folderid + "&agencyid=" + state.system.agencyid;
    }
    try {
      const response = await axios.get(getHost() + "/getfoldertree?" + level);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getPictures = createAsyncThunk(
  "data/getpictures",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    console.log(payload);
    try {
      const response = await axios.get(
        getHost() +
        "/getfiles?agencyid=" +
        (payload.common ? "00000000-0000-0000-0000-000000000000" : state.system.agencyid) +
        "&folderid=" + payload.folderid + "&dropdown=" + payload.dropdown
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getFiles = createAsyncThunk(
  "data/getfiles",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getfiles?agencyid=" +
        state.system.agencyid +
        "&folderid=" +
        payload.folderid
        + "&dropdown=" +
        payload.dropdown
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getUsers = createAsyncThunk(
  "data/getusers",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getusers?agencyid=" + state.system.agencyid
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const getAllUsers = createAsyncThunk(
  "data/getallusers",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getallusers"
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getAgencies = createAsyncThunk(
  "data/getagencies",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getagencies"
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);


export const getOfferstopper = createAsyncThunk(
  "data/getstopper",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getofferstopper?agencyid=" +
        state.system.agencyid + "&allitems=" + payload.allitems
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);



export const getCoupons = createAsyncThunk(
  "data/getcoupons",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getcoupons?agencyid=" +
        state.system.agencyid + "&allitems=" + payload.allitems
      );
      console.log("getcoupons")
      if (Array.isArray(response.data.data)) {
        for (let i = 0; i < response.data.data.length; i++) {
          try {
            response.data.data[i].agencyid = state.system.agencyid;
            response.data.data[i].validuntil = response.data.data[i]
              .validuntil
              ? new Date(response.data.data[i].validuntil)
              : response.data.data[i].validuntil;

          } catch (e) { console.log(e) }
        }
      }
      console.log(response.data)
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const getCouponcodes = createAsyncThunk(
  "data/getcouponcodes",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(getHost() + "/getcouponcodes?id=" + payload.id);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const generateCouponcodes = createAsyncThunk(
  "data/generatecouponcodes",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(getHost() + "/generatecouponcodes?id=" + payload.couponid);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);



