import * as React from 'react';
import { FormattedDate } from 'react-intl';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, MenuItem, DialogTitle, Paper, Popper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, FormControlLabel, Checkbox } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { getSuggestions, saveSuggestions, duplicateSuggestion } from '../features/dataApiCalls';
import { setSuggestions } from '../features/dataSlice';
import { dateToInputString } from "../utils";

export default function SuggestionsPage(props) {


    const dispatch = useDispatch();
    const suggestions = useSelector((state) => state.data.suggestions);
    const agencyid = useSelector((state) => state.system.agencyid);
    const [dialog, setDialog] = React.useState({});
    const [editField, setEditField] = React.useState("");
    const [currentId, setCurrentId] = React.useState("");
    const role = useSelector((state) => state.system.role);

    let dialogObject = {};

    if (dialog.number) {
        dialogObject = suggestions.find((o) => o[dialog.key] === dialog.number);
    }
    return (
        < >
            {dialog.number && <EditDialog setDialog={setDialog} dialog={dialog} suggestions={suggestions} dialogObject={dialogObject}
                editField={editField} role={role} />}
            <Search suggestions={suggestions} role={role} currentId={currentId} />
            <DataTable setDialog={setDialog} dialog={dialog} suggestions={suggestions} setCurrentId={setCurrentId} setEditField={setEditField} currentId={currentId} role={role} />


        </ >
    );
}

const Search = (props) => {
    const { role, currentId } = props;
    const dispatch = useDispatch();
    const [allitems, setAllitems] = React.useState("0");

    return (<div style={{ display: "flex" }}><div style={{ flexGrow: 1 }}><form onSubmit={(evt) => {
        evt.preventDefault();
        dispatch(getSuggestions({ allitems }));
    }
    } ><div style={{ display: "flex", flexGrow: 1 }}>
            <div style={{ flexGrow: 1 }}>
                &nbsp;</div><div style={{ marginLeft: 16 }}>
                <FormControlLabel control={<Checkbox checked={allitems==="1"} onChange={() => setAllitems(allitems=="0"?"1":"0")} />} label="all items" />
                <Button style={{ marginBottom: 16 }} type="submit" variant="contained">Get Data</Button></div></div></form ></div>
        {role == "agencyadmin" || (role == "admin" || role == "master") && (
            <>
                <div style={{ marginLeft: 16 }}>
                    <Button style={{ marginBottom: 16 }} onClick={() => dispatch(saveSuggestions()).then(() =>
                        dispatch(getSuggestions({ allitems })))} variant="contained">Save all</Button></div >
                <div style={{ marginLeft: 16 }}>
                    <Button style={{ marginBottom: 16 }} variant="contained"
                        onClick={() => {
                            dispatch(duplicateSuggestion({ id: currentId })).then(() =>
                                dispatch(getSuggestions({ allitems: "N" })));
                        }}

                    >Duplicate Data</Button></div>
            </>)
        } </div>)
}

const EditDialog = (props) => {
    const { setDialog, dialog, suggestions, dialogObject, editField } = props;
    const dispatch = useDispatch();
    const [dialogEditField, setDialogEditField] = React.useState(editField);


    React.useEffect(() => {
        setDialogEditField(editField);
    }, [editField])

    return (<>{dialog.number && <Dialog
        fullWidth={true}
        open={dialog.number ? true : false}
        onClose={() => setDialog({})}
    >
        <DialogTitle>{dialog.field}</DialogTitle>
        <DialogContent>
            <TextField
                multiline
                fullWidth
                rows={30}
                value={

                    dialog.field === "request"
                        ? dialogEditField
                        : dialogObject[dialog.field]

                }
                onChange={(evt) => {
                    if (
                        dialog.field === "request"

                    ) {
                        setDialogEditField(evt.target.value);
                        return;
                    }
                }}
            />
        </DialogContent>
        <DialogActions>
            {(dialog.field === "request")
                && (
                    <Button
                        onClick={() => {
                            try {
                                let jsontext = dialogEditField;
                                if (dialog.field === 'request')
                                    jsontext = JSON.parse(jsontext);
                                setDialog({});

                                if (dialog.field === 'request') {

                                    dispatch(setSuggestions([...suggestions].map(
                                        (o) => o[dialog.key] === dialog.number ? { ...o, [dialog.field]: jsontext, dirty: true } : o
                                    )))

                                }

                            } catch (e) { }
                        }}
                    >
                        save
                    </Button>
                )}
            <Button
                onClick={() => {
                    setDialog({});
                }}
            >
                close
            </Button>
        </DialogActions>
    </Dialog>}</>)
}

const DataTable = (props) => {
    const { setDialog, dialog, suggestions, setEditField, role, setCurrentId, currentId } = props;
    const dispatch = useDispatch();
    let typ = ["package", "accommodation", "cruise", "fewo"];
    let odd = true;
    return (<Paper sx={{ width: '100%', overflow: 'hidden' }}>

        <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
            <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: "20%" }}>label</TableCell>
                        <TableCell style={{ width: "10%" }}>typ</TableCell>
                        <TableCell style={{ width: "5%" }}>from</TableCell>
                        <TableCell style={{ width: "5%" }}>until</TableCell>
                        <TableCell style={{ width: "5%" }}>order</TableCell>
                        <TableCell style={{ width: "30%" }}>request</TableCell>
                        <TableCell style={{ width: "5%" }}>status</TableCell>
                        <TableCell style={{ width: "20%" }}>link</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(suggestions) &&
                        suggestions.map((obj, idx) => {
                            odd = !odd;
                            return (
                                <TableRow
                                    onClick={() => {
                                        setCurrentId(obj.id);
                                    }}
                                    key={idx}
                                    style={{
                                        backgroundColor:
                                            currentId === obj.id
                                                ? "#029aee"
                                                : odd
                                                    ? "#f0f0f0"
                                                    : undefined,
                                    }}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={{ width: "20%" }}>
                                        <TextField variant="standard"
                                            value={obj.label ? obj.label : ""}
                                            onChange={(evt) => {
                                                let tmp = suggestions.map((o, i) => {
                                                    if (o.id === obj.id) {
                                                        return {
                                                            ...o,
                                                            label: evt.target.value,
                                                            dirty: true,
                                                        };
                                                    } else {
                                                        return o;
                                                    }
                                                });
                                                dispatch(setSuggestions(tmp));
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: "10%" }}>
                                        <Select
                                            fullWidth
                                            labelId="select-typesl"
                                            id="select-types"
                                            value={
                                                obj.typ
                                                    ? obj.typ
                                                    : ""
                                            }
                                            variant="standard"

                                            onChange={(evt) => {
                                                let tmp = suggestions.map((o, i) => {
                                                    if (o.id === obj.id) {
                                                        return {
                                                            ...o,
                                                            typ: evt.target.value,
                                                            dirty: true,
                                                        };
                                                    } else {
                                                        return o;
                                                    }
                                                });
                                                dispatch(setSuggestions(tmp));
                                            }}
                                        >
                                            {typ.map((o) => (
                                                <MenuItem key={o} value={o}>
                                                    {o}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>
                                    <TableCell style={{ width: "5%" }}>
                                        <TextField
                                            fullWidth

                                            type="date"
                                            value={
                                                obj.valid_from
                                                    ? dateToInputString(new Date(obj.valid_from))
                                                    : ""
                                            }
                                            onKeyDown={(e) => e.preventDefault()}
                                            onChange={(evt) => {
                                                try {
                                                    let tmp = new Date(evt.target.value);
                                                    let act = suggestions.map((ele) =>
                                                        ele.id === obj.id
                                                            ? {
                                                                ...ele,
                                                                valid_from: tmp,
                                                                dirty: true,
                                                            }
                                                            : ele
                                                    );
                                                    dispatch(setSuggestions(act));
                                                } catch (e) { }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: "5%" }}>
                                        <TextField
                                            fullWidth

                                            type="date"
                                            value={
                                                obj.valid_until
                                                    ? dateToInputString(new Date(obj.valid_until))
                                                    : ""
                                            }
                                            onKeyDown={(e) => e.preventDefault()}
                                            onChange={(evt) => {
                                                try {
                                                    let tmp = new Date(evt.target.value);
                                                    let act = suggestions.map((ele) =>
                                                        ele.id === obj.id
                                                            ? {
                                                                ...ele,
                                                                valid_until: tmp,
                                                                dirty: true,
                                                            }
                                                            : ele
                                                    );
                                                    dispatch(setSuggestions(act));
                                                } catch (e) { }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: "5%" }}>
                                        <TextField variant="standard"
                                            value={obj.rank ? obj.rank : ""}
                                            onChange={(evt) => {
                                                let tmp = suggestions.map((o, i) => {
                                                    if (o.id === obj.id) {
                                                        return {
                                                            ...o,
                                                            rank: evt.target.value,
                                                            dirty: true,
                                                        };
                                                    } else {
                                                        return o;
                                                    }
                                                });
                                                dispatch(setSuggestions(tmp));
                                            }}
                                        />

                                    </TableCell>
                                    <TableCell style={{ maxWidth: "30%" }}>
                                        <Button
                                            onClick={() => {
                                                setDialog({
                                                    number: obj.id,
                                                    key: "id",
                                                    field: "request",
                                                });
                                                setEditField(JSON.stringify(obj.request, null, 4));

                                            }
                                            }
                                        >
                                            edit
                                        </Button>
                                        {obj.request ? JSON.stringify(obj.request, null, 4) : ""}
                                    </TableCell>
                                    <TableCell style={{ width: "5%" }}>
                                        <TextField variant="standard"
                                            value={obj.status ? obj.status : ""}
                                            onChange={(evt) => {
                                                let tmp = suggestions.map((o, i) => {
                                                    if (o.id === obj.id) {
                                                        return {
                                                            ...o,
                                                            status: evt.target.value,
                                                            dirty: true,
                                                        };
                                                    } else {
                                                        return o;
                                                    }
                                                });
                                                dispatch(setSuggestions(tmp));
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: "20%" }}>
                                        <TextField variant="standard"
                                            value={obj.link ? obj.link : ""}
                                            onChange={(evt) => {
                                                let tmp = suggestions.map((o, i) => {
                                                    if (o.id === obj.id) {
                                                        return {
                                                            ...o,
                                                            link: evt.target.value,
                                                            dirty: true,
                                                        };
                                                    } else {
                                                        return o;
                                                    }
                                                });
                                                dispatch(setSuggestions(tmp));
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Paper >
    )
}