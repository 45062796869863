import * as React from 'react';
import { FormattedDate } from 'react-intl';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, MenuItem, DialogTitle, Paper, Popper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { getFaqs, saveFaqs } from '../features/dataApiCalls';
import { setFaqs } from '../features/dataSlice';


export default function FaqsPage(props) {


    const dispatch = useDispatch();
    const faqs = useSelector((state) => state.data.faqs);
    const [dialog, setDialog] = React.useState({});
    const [editField, setEditField] = React.useState("");

    React.useEffect(() => {
        dispatch(getFaqs());
    }, []);

    let dialogObject = {};

    if (dialog.number) {
        dialogObject = faqs.find((o) => o[dialog.key] === dialog.number);
    }

    return (
        <>
            {dialog.keyvalue && (
                <EditDialog
                    setDialog={setDialog}
                    dialog={dialog}
                    faqs={faqs}
                    dialogObject={dialogObject}
                    editField={editField}
                />
            )}

            <DataTable
                setDialog={setDialog}
                dialog={dialog}
                faqs={faqs}
                setEditField={setEditField}
            />
        </>
    );
}



const EditDialog = (props) => {
    const { setDialog, dialog, faqs, dialogObject, editField, role } = props;
    const dispatch = useDispatch();
    const [dialogEditField, setDialogEditField] = React.useState(editField);

    React.useEffect(() => {
        setDialogEditField(editField);
    }, [editField]);

    return (
        <>
            {dialog.keyvalue && (
                <Dialog
                    fullWidth={true}
                    open={dialog.keyvalue ? true : false}
                    onClose={() => setDialog({})}
                >
                    <DialogTitle>{dialog.field}</DialogTitle>
                    <DialogContent>
                        <TextField
                            multiline
                            fullWidth
                            rows={30}

                            value={
                                dialogEditField

                            }
                            onChange={(evt) => {

                                setDialogEditField(evt.target.value);


                                return;

                            }}
                        />
                    </DialogContent>
                    <DialogActions>

                        <Button
                            onClick={() => {
                                try {



                                    let text = dialogEditField;
                                    dispatch(
                                        setFaqs(
                                            [...faqs].map((o) =>
                                                o[dialog.key] === dialog.keyvalue
                                                    ? { ...o, [dialog.field]: text, dirty: true }
                                                    : o
                                            )

                                        )
                                    );


                                    setDialog({});
                                } catch (e) { }
                            }}
                        >
                            save
                        </Button>

                        <Button
                            onClick={() => {
                                setDialog({});
                            }}
                        >
                            close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

const DataTable = (props) => {
    const { setDialog, dialog, faqs, setEditField } = props;
    const dispatch = useDispatch();

    let odd = true;
    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
                <Table
                    size="small"
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: "50%" }}>question</TableCell>
                            <TableCell style={{ width: "20%" }}>answer</TableCell>
                            <TableCell style={{ width: "10%" }}>rank</TableCell>
                            <TableCell style={{ width: "15%" }}>language</TableCell>
                            <TableCell style={{ width: "5%" }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(faqs) &&
                            faqs.map((e, idx) => {
                                odd = !odd;
                                return (
                                    <TableRow
                                        key={idx}
                                        style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                        <TableCell style={{  width: "50%" }}>
                                        <TextField variant="standard"
                                                fullWidth
                                                value={e.question ? e.question : ""}
                                                onChange={(evt) => {
                                                    let tmp = faqs.map((o, i) => {
                                                        if (o.id === e.id) {
                                                            return {
                                                                ...o,
                                                                question: evt.target.value,
                                                                dirty: true
                                                            };
                                                        } else {
                                                            return o;
                                                        }
                                                    });
                                                    dispatch(setFaqs(tmp));
                                                }}
                                            />                                            
                                 
                                        </TableCell>


                                        <TableCell style={{ width: "20%" }}>
                                            <Button
                                                style={{ color: e.answer ? "blue" : undefined }}
                                                onClick={() => {
                                                    setDialog({
                                                        keyvalue: e.id,
                                                        key: "id",
                                                        field: "answer",
                                                    });
                                                    setEditField(e.answer);
                                                }}
                                            >
                                                edit
                                            </Button>
                                        </TableCell>

                                        <TableCell style={{ width: "10%" }}>
                                            <TextField variant="standard"
                                                value={e.rank ? e.rank : ""}
                                                onChange={(evt) => {
                                                    let tmp = faqs.map((o, i) => {
                                                        if (o.id === e.id) {
                                                            return {
                                                                ...o,
                                                                rank: evt.target.value,
                                                                dirty: true
                                                            };
                                                        } else {
                                                            return o;
                                                        }
                                                    });
                                                    dispatch(setFaqs(tmp));
                                                }}
                                            />

                                        </TableCell>
                                        <TableCell style={{ width: "15%" }}>
                                            {e.language ? e.language : ""}
                                        </TableCell>
                                        <TableCell style={{ width: "5%" }}>
                                            <Button disabled={!e.dirty}
                                                onClick={() => {
                                                    dispatch(saveFaqs({ data: e }));
                                                    dispatch(
                                                        setFaqs(
                                                            [...faqs].map((o) =>
                                                                o.id === e.id
                                                                    ? { ...o, dirty: false }
                                                                    : o
                                                            )

                                                        )
                                                    );
                                                }}
                                            >
                                                save
                                            </Button>

                                        </TableCell>

                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};
