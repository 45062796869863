import {
  createSlice,
  createSelector,
  isPending,
  isRejected,
  isFulfilled,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";
import { getHost } from "../utils";

let myAxiosInterceptor;

export const doLogin = createAsyncThunk(
  "system/dologin",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(getHost() + "/login", {
        login: payload.login,
        pwd: payload.pwd,
   
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

export const doSignup = createAsyncThunk(
  "system/dosignup",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(getHost() + "/signup", {
        login: payload.login,
        pwd: payload.pwd,
        nickname: payload.nickname,
        email: payload.email,
        phone: payload.phone,
        baseagency : payload.baseagency,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error?.response?.data ? error.response.data : error.message,
      });
    }
  }
);

const initialState = {
  loading: 0,
  error: "",
  token: "",
  nickname: "",
  userhash: "",
  agencyname: "",
  agencyid: "", //"2d03fadb-c6a9-46e0-a425-8103afa0004d",
  language: "de",
  agencies: [], 
  role : "norole"
};

export const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    logout: (state, action) => {
      axios.interceptors.request.eject(myAxiosInterceptor);
      state.token = "";
      state.agencies = [];
    },
    changeAgency: (state, action) => {
      state.agencyname = action.payload.agencyname;
      state.agencyid = action.payload.agencyid;
      state.role = action.payload.role;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, { payload, meta }) => {
      let token = payload.token;
      myAxiosInterceptor = axios.interceptors.request.use(
        (config) => {
          config.headers["Authorization"] = `Bearer ${token}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      if (payload.agencyid) {
        state.agencyid = payload.agencyid;
        state.nickname = payload.nickname;
        state.userhash = payload.userhash;
        state.agencyname = payload.agencyname;
        state.token = payload.token;
        state.agencies = payload.agencies;
        state.role = payload.role;
      }
    });
    builder.addMatcher(isPending, (state, action) => {
      state.loading += 1;

      state.error = "";
    });
    builder.addMatcher(isRejected, (state, action) => {
      console.log(action);
      state.loading -= 1;

      state.error = action?.payload?.error;
    });
    builder.addMatcher(isFulfilled, (state, action) => {
      state.loading -= 1;
      state.error = "";
    });
  },
});

export const selectSystem = createSelector(
  (state) => ({
    loading: state.system.loading,
    error: state.system.error,
  }),
  (state) => state
);

export const { logout, changeAgency } = systemSlice.actions;

export default systemSlice.reducer;
