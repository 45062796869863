import * as React from "react";
import Button from "@mui/material/Button";
import { FormattedDate, FormattedTime } from "react-intl";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPerformancelog,openwrk } from "../features/dataApiCalls";

export default function PerformancelogPage(props) {
  const dispatch = useDispatch();

  const performancelog = useSelector((state) => state.data.performancelog);
  //const { holiDays, holidayfilter } = props;
  //const [holidaysort, setHolidaysort] = React.useState({});
  let odd = true;
  console.log(performancelog);
  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <div style={{ marginLeft: 16 }}>
          <Button
            style={{ marginBottom: 16 }}
            onClick={() => dispatch(getPerformancelog())}
            variant="contained"
          >
            Get Data
          </Button>
          </div>
          <div style={{ marginLeft: 16 }}>
          <Button
            style={{ marginBottom: 16 }}
            onClick={() => dispatch(openwrk())}
            variant="contained"
          >
            Open
          </Button>          
        </div>
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "5%", cursor: "pointer" }}>
                  agency
                </TableCell>
                <TableCell style={{ width: "5%", cursor: "pointer" }}>
                  queryid
                </TableCell>
                <TableCell style={{ width: "10%", cursor: "pointer" }}>
                  region
                </TableCell>                
                <TableCell style={{ width: "10%", cursor: "pointer" }}>
                  doi
                </TableCell>
                <TableCell style={{ width: "10%", cursor: "pointer" }}>
                  context
                </TableCell>                
                <TableCell style={{ width: "10%", cursor: "pointer" }}>
                  seconds
                </TableCell>
                <TableCell style={{ width: "40%", cursor: "pointer" }}>
                  properties
                </TableCell>
                <TableCell style={{ width: "10%", cursor: "pointer" }}>
                  sessionid
                </TableCell>




              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(performancelog) &&
                performancelog.map((obj, idx) => {
                  odd = !odd;
                  return (
                    <TableRow
                      key={idx}
                      style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell style={{ width: "5%" }}>
                        {obj.agency ? obj.agency : ""}
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        {obj.id ? obj.id : ""}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {obj.region ? obj.region : ""}
                      </TableCell>                      
                      <TableCell style={{ width: "10%" }}>
                        {obj.doi ? (
                          <>
                            <FormattedDate
                              value={new Date(obj.doi)}
                              year="2-digit"
                              month="2-digit"
                              day="2-digit"
                            />{" "}
                            <FormattedTime value={new Date(obj.doi)} />
                          </>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {obj.context ? obj.context : ""}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {obj.seconds ? obj.seconds : ""}
                      </TableCell>
                      <TableCell style={{ width: "40%" }}>
                        {obj.propertytext ? obj.propertytext : ""}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {obj.sessionid ? obj.sessionid : ""}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {obj.context ? obj.context : ""}
                      </TableCell>

                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
