import * as React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';


import { FormattedDate } from "react-intl";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSystemhelp, saveSystemhelp, usesystemhelp } from "../features/dataApiCalls";

export default function HelpPage(props) {
  const { module } = props;
  const dispatch = useDispatch();
  const userhash = useSelector((state) => state.system.userhash);
  const systemhelp = useSelector((state) => state.data.systemhelp);

  const [newQuestion, setNewquestion] = React.useState("");
  const [showQuestion, setShowquestion] = React.useState(false);
  const [curQuestion, setCurquestion] = React.useState("");

  React.useEffect(() => {
    dispatch(getSystemhelp({ module: module }));
  }, []);


  return (
    <>
      <div>
        {Array.isArray(systemhelp) &&
          systemhelp.map((e, idx) => {
            return (<>
              <div style={{ color: "black", fontSize: 16, marginTop: 30, border:true,backgroundColor: (e.id == curQuestion ? "lightgrey" : "white") }}>
                <span onClick={() => {
                  if (e.id != curQuestion) dispatch(usesystemhelp({ id: e.id }));
                  setCurquestion(e.id);
                }}> {e.question}  </span><span  style={{ color: "darkgrey", fontSize: 8 }} >{e.rank}</span>
              </div>
              {e.id == curQuestion && <>

                <div style={{ color: "darkgrey", fontSize: 16 }}
                  dangerouslySetInnerHTML={{ __html: (e.answer ? e.answer : "leider noch keine Antwort erstellt") }}>

                </div>
              </>}

            </>)
          })}
        {Array.isArray(systemhelp) && systemhelp.length == 0 && <span>Noch keine Fragen vorhanden</span>}
      </div>
      <div> <br /><br />
        {!showQuestion && <> <span style={{ color: "blue", fontSize: 12 }} onClick={() => {
          setShowquestion(true);
        }}>Ich hab da noch eine Frage...</span></>}
        {showQuestion && <>
          <TextField
            fullWidth
            label="neue Frage"
            value={newQuestion}
            onChange={(evt) => {
              setNewquestion(evt.target.value);

            }}
          />
          <Button
            onClick={() => {
              setShowquestion(false);
            }}
          >
            abbrechen
          </Button>
          <Button
            onClick={() => {
              dispatch(saveSystemhelp([{ module: module, question: newQuestion, dirty: true, userhash: userhash }]));
              setShowquestion(false);
            }}
          >
            speichern
          </Button>
        </>}
      </div>
    </>
  );
}


