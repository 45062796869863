import * as React from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CropFreeIcon from "@mui/icons-material/CropFree";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getHost } from "../utils";
/*import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";*/
import {
  getFolders,
  deleteFile as actionDeleteFile,
  getFilelist,
  getFiles,
  searchFile,
  shareFile as actionShareFile,
  getAgencyproperties,
  saveAlternatetext,
} from "../features/dataApiCalls";
import { setFiles, setOpenedfolders } from "../features/dataSlice";
import { unwrapResult } from "@reduxjs/toolkit";

export default function GalleryPage(props) {
  const dispatch = useDispatch();

  //const holidays = useSelector((state) => state.system.holidays);

  const [deleteFile, setDeleteFile] = React.useState({});

  //const filelist = useSelector((state) => state.data.filelist);
  const role = useSelector((state) => state.system.role);
  const agencyid = useSelector((state) => state.system.agencyid);
  const agencyproperties = useSelector((state) => state.data.agencyproperties);
  const folders = useSelector((state) => state.data.folders);
  const files = useSelector((state) => state.data.files);
  const searchedfiles = useSelector((state) => state.data.searchedfiles);
  const openedfolders = useSelector((state) => state.data.openedfolders);

  const [isFileSearch, setIsFileSearch] = React.useState(false);
  const [fileSearch, setFileSearch] = React.useState("");

  const [selectedFolder, setSelectedFolder] = React.useState("");


  React.useEffect(() => {
    dispatch(getAgencyproperties());

    if (folders.length < 2)
      dispatch(getFolders());
  }, []);
  console.log(agencyproperties?.properties?.associated_agencies);

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    //if (!destination) return;

    //const newItems = reorder(items, source.index, destination.index);

    //setItems(newItems);
    console.log(destination, source);
  };

  //React.useEffect(() => {
  //    dispatch(getFilelist());
  //}, []);

  return (
    <>
      <Dialog
        fullWidth={true}

        open={deleteFile?.id ? true : false}
        onClose={() => setDeleteFile({})}
      >
        <DialogTitle>Löschen</DialogTitle>
        <DialogContent>Datei "{deleteFile.filename}" löschen?</DialogContent>
        <DialogActions>
          <Button
            disabled={!(role == "agencyadmin" || role == "admin" || role === "master")}
            onClick={() => {
              dispatch(
                actionDeleteFile({
                  documentid: deleteFile.id,
                  folderid: selectedFolder,
                })
              ).then(unwrapResult)
                .then((originalPromiseResult) => { })
                .catch((e) => { alert(e.error.msg) });
              dispatch(getFiles({ folderid: selectedFolder }));
              setDeleteFile({});
            }}
          >
            ok {role}
          </Button>

          <Button
            onClick={() => {
              setDeleteFile({});
            }}
          >
            cancel
          </Button>
        </DialogActions>
      </Dialog >
      {/*<DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-folders">
        {(provided) => (
          <div
            style={{
              minHeight: 200,
              minWidth: 300,
              borderTop: "solid 2px black",
              borderTop: "solid 2px black",
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            sad
          </div>
        )}
      </Droppable>

      <Droppable droppableId="droppable-folders2">
        {(provided) => (
          <div
            style={{
              minHeight: 200,
              minWidth: 300,
              borderTop: "solid 2px black",
              borderTop: "solid 2px black",
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            sad
          </div>
        )}
        </Droppable>*/}
      < div style={{ display: "flex" }
      }>
        <div
          style={{
            maxWidth: "30%",
            minWidth: "30%",
            overflowX: "scroll",
            whiteSpace: "nowrap",
          }}
        >
          <NextLevel
            parentid={agencyid}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            isFileSearch={isFileSearch}
          />
          <NextLevel
            parentid={"00000000-0000-0000-0000-000000000000"}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            isFileSearch={isFileSearch}
          />
        </div>

        {/*<Droppable droppableId="droppable-list">
        {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>*/}
        <div style={{ flexGrow: 4 }}>
          <>
            <Grid container align="center">
              <Grid item xs={2}>
                {!isFileSearch && selectedFolder && (
                  <>
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      multiple
                      type="file"
                      onChange={() => {
                        const fileInput = document.querySelector(
                          "#raised-button-file"
                        );
                        const formData = new FormData();

                        formData.append("file", fileInput.files[0]);

                        const options = {
                          method: "POST",
                          body: formData,
                        };

                        fetch(
                          getHost() + "upload/" + agencyid + "/" + selectedFolder + "/picture",
                          options
                        )
                          .then((response) => {
                            return response.json();
                          })
                          .then((data) => {

                            dispatch(getFiles({ folderid: selectedFolder }));
                          })
                          .catch((e) => {
                            console.log(e);
                          });
                      }}
                    />

                    <label htmlFor="raised-button-file">
                      <Button variant="contained" component="span">
                        Upload
                      </Button>
                    </label>
                  </>
                )}
              </Grid>
              <Grid item xs={8}>
                <form
                  onSubmit={(evt) => {
                    evt.preventDefault();
                    setIsFileSearch(true);
                    dispatch(searchFile({ search: fileSearch }));
                  }}
                >
                  <TextField
                    variant="standard"
                    value={searchFile ? fileSearch : ""}
                    onChange={(e) => setFileSearch(e.target.value)}
                  />
                  &nbsp;
                  <Button variant="contained" type="submit">
                    Search Files
                  </Button>
                </form>
              </Grid>
              {//selectedFolder && (
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setIsFileSearch(false);
                      dispatch(getFiles({ folderid: selectedFolder }));
                    }}
                  >
                    Get Files
                  </Button>
                </Grid>
                //)
              }
              {!isFileSearch && Array.isArray(files) && files.length > 0 && (
                <Paper style={{ width: "90%", margin: "5%" }}>
                  <Grid container spacing={2}>
                    {" "}
                    {files.map((o, i) => {
                      return (
                        <>
                          <Grid key={i} item xs={3}>
                            {/*<Draggable draggableId={o.filename} index={i}>
                              {(provided, snapshot) => (
                      <div>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >*/}
                            <img src={o.url} style={{ width: "100%" }} title={o.url} />
                            {/*</div>*/}

                            {/*!snapshot.isDragging && (*/}
                            <div align="center">
                              {
                                o.filename /*+
                                " (" +
                                Math.floor(o.info.ST_SIZE / 1000) +
                              " KB)"*/
                              }

                              {agencyproperties?.properties?.associated_agencies &&
                                (<> <Button
                                  variat="text"
                                  onClick={() => {
                                    dispatch(
                                      actionShareFile({
                                        documentid: o.documentid,
                                        folderid: selectedFolder,
                                      })
                                    ).then(unwrapResult)
                                      .then((originalPromiseResult) => { })
                                      .catch((e) => { alert(e.error.msg) });

                                  }}
                                >
                                  share
                                </Button>
                                </>)}
                              <Button
                                variat="text"
                                onClick={(e) => {
                                  setDeleteFile({
                                    id: o.documentid,
                                    filename: o.filename,
                                  });
                                }}
                              >
                                delete
                              </Button>
                              <div>
                                <TextField value={o.alternatetext ? o.alternatetext : ""}
                                  onBlur={() => {
                                    dispatch(saveAlternatetext({ id: o.folderdocumentid, alternatetext: o.alternatetext }));
                                  }}
                                  onChange={(evt) => {
                                    console.log(JSON.stringify(files));
                                    let tmpFiles = files.map((ele) => ele.folderdocumentid === o.folderdocumentid ? { ...ele, alternatetext: evt.target.value } : ele);
                                    dispatch(setFiles(tmpFiles));
                                  }}
                                />
                              </div>
                            </div>
                          </Grid>
                          {/*})}*/}
                          {/*)
                      </div>
                                    </Draggable>*/}
                        </>
                      );
                    })}
                  </Grid>
                </Paper>
              )}

              {isFileSearch &&
                Array.isArray(searchedfiles) &&
                searchedfiles.length > 0 && (
                  <Paper style={{ width: "90%", margin: "5%" }}>
                    <Grid container spacing={2}>
                      {" "}
                      {searchedfiles.map((o, i) => {

                        return (
                          <>
                            <Grid key={i} item xs={3}>
                              {/*<Draggable draggableId={o.filename} index={i}>
                              {(provided, snapshot) => (
                      <div>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >*/}
                              <span
                                title={o.foldernames}
                                onClick={() => {
                                  setSelectedFolder(o.folderid);
                                  let opened = [];

                                  let cur = folders.find(
                                    (f) => f.folderid === o.folderid
                                  );
                                  //opened.push(cur.folderid);
                                  while (cur && cur.level > -1) {
                                    cur = folders.find(
                                      (f) => f.folderid === cur.parentid
                                    );
                                    if (cur) {
                                      opened.push(cur.folderid);
                                    }
                                  }
                                  dispatch(setOpenedfolders(opened));
                                }}
                              >
                                <img src={o.url} style={{ width: "100%" }} />
                              </span>
                              {/*</div>*/}

                              {/*!snapshot.isDragging && (*/}
                              <div align="center">
                                {
                                  o.filename /*+
                                " (" +
                                Math.floor(o.info.ST_SIZE / 1000) +
                              " KB)"*/
                                }
                                {agencyproperties?.properties?.associated_agencies &&
                                  (<> <Button
                                    variat="text"
                                    onClick={() => {
                                      dispatch(
                                        actionShareFile({
                                          documentid: o.documentid,
                                          folderid: selectedFolder,
                                        })
                                      ).then(unwrapResult)
                                        .then((originalPromiseResult) => { })
                                        .catch((e) => { alert(e.error.msg) });

                                    }}
                                  >
                                    share
                                  </Button>
                                  </>)}
                                <Button
                                  variat="text"
                                  onClick={(e) => {
                                    setDeleteFile({
                                      id: o.documentid,
                                      filename: o.filename,
                                    });
                                  }}
                                >
                                  delete
                                </Button>
                                <div>SEARCH {o.filename}  {o.folderdocumentid}</div>
                              </div>
                            </Grid>
                            {/*})}*/}
                            {/*)
                      </div>
                                    </Draggable>*/}
                          </>
                        );
                      })}
                    </Grid>
                  </Paper>
                )}
            </Grid>
          </>
          {/*    {provided.placeholder}
          </div>
        )}
      </Droppable>
                    </DragDropContext>*/}
        </div>
      </div >
    </>
  );
}

const NextLevel = (props) => {
  const { parentid, selectedFolder, setSelectedFolder, isFileSearch } = props;
  const dispatch = useDispatch();
  const folders = useSelector((state) => state.data.folders);
  const openedfolders = useSelector((state) => state.data.openedfolders);
  return (
    <>
      {folders
        .filter((e) => e.parentid === parentid)
        .map((e) => {
          return (
            <div
              style={{
                paddingLeft: 10 * (e.level + 1),
              }}
            >
              <div>
                {folders.find((f) => e.folderid === f.parentid) ? (
                  <span>
                    {openedfolders.includes(e.folderid) ? (
                      <RemoveIcon
                        style={{
                          cursor: "pointer",
                          verticalAlign: "bottom",
                          fontSize: "18px !important",
                        }}
                        onClick={() =>
                          dispatch(
                            setOpenedfolders(
                              openedfolders.filter((a) => a !== e.folderid)
                            )
                          )
                        }
                      />
                    ) : (<>
                      {
                        e.folderid != e.parentid && <AddIcon
                          style={{
                            cursor: "pointer",
                            verticalAlign: "bottom",
                            fontSize: "18px !important",
                          }}
                          onClick={() =>
                            dispatch(
                              setOpenedfolders([...openedfolders, e.folderid])
                            )
                          }
                        />
                      }
                    </>)}
                  </span>
                ) : (
                  <CropFreeIcon
                    style={{
                      verticalAlign: "bottom",
                      fontSize: "18px !important",
                    }}
                  />
                )}
                <span
                  title={e.name}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      e.folderid === selectedFolder ? "#c0c0c0" : undefined,
                  }}
                  onClick={() => {
                    if (e.level > -1 && !isFileSearch) {
                      setSelectedFolder(e.folderid);
                      dispatch(getFiles({ folderid: e.folderid }));
                    }
                  }}
                >
                  {e.name}
                </span>
              </div>
              {openedfolders.includes(e.folderid) && (
                <NextLevel
                  parentid={e.folderid}
                  isFileSearch={isFileSearch}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder}
                />
              )}
            </div>
          );
        })}
    </>
  );
};
