import * as React from "react";
import Button from "@mui/material/Button";
import { FormattedDate } from "react-intl";
import {

  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import UserGrid from "./UserGrid";

import { getHost } from "../utils";

import {
  getUsers,
  getProperty,
  saveUsers,
  getPictures,
} from "../features/dataApiCalls";
import { setUsers } from "../features/dataSlice";

export default function UserPage(props) {
  const dispatch = useDispatch();
  //const [search, setSearch] = React.useState("");
  const role = useSelector((state) => state.system.role);
  const users = useSelector((state) => state.data.users);
  const agencyid = useSelector((state) => state.system.agencyid);
  const [currentId, setCurrentId] = React.useState("");
  const currentUser = users.find((e) => e.userhash === currentId)
    ? users.find((e) => e.userhash === currentId)
    : {};

  const properties = useSelector((state) => state.data.properties);

  //TODO: HIer ist noch was zu tun
  React.useEffect(() => {
    dispatch(getProperty({ tag: "userroles" }));
    dispatch(getUsers());
    dispatch(getPictures({ folderid: "portraits" }));
  }, []);

  let odd = true;

  let userrole = properties.userrole;
  if (!Array.isArray(userrole)) userrole = [];

  console.log(currentUser);

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <div>
          
          <ButtonGroup style={{ marginBottom: 16 }} variant="contained">
            <Button onClick={() => { dispatch(getUsers({})); dispatch(getPictures({ folderid: 'portraits' })); }}>
              Get Data
            </Button>
            {(role == "agencyadmin" || role == "admin" || role == "master") && (
              <>
                <Button onClick={() => {dispatch(saveUsers());dispatch(getUsers({}))}}>
                  Save Data
                </Button>
              </>)}
            <>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={() => {
                  const fileInput = document.querySelector(
                    "#raised-button-file"
                  );
                  const formData = new FormData();

                  formData.append("file", fileInput.files[0]);

                  const options = {
                    method: "POST",
                    body: formData,
                  };

                  fetch(
                    getHost() + "upload/" + agencyid + "/portraits/picture",
                    options
                  )
                    .then((response) => {
                      return response.json();
                    })
                    .then((data) => {

                      dispatch(getPictures({ folderid: 'portraits' }));
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }}
              />

              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  Upload Portrait
                </Button>
              </label>
            </>
          </ButtonGroup>
        </div>
      </div>
      <Paper
        style={{ marginBottom: 8 }}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        <TableContainer sx={{ maxHeight: 200 }}>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "15%" }}>name</TableCell>
                <TableCell style={{ width: "10%" }}>role</TableCell>
                <TableCell style={{ width: "20%" }}>communication</TableCell>
                <TableCell style={{ width: "20%" }}>email</TableCell>
                <TableCell style={{ width: "20%" }}>phone</TableCell>
                <TableCell style={{ width: "15%" }}>online</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(users) &&
                users.map((e, idx) => {
                  odd = !odd;
                  return (
                    <TableRow
                      onClick={() => {
                        setCurrentId(e.userhash);

                      }}

                      key={idx}
                      style={{
                        backgroundColor:
                          currentId === e.userhash
                            ? "#029aee"
                            : odd
                              ? "#f0f0f0"
                              : undefined,
                      }}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell style={{ width: "15%" }}>
                        {e.nickname ? e.nickname : ""}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {e.role ? e.role : ""}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {e.communication ? e.communication : ""}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {e.email ? e.email : ""}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {e.phone ? e.phone : ""}
                      </TableCell>
                      <TableCell style={{ width: "15%" }}>
                        {e.online ? (e.online ? 'online':'offline') : ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {currentUser.userhash && (
        <UserGrid
          users={users}
          currentUser={currentUser}
          userrole={userrole}
          role={role}
          setUsers={(obj) => dispatch(setUsers(obj))}
        />
      )}
    </>
  );
}
