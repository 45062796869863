import * as React from "react";
import Button from "@mui/material/Button";

import {
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,

} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import HotellistsGrid from "./HotellistsGrid";
import HotellistSearchhotel from "./HotellistSearchhotel";
import { getHost } from "../utils";
import {
  getHotellists,
  getHotellistitems,
  saveHotellists,
  deleteHotellist,
  deleteHotellistitem,
  setHotellistprivate,
  setHotellistpublic,
  duplicateHotellist,
} from "../features/dataApiCalls";
import { setHotellists } from "../features/dataSlice";

export default function HotellistsPage(props) {
  const { publiclist } = props;
  const dispatch = useDispatch();
  const role = useSelector((state) => state.system.role);
  const hotellists = useSelector((state) => state.data.hotellists);
  const hotellistitems = useSelector((state) => state.data.hotellistitems);
  const agencyid = useSelector((state) => state.system.agencyid);
  const [currentId, setCurrentId] = React.useState("");
  const currentHotellist = hotellists.find((e) => e.id === currentId)
    ? hotellists.find((e) => e.id === currentId)
    : {};


  //TODO: HIer ist noch was zu tun
  React.useEffect(() => {

  }, []);

  let odd = true;

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <div>

          <ButtonGroup style={{ marginBottom: 16 }} variant="contained">
            <Button onClick={() => dispatch(getHotellists((publiclist ? "00000000-0000-0000-0000-000000000000" : agencyid)))}>
              Get Data
            </Button>
            {(role == "admin" || role == "master") && (
              <>
                <Button onClick={() => dispatch(saveHotellists())}>
                  Save Data
                </Button>

                {(!publiclist ||  role=="admin" || role=="agencyadmin" || role=="master") &&<>
                <input
                  accept="application-xls/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={() => {
                    const fileInput = document.querySelector(
                      "#raised-button-file"
                    );
                    const formData = new FormData();

                    formData.append("file", fileInput.files[0]);

                    const options = {
                      method: "POST",
                      body: formData,
                    };

                    fetch(
                      getHost() + "upload/" + (publiclist ? "00000000-0000-0000-0000-000000000000" : agencyid) + "/none/hotellist",
                      options
                    )
                      .then((response) => {
                        console.log(response);
                        if (!response.ok) { throw Error(response.statusText) }
                        dispatch(getHotellists((publiclist ? "00000000-0000-0000-0000-000000000000" : agencyid)))
                        return response.json();
                      })
                      .then((data) => {

                      })
                      .catch((e) => {
                        alert(e?.response?.data ? e.response.data : e.message);
                        console.log(e);
                      });
                  }}
                />

                <label htmlFor="raised-button-file">
                  <Button variant="contained" component="span">
                    Upload Excel
                  </Button>
                </label></>}
              </>)}
          </ButtonGroup>
        </div>
      </div>
      <Paper
        style={{ marginBottom: 8 }}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        <TableContainer sx={{ maxHeight: 300 }}>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "20%" }}>Name</TableCell>
                <TableCell style={{ width: "60%" }}>Properties</TableCell>
                <TableCell style={{ width: "2%" }}>Status</TableCell>
                <TableCell style={{ width: "5%" }}></TableCell>
                <TableCell style={{ width: "5%" }}></TableCell>
                <TableCell style={{ width: "8%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(hotellists) &&
                hotellists.map((e, idx) => {
                  odd = !odd;
                  return (
                    <TableRow
                      onClick={() => {
                        setCurrentId(e.id);
                        dispatch(getHotellistitems({ hotellistid: e.id }))
                      }}

                      key={idx}
                      style={{
                        backgroundColor:
                          currentId === e.id
                            ? "#029aee"
                            : odd
                              ? "#f0f0f0"
                              : undefined,
                      }}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell style={{ width: "20%" }}>
                        {e.name ? e.name : ""}
                      </TableCell>
                      <TableCell style={{ width: "60%" }}>
                        {e.properties ? e.properties : ""}
                      </TableCell>
                      <TableCell style={{ width: "2%" }}>
                        {e.status ? e.status : ""}
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        <Button
                          variat="text"
                          onClick={(evt) => {
                            dispatch(deleteHotellist({ id: e.id }));
                            dispatch(getHotellists())
                          }}
                        >
                          delete
                        </Button>
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        <Button
                          variat="text"
                          onClick={(evt) => {
                            dispatch(duplicateHotellist({ id: e.id }));
                            dispatch(getHotellists())
                          }}
                        >
                          duplicate
                        </Button>
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        <Button
                          variat="text"
                          onClick={(evt) => {
                            publiclist ? (role=="admin"||role=="master" ? dispatch(setHotellistprivate({ hotellistid: e.id })) : dispatch(({}))) : dispatch(setHotellistpublic({ hotellistid: e.id }));
                            dispatch(getHotellists())
                          }}
                        >
                          {(publiclist ? (role=="admin"||role=="master"?"set private":""):"set public")}
                        </Button>
                      </TableCell>                      
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {currentHotellist.id && (
        <>
          <HotellistsGrid
            hotellistitems={hotellistitems}
            currentHotellistid={currentId}
            getHotellistitems={getHotellistitems}
            deleteHotellistitem={deleteHotellistitem}
          />
          <HotellistSearchhotel
            currentHotellistid={currentId}
            getHotellistitems={getHotellistitems}
          />
        </>
      )}
    </>
  );
}
