import * as React from "react";
import Button from "@mui/material/Button";
import { FormattedDate } from "react-intl";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,

} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAirlines } from "../features/dataApiCalls";

export default function AirlinesPage(props) {
  const dispatch = useDispatch();
  const airlines = useSelector((state) => state.data.airlines);

  const { airLines, airlinefilter } = props;
  const [airlinesort, setairLinesort] = React.useState({});

  React.useEffect(() => {
    dispatch(getAirlines());
  }, []);



  let odd = true;
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ width: "5%", cursor: "pointer" }}
                  onClick={() =>
                    setairLinesort({
                      column: "id",
                      direction: airlinesort.direction
                        ? airlinesort.direction * -1
                        : 1,
                    })
                  }
                >
                  id
                </TableCell>
                <TableCell
                  style={{ width: "25%", cursor: "pointer" }}
                  onClick={() =>
                    setairLinesort({
                      column: "name",
                      direction: airlinesort.direction
                        ? airlinesort.direction * -1
                        : 1,
                    })
                  }
                >
                  name
                </TableCell>
                <TableCell style={{ width: "50%" }}>properties</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(airlines) &&
                airlines
                  .filter((o) =>
                    airlinefilter
                      ? 1 === 1
                      : 1 === 1
                  )
                  .sort((a, b) => {
                    if (!airlinesort.column) return 0;
                    return airlinesort.direction == 1
                      ? a[airlinesort.column] > b[airlinesort.column]
                      : a[airlinesort.column] < b[airlinesort.column];
                  }
                  )
                  .map((obj, idx) => {
                    odd = !odd;
                    return (
                      <TableRow
                        key={idx}
                        style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell style={{ width: "5%" }}>
                          {obj.id ? obj.id : ""}
                        </TableCell>
                        <TableCell style={{ width: "25%" }}>
                          {obj.name ? obj.name : ""}
                        </TableCell>
                        <TableCell style={{ width: "50%" }}>
                          {obj.properties ? JSON.stringify(obj.properties) : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

