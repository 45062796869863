import * as React from "react";
import Button from "@mui/material/Button";
import { FormattedDate } from "react-intl";
import {
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getAllUsers, getProperty, saveAllUsers } from "../features/dataApiCalls";
import { setAllUsers } from "../features/dataSlice";

export default function AllUserPage(props) {
  const dispatch = useDispatch();
  //const [search, setSearch] = React.useState("");
  const role = useSelector((state) => state.system.role);
  const allusers = useSelector((state) => state.data.allusers);
  const agencies = useSelector((state) => state.system.agencies);
  const [currentId, setCurrentId] = React.useState("");
  const [usersort, setUsersort] = React.useState({});
  const properties = useSelector((state) => state.data.properties);

  const [namefilter, setAllUsersNameFilter] = React.useState("");
  let filtered = allusers.filter(
    (e) =>
      e.nickname.toLowerCase().indexOf(namefilter.toLowerCase()) >
      -1
  );
  const [agencyfilter, setAllUsersAgencyFilter] = React.useState("");
  filtered = filtered.filter(
    (e) =>
      (e.agency || "").toLowerCase().indexOf(agencyfilter.toLowerCase()) >
      -1
  );

  //TODO: HIer ist noch was zu tun
  React.useEffect(() => {
    dispatch(getProperty({ tag: "userroles" }));
    dispatch(getAllUsers());
  }, []);

  let odd = true;

  let userroles = properties.userroles;
  if (!Array.isArray(userroles)) userroles = [];

  console.log(userroles);
  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <div>
          <ButtonGroup style={{ marginBottom: 16 }} variant="contained">
            <Button onClick={() => dispatch(getAllUsers({}))}>Get Data</Button>
            {(role == "admin" || role == "master") && (
              <>
                <Button onClick={() => dispatch(saveAllUsers())}>Save Data</Button>
              </>
            )}
          </ButtonGroup>
        </div>
      </div>
      <Paper
        style={{ marginBottom: 8 }}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        <TableContainer>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "18%" }} onClick={() =>
                  setUsersort({
                    column: "nickname",
                    direction: usersort.direction
                      ? usersort.direction * -1
                      : 1,
                  })
                }>name
                  <TextField
                    variant="standard"
                    onChange={(e) => setAllUsersNameFilter(e.target.value)}
                  ></TextField>

                </TableCell>
                <TableCell style={{ width: "2%" }}>+</TableCell>
                <TableCell style={{ width: "10%" }}>baseagency</TableCell>

                <TableCell style={{ width: "10%" }} onClick={() =>
                  setUsersort({
                    column: "agency",
                    direction: usersort.direction
                      ? usersort.direction * -1
                      : 1,
                  })
                }>agency
                  <TextField
                    variant="standard"
                    onChange={(e) => setAllUsersAgencyFilter(e.target.value)}
                  ></TextField>
                </TableCell>
                <TableCell style={{ width: "10%" }}>role</TableCell>
                <TableCell style={{ width: "10%" }} onClick={() =>
                  setUsersort({
                    column: "signup",
                    direction: usersort.direction
                      ? usersort.direction * -1
                      : 1,
                  })
                }>signup
                </TableCell>
                <TableCell style={{ width: "10%" }}>email</TableCell>
                <TableCell style={{ width: "10%" }}>id</TableCell>
                <TableCell style={{ width: "10%" }}>userhash</TableCell>
                <TableCell style={{ width: "10%" }}>del</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(filtered) &&
                filtered.
                  sort((a, b) => {
                    if (!usersort.column) return 0;
                    if (usersort.column === "signup") {
                      return usersort.direction == 1
                        ? new Date(a.signup).getTime() >
                        new Date(b.signup).getTime()
                        : new Date(a.signup).getTime() <
                        new Date(b.signup).getTime();
                    }
                    if (usersort.column === "nickname" || usersort.column === "agency") {
                      return usersort.direction == 1
                        ? a[usersort.column] > b[usersort.column]
                        : a[usersort.column] < b[usersort.column];
                    }                    
                  }).
                  map((e, idx) => {
                    odd = !odd;
                    return (
                      <TableRow
                        onClick={() => {
                          setCurrentId(e.id);
                        }}
                        key={idx}
                        style={{
                          backgroundColor:
                            currentId === e.id
                              ? "#029aee"
                              : odd
                                ? "#f0f0f0"
                                : undefined,
                        }}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell style={{ width: "18%" }}>
                          {e.nickname ? e.nickname : ""}
                        </TableCell>
                        <TableCell style={{ width: "2%" }}>
                          <Button onClick={(evt) => {
                            dispatch(
                              setAllUsers([...allusers, { nickname: e.nickname, userhash: e.userhash, properties: {}, dirty: true, id: "" }]

                              )
                            );
                          }} >+</Button>
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {e.baseagency ? e.baseagency : ""}
                        </TableCell>

                        <TableCell style={{ width: "10%" }}>
                          <Select
                            labelId="select-typesl"
                            id="select-types"
                            variant="standard"
                            value={e.agencyid}
                            onChange={(evt) => {
                              console.log(evt.target.value);
                              dispatch(
                                setAllUsers(
                                  allusers.map((row) =>
                                    row.id === e.id
                                      ? {
                                        ...row,
                                        agencyid: evt.target.value,
                                        dirty: true,
                                      }
                                      : row
                                  )
                                )
                              );
                            }}
                          >
                            {agencies.map((o) => (
                              <MenuItem key={o.agencyid} value={o.agencyid}>
                                {o.agencyname}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          <Select
                            fullWidth
                            labelId="select-role"
                            id="select-role"
                            value={e.role ? e.role : ""}
                            variant="standard"
                            onChange={(evt) => {
                              console.log(evt.target.value);
                              dispatch(
                                setAllUsers(
                                  allusers.map((row) =>
                                    row.id === e.id
                                      ? {
                                        ...row,
                                        role: evt.target.value,
                                        dirty: true,
                                      }
                                      : row
                                  )
                                )
                              );
                            }}
                          >
                            {userroles.map((o) => (
                              <MenuItem key={o.label} value={o.label}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {e.signup ? e.signup : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {e.email ? e.email : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {e.id ? e.id : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {e.userhash ? e.userhash : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          <Button style={{ color: e.delete ? "red" : undefined }} onClick={(evt) => {

                            dispatch(
                              setAllUsers(
                                allusers.map((row) =>
                                  row.id === e.id
                                    ? {
                                      ...row,
                                      delete: !row.delete,
                                      dirty: true,
                                    }
                                    : row
                                )
                              )
                            );
                          }} >löschen</Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
