import * as React from "react";

import { FormattedDate } from "react-intl";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getHost } from "../utils";
import { getOfferstopper, saveOfferstopper, duplicateOfferstopper, getCoupons } from "../features/dataApiCalls";
import { setOfferstopper } from "../features/dataSlice";



export default function OfferstopperPage(props) {
  const dispatch = useDispatch();

  const offerstopper = useSelector((state) => state.data.offerstopper);
  const coupons = useSelector((state) => state.data.coupons);
  const [dialog, setDialog] = React.useState({});
  const [editField, setEditField] = React.useState("");
  const role = useSelector((state) => state.system.role);
  let dialogObject = {};

  React.useEffect(() => {
    dispatch(getOfferstopper({ allitems: "0" }))
    dispatch(getCoupons({ allitems: "1" }));
  }, []);


  if (dialog.keyvalue) {
    dialogObject = offerstopper.find((o) => o[dialog.key] === dialog.keyvalue);
  }
  return (
    <>
      {dialog.keyvalue && (
        <EditDialog
          setDialog={setDialog}
          dialog={dialog}
          offerstopper={offerstopper}
          dialogObject={dialogObject}
          editField={editField}
          role={role}
        />
      )}

      <DataTable
        setDialog={setDialog}
        dialog={dialog}
        coupons={coupons}
        offerstopper={offerstopper}
        setEditField={setEditField}
      />
    </>
  );
}



const EditDialog = (props) => {
  const { setDialog, dialog, offerstopper, dialogObject, editField, role } = props;
  const dispatch = useDispatch();
  const [dialogEditField, setDialogEditField] = React.useState(editField);

  React.useEffect(() => {
    setDialogEditField(editField);
  }, [editField]);

  return (
    <>
      {dialog.keyvalue && (
        <Dialog
          fullWidth={true}
          open={dialog.keyvalue ? true : false}
          onClose={() => setDialog({})}
        >
          <DialogTitle>{dialog.field}</DialogTitle>
          <DialogContent>
            <TextField
              multiline
              fullWidth
              rows={30}

              value={
                dialogEditField

              }
              onChange={(evt) => {

                setDialogEditField(evt.target.value);


                return;

              }}
            />
          </DialogContent>
          <DialogActions>

            <Button
              onClick={() => {
                try {



                  if (dialog.field === "properties") {
                    let jsontext = JSON.parse(dialogEditField);

                    dispatch(
                      setOfferstopper(
                        [...offerstopper].map((o) =>
                          o[dialog.key] === dialog.keyvalue
                            ? { ...o, properties: jsontext, dirty: true }
                            : o
                        )
                      )
                    );
                  }
                  if (dialog.field === "mobilehtml" || dialog.field === "desktophtml") {
                    let text = dialogEditField;
                    dispatch(
                      setOfferstopper(
                        [...offerstopper].map((o) =>
                          o[dialog.key] === dialog.keyvalue
                            ? { ...o, [dialog.field]: text, dirty: true }
                            : o
                        )

                      )
                    );
                  }

                  setDialog({});
                } catch (e) { }
              }}
            >
              save
            </Button>

            <Button
              onClick={() => {
                setDialog({});
              }}
            >
              close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const DataTable = (props) => {
  const { setDialog, dialog, offerstopper, setEditField, coupons } = props;
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = React.useState("");
  const [allitems, setAllitems] = React.useState("0");
  const role = useSelector((state) => state.system.role);
  let odd = true;
  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <div><FormControlLabel control={<Checkbox checked={allitems === "1"} onChange={() => setAllitems(allitems == "0" ? "1" : "0")} />} label="all items" />
          <ButtonGroup style={{ marginBottom: 16 }} variant="contained">
            <Button onClick={() => dispatch(getOfferstopper({ allitems: allitems }))}>
              Get Data
            </Button>
            {(role == "agencyadmin" || role == "admin" || role == "master") && (
              <>
                <Button onClick={() => dispatch(saveOfferstopper())}>
                  Save Data
                </Button>
                <Button
                  onClick={() => {
                    dispatch(duplicateOfferstopper({ id: currentId })).then(() =>
                      dispatch(getOfferstopper({ allitems: "N" })));
                  }}

                >
                  Duplicate Data
                </Button></>)}

          </ButtonGroup>
        </div>
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "20%" }}>label</TableCell>
                <TableCell style={{ width: "5%" }}>page</TableCell>
                <TableCell style={{ width: "5%" }}>row</TableCell>
                <TableCell style={{ width: "5%" }}>rank</TableCell>
                <TableCell style={{ width: "15%" }}>coupon</TableCell>
                <TableCell style={{ width: "15%" }}>properties</TableCell>
                <TableCell style={{ width: "15%" }}>desktophtml</TableCell>
                <TableCell style={{ width: "15%" }}>mobilehtml</TableCell>
                <TableCell style={{ width: "15%" }}>status</TableCell>
                <TableCell style={{ width: "5%" }}>

                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(offerstopper) &&
                offerstopper.map((e, idx) => {
                  odd = !odd;
                  return (
                    <TableRow onClick={() => {
                      setCurrentId(e.id);
                    }}

                      key={idx}
                      style={{
                        backgroundColor:
                          currentId === e.id
                            ? "#029aee"
                            : odd
                              ? "#f0f0f0"
                              : undefined,
                      }}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >

                      <TableCell style={{ width: "20%" }}>
                        <TextField
                          fullWidth

                          value={e.label ? e.label : ""}
                          onChange={(evt) => {
                            let tmp = offerstopper.map((o, i) => {
                              if (o.id === e.id) {
                                return {
                                  ...o,
                                  label: evt.target.value,
                                  dirty: true,

                                };
                              } else {
                                return o;
                              }
                            });
                            dispatch(setOfferstopper(tmp));

                          }}

                        />

                      </TableCell>

                      <TableCell style={{ width: "5%" }}>
                        {e.properties.page ? e.properties.page : ""}
                      </TableCell>

                      <TableCell style={{ width: "5%" }}>
                        {e.properties.row ? e.properties.row : ""}
                      </TableCell>

                      <TableCell style={{ width: "5%" }}>
                        {e.properties.rank ? e.properties.rank : ""}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>

                        <Select
                          fullWidth
                          labelId="select-listid"
                          id="select-list-id"
                          value={e.couponid ? e.couponid : ""}
                          variant="standard"
                          onChange={(evt) => {
                            let tmp = offerstopper.map((o, i) => {
                              if (o.id === e.id) {
                                return {
                                  ...o,
                                  couponid: evt.target.value,
                                  dirty: true,
                                };
                              } else {
                                return o;
                              }
                            });
                            dispatch(setOfferstopper(tmp));
                          }}
                        >
                          {coupons.map((o) => (
                            <MenuItem key={o.id} value={o.id}>
                              {o.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell style={{ width: "15%" }}>
                        <Button
                          style={{ color: e.properties ? "blue" : undefined }}
                          onClick={() => {
                            setDialog({
                              keyvalue: e.id,
                              key: "id",
                              field: "properties",
                            });
                            setEditField(
                              JSON.stringify(e.properties, null, 4)
                            );
                          }}
                        >
                          edit
                        </Button>
                      </TableCell>
                      <TableCell style={{ width: "15%" }}>
                        <Button
                          style={{ color: e.desktophtml ? "blue" : undefined }}
                          onClick={() => {
                            setDialog({
                              keyvalue: e.id,
                              key: "id",
                              field: "desktophtml",
                            });
                            setEditField(e.desktophtml);
                          }}
                        >
                          edit
                        </Button>
                      </TableCell>

                      <TableCell style={{ width: "15%" }}>
                        <Button
                          style={{ color: e.mobilehtml ? "blue" : undefined }}
                          onClick={() => {
                            setDialog({
                              keyvalue: e.id,
                              key: "id",
                              field: "mobilehtml",
                            });
                            setEditField(e.mobilehtml);
                          }}
                        >
                          edit
                        </Button>
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        <TextField
                          fullWidth

                          value={e.status ? e.status : ""}
                          onChange={(evt) => {
                            let tmp = offerstopper.map((o, i) => {
                              if (o.id === e.id) {
                                return {
                                  ...o,
                                  status: evt.target.value,
                                  dirty: true,

                                };
                              } else {
                                return o;
                              }
                            });
                            dispatch(setOfferstopper(tmp));

                          }}

                        />

                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        <Button disabled={!e.dirty}
                          onClick={() => {
                            dispatch(saveOfferstopper({ data: e })).then(e => dispatch(getOfferstopper({ allitems: "N" })));
                            /*
                            dispatch(
                              setOfferstopper(
                                [...offerstopper].map((o) =>
                                  o.id === e.id
                                    ? {...o, dirty: false }
                        : o
                        )
  
                        )
                        );*/
                          }}
                        >
                          save
                        </Button>

                      </TableCell>

                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper >
    </>
  );
};
