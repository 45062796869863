import * as React from "react";

import { FormattedDate } from "react-intl";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getHost } from "../utils";
import { getContainer, saveContainer } from "../features/dataApiCalls";
import { setContainer } from "../features/dataSlice";

export default function ContainerPage(props) {
  const dispatch = useDispatch();

  const container = useSelector((state) => state.data.container);
  const [dialog, setDialog] = React.useState({});
  const [editField, setEditField] = React.useState("");
  const role = useSelector((state) => state.system.role);

  let dialogObject = {};

  React.useEffect(() => {
    dispatch(getContainer());
  }, []);


  if (dialog.keyvalue) {
    dialogObject = container.find((o) => o[dialog.key] === dialog.keyvalue);
  }
  return (
    <>
      {dialog.keyvalue && (
        <EditDialog
          setDialog={setDialog}
          dialog={dialog}
          container={container}
          dialogObject={dialogObject}
          editField={editField}
          role={role}
        />
      )}

      <DataTable
        setDialog={setDialog}
        dialog={dialog}
        container={container}
        setEditField={setEditField}
      />
    </>
  );
}



const EditDialog = (props) => {
  const { setDialog, dialog, container, dialogObject, editField, role } = props;
  const dispatch = useDispatch();
  const [dialogEditField, setDialogEditField] = React.useState(editField);

  React.useEffect(() => {
    setDialogEditField(editField);
  }, [editField]);

  return (
    <>
      {dialog.keyvalue && (
        <Dialog
          fullWidth={true}
          open={dialog.keyvalue ? true : false}
          onClose={() => setDialog({})}
        >
          <DialogTitle>{dialog.field}</DialogTitle>
          <DialogContent>
            <TextField
              multiline
              fullWidth
              rows={30}

              value={
                dialogEditField

              }
              onChange={(evt) => {

                setDialogEditField(evt.target.value);


                return;

              }}
            />
          </DialogContent>
          <DialogActions>

            <Button
              onClick={() => {
                try {



                  if (dialog.field === "body") {
                    let jsontext = JSON.parse(dialogEditField);

                    dispatch(
                      setContainer(
                        [...container].map((o) =>
                          o[dialog.key] === dialog.keyvalue
                            ? { ...o, body: jsontext, dirty: true }
                            : o
                        )
                      )
                    );
                  }
                  if (dialog.field === "header" || dialog.field === "footer") {
                    let text = dialogEditField;
                    dispatch(
                      setContainer(
                        [...container].map((o) =>
                          o[dialog.key] === dialog.keyvalue
                            ? { ...o, [dialog.field]: text, dirty: true }
                            : o
                        )

                      )
                    );
                  }

                  setDialog({});
                } catch (e) { }
              }}
            >
              save
            </Button>

            <Button
              onClick={() => {
                setDialog({});
              }}
            >
              close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const DataTable = (props) => {
  const { setDialog, dialog, container, setEditField } = props;
  const dispatch = useDispatch();
  console.log(container);
  const [containerfilter, setContainerFilter] = React.useState("");
  let filtered = container.filter(
    (e) =>
      e.tag.toLowerCase().indexOf(containerfilter.toLowerCase()) >
      -1
  );


  let odd = true;
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
        <Table
          size="small"
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "10%" }}>
                Tag
                <TextField
                  variant="standard"
                  onChange={(e) => setContainerFilter(e.target.value)}
                ></TextField>
              </TableCell>

              <TableCell style={{ width: "10%" }}>typ</TableCell>
              <TableCell style={{ width: "10%" }}>page</TableCell>
              <TableCell style={{ width: "20%" }}>header</TableCell>
              <TableCell style={{ width: "25%" }}>body</TableCell>
              <TableCell style={{ width: "20%" }}>footer</TableCell>
              <TableCell style={{ width: "5%" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(filtered) &&
              filtered.map((e, idx) => {
                odd = !odd;
                return (
                  <TableRow
                    key={idx}
                    style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ width: "10%" }}>
                      {e.tag ? e.tag : ""}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {e.typ ? e.typ : ""}
                    </TableCell>

                    <TableCell style={{ width: "10%" }}>
                      {e.page ? e.page : ""}
                    </TableCell>

                    <TableCell style={{ width: "20%" }}>
                      <Button
                        style={{ color: e.header ? "blue" : undefined }}
                        onClick={() => {
                          setDialog({
                            keyvalue: e.id,
                            key: "id",
                            field: "header",
                          });
                          setEditField(e.header);
                        }}
                      >
                        edit
                      </Button>
                    </TableCell>
                    <TableCell style={{ width: "25%" }}>
                      <Button
                        style={{ color: e.body ? "blue" : undefined }}
                        onClick={() => {
                          setDialog({
                            keyvalue: e.id,
                            key: "id",
                            field: "body",
                          });
                          setEditField(
                            JSON.stringify(e.body, null, 4)
                          );
                        }}
                      >
                        edit
                      </Button>
                    </TableCell>
                    <TableCell style={{ width: "20%" }}>
                      <Button
                        style={{ color: e.footer ? "blue" : undefined }}
                        onClick={() => {
                          setDialog({
                            keyvalue: e.id,
                            key: "id",
                            field: "footer",
                          });
                          setEditField(e.footer);
                        }}
                      >
                        edit
                      </Button>
                    </TableCell>
                    <TableCell style={{ width: "5%" }}>
                      <Button disabled={!e.dirty}
                        onClick={() => {
                          dispatch(saveContainer({ data: e }));
                          dispatch(
                            setContainer(
                              [...container].map((o) =>
                                o.id === e.id
                                  ? { ...o, dirty: false }
                                  : o
                              )

                            )
                          );
                        }}
                      >
                        save
                      </Button>

                    </TableCell>

                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
