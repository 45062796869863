import React, { useState } from "react";
import { IntlProvider } from "react-intl";

import German from "../lang/de.json";
import English from "../lang/en.json";
const Context = React.createContext();

const local = navigator.language;

let lang;
if (local === "en") {
  lang = English;
} else {
  lang = German;
}

const TranslationWrapper = (props) => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);
  function selectLanguage(lng) {
    const newLocale = lng;
    setLocale(newLocale);
    if (newLocale === "en") {
      setMessages(English);
    } else {
      setMessages(German);
    }
  }
  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};
export default TranslationWrapper;
