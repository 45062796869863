import * as React from "react";
import Button from "@mui/material/Button";
import { FormattedDate } from "react-intl";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAirports } from "../features/dataApiCalls";

export default function AirportsPage(props) {
  const dispatch = useDispatch();

  const airports = useSelector((state) => state.data.airports);


  return (
    <>
      <Search />
      <DataTable airports={airports} />
    </>
  );
}
const DataTable = (props) => {
  const { airports } = props;
  const { airPorts, airPortfilter } = props;
  const [airportsort, setAirPortsort] = React.useState({});
  let odd = true;
  return (
    <>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ width: "5%", cursor: "pointer" }}
                  onClick={() =>
                    setAirPortsort({
                      column: "iata",
                      direction: airportsort.direction
                        ? airportsort.direction * -1
                        : 1,
                    })
                  }
                >
                  iata
                </TableCell>
                <TableCell
                  style={{ width: "5%", cursor: "pointer" }}
                  onClick={() =>
                    setAirPortsort({
                      column: "icao",
                      direction: airportsort.direction
                        ? airportsort.direction * -1
                        : 1,
                    })
                  }
                >
                  icao
                </TableCell>
                <TableCell
                  style={{ width: "15%", cursor: "pointer" }}
                  onClick={() =>
                    setAirPortsort({
                      column: "name_en",
                      direction: airportsort.direction
                        ? airportsort.direction * -1
                        : 1,
                    })
                  }
                >
                  name_en
                </TableCell>
                <TableCell
                  style={{ width: "15%", cursor: "pointer" }}
                  onClick={() =>
                    setAirPortsort({
                      column: "name_de",
                      direction: airportsort.direction
                        ? airportsort.direction * -1
                        : 1,
                    })
                  }
                >
                  name_de
                </TableCell>
                <TableCell
                  style={{ width: "10%", cursor: "pointer" }}
                  onClick={() =>
                    setAirPortsort({
                      column: "country_de",
                      direction: airportsort.direction
                        ? airportsort.direction * -1
                        : 1,
                    })
                  }
                >
                  country_de
                </TableCell>
                <TableCell style={{ width: "10%" }}>length_ft</TableCell>
                <TableCell style={{ width: "10%" }}>latitude</TableCell>
                <TableCell style={{ width: "10%" }}>longitude</TableCell>
                <TableCell style={{ width: "10%" }}>elevation_ft</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(airports) &&
                airports
                  .filter((o) =>
                    airPortfilter
                      ? 1 === 1
                      : 1 === 1
                  )
                  .sort((a, b) => {
                    if (!airportsort.column) return 0;
                    return airportsort.direction == 1
                      ? a[airportsort.column] > b[airportsort.column]
                      : a[airportsort.column] < b[airportsort.column];
                  }
                  )
                  .map((obj, idx) => {
                    odd = !odd;
                    return (
                      <TableRow
                        key={idx}
                        style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell style={{ width: "5%" }}>
                          {obj.iata ? obj.iata : ""}
                        </TableCell>
                        <TableCell style={{ width: "5%" }}>
                          {obj.icao ? obj.icao : ""}
                        </TableCell>

                        <TableCell style={{ width: "15%" }}>
                          {obj.name_en ? obj.name_en : ""}
                        </TableCell>
                        <TableCell style={{ width: "15%" }}>
                          {obj.name_de ? obj.name_de : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {obj.country_de ? obj.country_de : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {obj.length_ft ? obj.length_ft : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {obj.latitude ? obj.latitude : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {obj.longitude ? obj.longitude : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {obj.elevation_ft ? obj.elevation_ft : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

const Search = (props) => {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState("");
  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault();
        dispatch(getAirports({ search: search }));
      }}
    >
      <div style={{ display: "flex" }}>

        <div style={{ flexGrow: 1 }}>
          <TextField
            variant="standard"
            label={"search country"}
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div style={{ marginLeft: 16 }}>
          <Button
            style={{ marginBottom: 16 }}
            type="submit"
            variant="contained"
          >
            Get Data
          </Button>
        </div>
      </div>
    </form>
  );
};