import * as React from 'react';
import Button from '@mui/material/Button';
import { FormattedDate } from 'react-intl';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getRoomNames, saveRoomNames } from '../features/dataApiCalls';
import { setOperatorRoomnames } from '../features/dataSlice';

export default function OperatorRoomnamesPage(props) {



    const dispatch = useDispatch();
    const [search, setSearch] = React.useState("");
    const operatorroomnames = useSelector((state) => state.data.operatorroomnames);
    const role = useSelector((state) => state.system.role);
    let odd = true;
    return (
        < >

            <form onSubmit={(evt) => {
                evt.preventDefault();
                dispatch(getRoomNames({ search: search }));
            }
            } ><div style={{ display: "flex" }}>
                    <div style={{ flexGrow: 1 }}><TextField value={search} fullWidth label="search or type new (+word) or today or refresh" variant="standard" onChange={(e) => setSearch(e.target.value)} /></div><div style={{ marginLeft: 16 }}>
                        <Button style={{ marginBottom: 16 }} type="submit" variant="contained">Get Data</Button></div></div></form>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>

                <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
                    <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>

                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: "30%" }}>operatorroomname</TableCell>
                                <TableCell style={{ width: "20%" }}>agencyroomname</TableCell>
                                <TableCell style={{ width: "10%" }}>keywords</TableCell>
                                <TableCell style={{ width: "14%" }}>recommondation</TableCell>
                                <TableCell style={{ width: "10%" }}>suggestions</TableCell>
                                <TableCell style={{ width: "5%" }}>roomtype</TableCell>
                                <TableCell style={{ width: "1%" }}>status</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(operatorroomnames) &&
                                operatorroomnames.map((e, idx) => {
                                    odd = !odd;
                                    return (
                                        <TableRow
                                            key={idx}
                                            style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell style={{ width: "30%" }}>
                                                {e.roomname ? e.roomname : ""}
                                            </TableCell>
                                            <TableCell style={{ width: "20%" }}>
                                                <TextField
                                                    fullWidth
                                                    disabled={role != "master"}
                                                    value={e.agencyroomname ? e.agencyroomname : ""}
                                                    onChange={(evt) => {
                                                        let tmp = operatorroomnames.map((o, i) => {
                                                            if (o.id === e.id) {
                                                                return {
                                                                    ...o,
                                                                    agencyroomname: evt.target.value,

                                                                };
                                                            } else {
                                                                return o;
                                                            }
                                                        });
                                                        dispatch(setOperatorRoomnames(tmp));

                                                    }}
                                                    onBlur={() => {
                                                        console.log(e)
                                                        dispatch(saveRoomNames({ data: e, }))
                                                    }
                                                    }
                                                />

                                            </TableCell>
                                            <TableCell style={{ width: "10%" }}>
                                                <TextField
                                                    fullWidth
                                                    disabled={role != "master"}
                                                    value={e.keywords ? e.keywords : ""}
                                                    onChange={(evt) => {
                                                        let tmp = operatorroomnames.map((o, i) => {
                                                            if (o.id === e.id) {
                                                                return {
                                                                    ...o,
                                                                    keywords: evt.target.value,

                                                                };
                                                            } else {
                                                                return o;
                                                            }
                                                        });
                                                        dispatch(setOperatorRoomnames(tmp));

                                                    }}
                                                    onBlur={() => {
                                                        console.log(e)
                                                        saveRoomNames(e)
                                                    }
                                                    }
                                                />

                                            </TableCell>

                                            <TableCell style={{ width: "14%" }}>
                                                <Button
                                                    disabled={role != "master"}
                                                    onClick={(evt) => {
                                                        let tmp = operatorroomnames.map((o, i) => {
                                                            if (o.id === e.id) {
                                                                return {
                                                                    ...o,
                                                                    agencyroomname: o.recommondation,

                                                                };
                                                            } else {
                                                                return o;
                                                            }
                                                        });

                                                    }}>
                                                    {e.recommondation ? e.recommondation : ""}
                                                </Button>
                                            </TableCell>
                                            <TableCell style={{ width: "10%" }}>
                                                {e.suggestions ? e.suggestions : "no suggestion"}
                                            </TableCell>

                                            <TableCell style={{ width: "5%" }}>
                                                {e.roomtyp ? e.roomtyp : ""}
                                            </TableCell>
                                            <TableCell style={{ width: "1%" }}>
                                                {e.status ? e.status : ""}
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>



        </ >
    );
}


