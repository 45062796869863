import * as React from "react";

import { FormattedDate } from "react-intl";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { dateToInputString } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { getHost } from "../utils";
import { saveCouponcodes, deleteCouponcode } from "../features/dataApiCalls";


export default function CouponcodesPage(props) {
  const { couponcodes } = props;
  const dispatch = useDispatch();

  const role = useSelector((state) => state.system.role);
  const [currentId, setCurrentId] = React.useState("");
  React.useEffect(() => {
  }, []);

  let odd = true;
  console.log(couponcodes);

  return (
    <>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "10%" }}>doi</TableCell>
                <TableCell style={{ width: "20%" }}>code</TableCell>
                <TableCell style={{ width: "20%" }}>validuntil</TableCell>
                <TableCell style={{ width: "20%" }}>properties</TableCell>
                <TableCell style={{ width: "10%" }}>status</TableCell>
                <TableCell style={{ width: "5%" }}></TableCell>

              </TableRow>
            </TableHead>
            <TableBody>

              {Array.isArray(couponcodes) &&
                couponcodes.map((e, idx) => {
                  odd = !odd;
                  return (
                    <TableRow onClick={() => {
                      setCurrentId(e.id);
                    }}

                      key={idx}
                      style={{
                        backgroundColor:
                          currentId === e.id
                            ? "#029aee"
                            : odd
                              ? "#f0f0f0"
                              : undefined,
                      }}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >

                      <TableCell style={{ width: "10%" }}>
                        {e.doi ? e.doi : ""}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {e.couponcode ? e.couponcode : ""}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {e.validuntil ? e.validuntil : ""}
                      </TableCell>
                      <TableCell style={{ width: "25%" }}>
                        {e.properties ? JSON.stringify(e.properties) : ""}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {e.status ? e.status : ""}
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        <Button
                          onClick={() => {
                            dispatch(deleteCouponcode({ id: e.id }));
                          }}
                        >
                          delete
                        </Button>
                      </TableCell>


                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper >

    </>
  );
}


