import * as React from "react";
import Button from "@mui/material/Button";
import { FormattedDate } from "react-intl";
import {
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AgencyActionsGrid from "./AgencyActionsGrid";
import { getHost } from "../utils";
import {
  duplicateAgencyaction,
  getAgencyactions,
  getProperty,
  getHotellists,
  saveAgencyactions,
  getPictures,

} from "../features/dataApiCalls";
import { setAgencyactions } from "../features/dataSlice";

export default function AgencyActionsPage(props) {
  const dispatch = useDispatch();
  //const [search, setSearch] = React.useState("");
  const role = useSelector((state) => state.system.role);
  const agencyactions = useSelector((state) => state.data.agencyactions);
  const hotellists = useSelector((state) => state.data.hotellists);
  const agencyid = useSelector((state) => state.system.agencyid);
  const [currentId, setCurrentId] = React.useState("");
  const [allitems, setAllitems] = React.useState("0");
  const [moreRows, setMorerows] = React.useState(false);
  const [codefilter, setCodeFilter] = React.useState("");
  const [labelfilter, setLabelFilter] = React.useState("");
  const [groupfilter, setGroupFilter] = React.useState("");
  const [webtextfilter, setWebtextFilter] = React.useState("");
  const [actionsort, setActionSort] = React.useState({});
  const currentAction = agencyactions.find((e) => e.id === currentId)
    ? agencyactions.find((e) => e.id === currentId)
    : {};

  const properties = useSelector((state) => state.data.properties);

  //TODO: HIer ist noch was zu tun
  React.useEffect(() => {
    dispatch(getProperty({ tag: "promotyp" }));
    dispatch(getProperty({ tag: "promoclass" }));
    dispatch(getProperty({ tag: "onpage" }));
    dispatch(getHotellists(agencyid));
    dispatch(getAgencyactions({ allitems: "0" }))
    //dispatch(getPictures({ folderid: "stickers", dropdown: "1" }));
  }, []);

  let filtered = agencyactions

  if (codefilter)
    filtered = filtered.filter(
      (e) => e.code && e.code.toLowerCase().indexOf(codefilter.toLowerCase()) > -1
    );
  if (labelfilter)
    filtered = filtered.filter(
      (e) => e.label && e.label.toLowerCase().indexOf(labelfilter.toLowerCase()) > -1
    );
  if (groupfilter)
    filtered = filtered.filter(
      (e) => e.actiongroup && e.actiongroup.toLowerCase().indexOf(groupfilter.toLowerCase()) > -1
    );
  if (webtextfilter)
    filtered = filtered.filter(
      (e) => e.properties.text && e.properties.text.toLowerCase().indexOf(webtextfilter.toLowerCase()) > -1
    );

  let odd = true;

  let promotyp = properties.promotyp;
  if (!Array.isArray(promotyp)) promotyp = [];
  let promoclass = properties.promoclass;
  if (!Array.isArray(promoclass)) promoclass = [];
  let onpage = properties.onpage;
  if (!Array.isArray(onpage)) onpage = [];



  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <div><FormControlLabel control={<Checkbox checked={allitems === "1"} onChange={() => setAllitems(allitems == "0" ? "1" : "0")} />} label="all items" />
          <ButtonGroup style={{ marginBottom: 16 }} variant="contained">

            <Button onClick={() => dispatch(getAgencyactions({ allitems: allitems }))}>
              Get Data
            </Button>
            {(role == "agencyadmin" || role == "admin" || role == "master") && (
              <>
                <Button onClick={() => {
                  console.log(currentAction);
                  dispatch(saveAgencyactions())
                }}>
                  Save Data
                </Button>
                <Button
                  onClick={() => {
                    dispatch(duplicateAgencyaction({ id: currentId })).then(() =>
                      dispatch(getAgencyactions({ allitems: "N" })));
                  }}

                >
                  Duplicate Data
                </Button></>)}
            <>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={() => {
                  const fileInput = document.querySelector(
                    "#raised-button-file"
                  );
                  const formData = new FormData();

                  formData.append("file", fileInput.files[0]);

                  const options = {
                    method: "POST",
                    body: formData,
                  };

                  fetch(
                    getHost() + "upload/" + agencyid + "/stickers/picture",
                    options
                  )
                    .then((response) => {
                      return response.json();
                    })
                    .then((data) => {

                      dispatch(getPictures({ folderid: "stickers", dropdown: "1" }));
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }}
              />

              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  Upload Sticker
                </Button>
              </label>
            </>
          </ButtonGroup>

        </div>
      </div>
      <Paper
        style={{ marginBottom: 8 }}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        <TableContainer sx={{ maxHeight: moreRows ? 1000 : 200 }}>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "15%" }}>Code
                  <TextField
                    variant="standard"
                    onChange={(e) => setCodeFilter(e.target.value)}
                  ></TextField>
                </TableCell>
                <TableCell style={{ width: "10%" }}>Label
                  <TextField
                    variant="standard"
                    onChange={(e) => setLabelFilter(e.target.value)}
                  ></TextField>
                </TableCell>

                <TableCell style={{ width: "10%" }}>Group
                  <TextField
                    variant="standard"
                    onChange={(e) => setGroupFilter(e.target.value)}
                  ></TextField>
                </TableCell>
                <TableCell style={{ width: "20%" }}>WebText
                  <TextField
                    variant="standard"
                    onChange={(e) => setWebtextFilter(e.target.value)}
                  ></TextField>
                </TableCell>
                <TableCell style={{ width: "10%" }}>BookingPeriodTyp</TableCell>
                <TableCell style={{ width: "10%" }}
                  onClick={() =>
                    setActionSort({
                      column: "typlabel",
                      direction: actionsort.direction
                        ? actionsort.direction * -1
                        : 1,
                    })
                  }>
                  Typ

                </TableCell>
                <TableCell style={{ width: "10%" }}
                  onClick={() =>
                    setActionSort({
                      column: "onpagelabel",
                      direction: actionsort.direction
                        ? actionsort.direction * -1
                        : 1,
                    })
                  }>
                  PlaceOnPage</TableCell>
                <TableCell style={{ width: "10%" }}>Stickername</TableCell>
                <TableCell style={{ width: "5%" }}>Rank
                  <span onClick={() => {
                    setMorerows(!moreRows)
                  }}>
                    {moreRows ? " [-]" : " [+]"}
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(agencyactions) &&
                filtered
                  /*.sort((a, b) => {
                    //if (!actionsort.column) return 0;
                    if (actionsort.column === "onpagelabel") {
                      return actionsort.direction == 1
                        ? a[actionsort.column] > b[actionsort.column]
                        : a[actionsort.column] < b[actionsort.column];
                    }
                  })*/
                  .map((e, idx) => {
                    odd = !odd;
                    return (
                      <TableRow
                        onClick={() => {
                          setCurrentId(e.id);
                        }}

                        key={idx}
                        style={{
                          backgroundColor:
                            currentId === e.id
                              ? "#029aee"
                              : odd
                                ? "#f0f0f0"
                                : undefined,
                        }}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell style={{ width: "15%" }}>
                          {e.code ? e.code : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {e.label ? e.label : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {e.actiongroup ? e.actiongroup : ""}
                        </TableCell>
                        <TableCell style={{ width: "20%" }}>
                          {e.properties
                            ? e.properties.text
                              ? e.properties.text
                              : ""
                            : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {e.bookingperiod ? e.bookingperiod : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {e.typlabel ? e.typlabel : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {e.onpagelabel ? e.onpagelabel : ""}
                        </TableCell>
                        <TableCell style={{ width: "10%" }}>
                          {e.stickername ? e.stickername : ""}
                        </TableCell>
                        <TableCell style={{ width: "5%" }}>
                          {e.rank ? (e.rank ? e.rank : "") : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {currentAction.id && (
        <AgencyActionsGrid
          actions={agencyactions}
          currentAction={currentAction}
          promoclass={promoclass}
          promotyp={promotyp}
          onpage={onpage}
          hotellists={hotellists}
          setActions={(obj) => dispatch(setAgencyactions(obj))}
        />
      )}
    </>
  );
}
