import * as React from 'react';
import { FormattedDate } from 'react-intl';
import { Autocomplete, Button, ButtonGroup, Paper, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { getMouseovertexts, saveMouseovertexts } from '../features/dataApiCalls';
import { setMouseovertexts } from '../features/dataSlice';


export default function MouseoverPage(props) {


    const dispatch = useDispatch();
    const mouseovertexts = useSelector((state) => state.data.mouseovertexts);
    const agencyid = useSelector((state) => state.system.agencyid);
    const [editField, setEditField] = React.useState("");
    const role = useSelector((state) => state.system.role);

    return (
        < >

            <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}></div>
                <div> <ButtonGroup style={{ marginBottom: 16 }} variant="contained">
                    <Button onClick={() => dispatch(getMouseovertexts())}>
                        Get Data
                    </Button>
                    {(role == "admin" || role == "master") && (
                        <>
                            <Button onClick={() => dispatch(saveMouseovertexts())}>
                                Save all
                            </Button></>)}       </ButtonGroup></div></div>
            <DataTable mouseovertexts={mouseovertexts} setEditField={setEditField} />


        </ >
    );
}




const DataTable = (props) => {
    const { mouseovertexts, setEditField } = props;
    const dispatch = useDispatch();

    let odd = true;
    return (<Paper sx={{ width: '100%', overflow: 'hidden' }}>

        <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
            <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: "35%" }}>tag</TableCell>
                        <TableCell style={{ width: "45%" }}>infotext</TableCell>
                        <TableCell style={{ width: "10%" }}>language</TableCell>
                        <TableCell style={{ width: "10%" }}>status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(mouseovertexts) &&
                        mouseovertexts.map((obj, idx) => {
                            odd = !odd;
                            return (
                                <TableRow
                                    key={idx}
                                    style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={{ width: "35%" }}>
                                        {obj.tag ? obj.tag : ""}
                                    </TableCell>
                                    <TableCell style={{ width: "45%" }}>
                                        <TextField variant="standard"
                                            multiline
                                            fullWidth
                                            rows={3}
                                            value={obj.infotext ? obj.infotext : ""}
                                            onChange={(evt) => {
                                                let tmp = mouseovertexts.map((o, i) => {
                                                    if (o.id === obj.id) {
                                                        return {
                                                            ...o,
                                                            infotext: evt.target.value,
                                                            dirty: true
                                                        };
                                                    } else {
                                                        return o;
                                                    }
                                                });

                                                dispatch(setMouseovertexts(tmp));
                                            }}
                                        />

                                    </TableCell>
                                    <TableCell style={{ width: "10%" }}>
                                        {obj.language ? obj.language : ""}
                                    </TableCell>
                                    <TableCell style={{ width: "10%" }}>
                                    <TextField variant="standard"
                                            fullWidth
                                            value={obj.status ? obj.status : ""}
                                            onChange={(evt) => {
                                                let tmp = mouseovertexts.map((o, i) => {
                                                    if (o.id === obj.id) {
                                                        return {
                                                            ...o,
                                                            status: evt.target.value,
                                                            dirty: true
                                                        };
                                                    } else {
                                                        return o;
                                                    }
                                                });

                                                dispatch(setMouseovertexts(tmp));
                                            }}
                                        />

                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Paper >
    )
}