import * as React from "react";

import { FormattedDate } from "react-intl";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getHost } from "../utils";
import { getCityInfo, getGiatainfos, saveCityProperties, saveHotelProperties } from "../features/dataApiCalls";
import { setGiatainfos } from "../features/dataSlice";

export default function GiataDataPage(props) {
  const dispatch = useDispatch();
  const giatainfos = useSelector((state) => state.data.giatainfos);
  const [dialog, setDialog] = React.useState({});
  const [editField, setEditField] = React.useState("");
  const role = useSelector((state) => state.system.role);
  const [hotels, setHotels] = React.useState(false);
  let dialogObject = {};

  if (dialog.keyvalue) {
    dialogObject = giatainfos.find((o) => o[dialog.key] === dialog.keyvalue);
  }
  return (
    <>
      {dialog.keyvalue && (
        <EditDialog
          setDialog={setDialog}
          dialog={dialog}
          giatainfos={giatainfos}
          dialogObject={dialogObject}
          editField={editField}
          role={role}
        />
      )}
      <Search setHotels={setHotels} hotels={hotels}/>
      <DataTable
        setDialog={setDialog}
        dialog={dialog}
        giatainfos={giatainfos}
        setEditField={setEditField}
      />
    </>
  );
}

const Search = (props) => {
  const { setHotels,hotels } = props;
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState("");
  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault();
        dispatch(getGiatainfos({ search: search, hotels:hotels}));
      }}
    >
      <div style={{ display: "flex" }}>
      
        <div style={{ flexGrow: 1 }}>
          <TextField
            variant="standard"
            label={"search"}
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div><FormControlLabel control={<Checkbox checked={hotels} onChange={() => setHotels(!hotels)} />} label="Hotels" /></div>
        <div style={{ marginLeft: 16 }}>
          <Button
            style={{ marginBottom: 16 }}
            type="submit"
            variant="contained"
          >
            Get Data
          </Button>
        </div>
      </div>
    </form>
  );
};

const EditDialog = (props) => {
  const { setDialog, dialog, giatainfos, dialogObject, editField, role } = props;
  const dispatch = useDispatch();
  const [dialogEditField, setDialogEditField] = React.useState(editField);

  React.useEffect(() => {
    setDialogEditField(editField);
  }, [editField]);

  return (
    <>
      {dialog.keyvalue && (
        <Dialog
          fullWidth={true}
          open={dialog.keyvalue ? true : false}
          onClose={() => setDialog({})}
        >
          <DialogTitle>{dialog.field}</DialogTitle>
          <DialogContent>
            <TextField
              multiline
              fullWidth
              rows={30}
              disabled={
                dialog.field === "hotelattributes" ||
                dialog.field === "hotelratings"
              }
              value={
                dialog.field === "cityproperties" ||
                  dialog.field === "hotelproperties" ||
                  dialog.field === "hotelattributes" ||
                  dialog.field === "hotelagencyproperties" ||
                  dialog.field === "hotelratings"
                  ? dialogEditField
                  : dialogObject[dialog.field]
              }
              onChange={(evt) => {
                if (
                  dialog.field === "hotelagencyproperties" ||
                  dialog.field === "cityproperties" ||
                  dialog.field === "hotelproperties"
                ) {
                  setDialogEditField(evt.target.value);
                  return;
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            {(dialog.field === "cityproperties" ||
              dialog.field === "hotelagencyproperties") /* ||
                dialog.field === "hotelproperties" ||
                dialog.field === "hotelattributes" ||
                dialog.field === "hotelratings"*/ && (
                <Button
                  onClick={() => {
                    try {
                      let jsontext = JSON.parse(dialogEditField);
                      setDialog({});

                      if (dialog.field === "cityproperties") {
                        dispatch(
                          saveCityProperties({
                            data: jsontext,
                            id: dialog.keyvalue,
                          })
                        );
                        dispatch(
                          setGiatainfos(
                            [...giatainfos].map((o) =>
                              o[dialog.key] === dialog.keyvalue
                                ? { ...o, cityproperties: jsontext }
                                : o
                            )
                          )
                        );
                      }
                      if (dialog.field === "hotelproperties") {
                        dispatch(
                          saveHotelProperties({
                            properties: jsontext,
                            keyproperty: dialog.field,
                            id: dialog.keyvalue,
                            giataid: giatainfos.find(e => e[dialog.key] === dialog.keyvalue).giataid,
                            hotelgroup: giatainfos.find(e => e[dialog.key] === dialog.keyvalue).hotelgroup
                          })
                        );
                        dispatch(
                          setGiatainfos(
                            [...giatainfos].map((o) =>
                              o[dialog.key] === dialog.keyvalue
                                ? { ...o, [dialog.field]: jsontext }
                                : o
                            )
                          )
                        );
                      }
                      if (dialog.field === "hotelagencyproperties") {
                        dispatch(
                          saveHotelProperties({
                            properties: jsontext,
                            keyproperty: dialog.field,
                            id: dialog.keyvalue,
                            giataid: giatainfos.find(e => e[dialog.key] === dialog.keyvalue).giataid,
                            hotelgroup: giatainfos.find(e => e[dialog.key] === dialog.keyvalue).hotelgroup
                          }));
                        dispatch(
                          setGiatainfos(
                            [...giatainfos].map((o) =>
                              o[dialog.key] === dialog.keyvalue
                                ? { ...o, [dialog.field]: jsontext }
                                : o
                            )
                          )
                        );
                      }
                    } catch (e) { }
                  }}
                >
                  save
                </Button>
              )}
            <Button
              onClick={() => {
                setDialog({});
              }}
            >
              close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const DataTable = (props) => {
  const { setDialog, dialog, giatainfos, setEditField } = props;
  const dispatch = useDispatch();

  let odd = true;
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
        <Table
          size="small"
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "20%" }}>fullname</TableCell>
              <TableCell style={{ width: "10%" }}>groupID</TableCell>
              <TableCell style={{ width: "10%" }}>regionID</TableCell>
              <TableCell style={{ width: "10%" }}>cityID</TableCell>
              <TableCell style={{ width: "5%" }}>giataID</TableCell>
              <TableCell style={{ width: "5%" }}>hotelGroup</TableCell>
              <TableCell style={{ width: "10%" }}>cityinfo</TableCell>
              <TableCell style={{ width: "10%" }}>agency hotelproperties</TableCell>
              <TableCell style={{ width: "10%" }}>hotel properties</TableCell>
              <TableCell style={{ width: "10%" }}>hotel attributes</TableCell>
              <TableCell style={{ width: "10%" }}>hotel ratings</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(giatainfos) &&
              giatainfos.map((e, idx) => {
                odd = !odd;
                return (
                  <TableRow
                    key={idx}
                    style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ width: "20%" }}>
                      {e.fullname ? e.fullname : ""}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {e.groupid ? e.groupid : ""}
                    </TableCell>

                    <TableCell style={{ width: "10%" }}>
                      {e.regionid ? e.regionid : ""}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      <Button
                        onClick={() => {
                          dispatch(
                            getCityInfo({
                              cityid: e.cityid,
                              giataid: e.giataid,
                              context: "werbetext",
                            })
                          );
                        }}
                      >
                        {e.cityid ? e.cityid : ""}
                      </Button>
                    </TableCell>
                    <TableCell style={{ width: "5%" }}>
                      {e.giataid ? e.giataid : ""}
                      {e.giataid && (
                        <Button
                          style={{}}
                          onClick={() => {
                            window.open(
                              getHost() +
                              "gethotelpicturesfromgiata?id=" +
                              e.giataid,
                              "_BLANK"
                            );
                          }}
                        >
                          pics
                        </Button>
                      )}
                    </TableCell>
                    <TableCell style={{ width: "5%" }}>
                      <TextField variant="standard"
                        value={e.hotelgroup ? e.hotelgroup : ""}
                        onChange={(evt) => {
                          let tmp = giatainfos.map((o, i) => {
                            if (o.giataid === e.giataid) {
                              return {
                                ...o,
                                hotelgroup: evt.target.value,
                                dirty: true
                              };
                            } else {
                              return o;
                            }
                          });
                          dispatch(setGiatainfos(tmp));
                        }}
                        onBlur={()=>dispatch(saveHotelProperties({
                          properties: e.hotelproperties,
                          keyproperty: "hotelproperties",
                          giataid: e.giataid,
                          id: e.id,
                          hotelgroup: e.hotelgroup
                        }))
                        }
                      />

                    </TableCell>

                    <TableCell style={{ width: "10%" }}>
                      <Button
                        style={{ color: e.cityproperties ? "blue" : undefined }}
                        onClick={() => {
                          setDialog({
                            keyvalue: e.id,
                            key: "id",
                            field: "cityproperties",
                          });
                          setEditField(
                            JSON.stringify(e.cityproperties, null, 4)
                          );
                        }}
                      >
                        edit
                      </Button>
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {e.giataid && (
                        <Button
                          style={{
                            color: e.hotelagencyproperties ? "blue" : undefined,
                          }}
                          onClick={() => {
                            setDialog({
                              keyvalue: e.giataid,
                              key: "giataid",
                              field: "hotelagencyproperties",
                            });

                            setEditField(
                              JSON.stringify(e.hotelagencyproperties, null, 4)
                            );
                          }}
                        >
                          edit
                        </Button>
                      )}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {e.giataid && (
                        <Button
                          style={{
                            color: e.hotelproperties ? "blue" : undefined,
                          }}
                          onClick={() => {
                            setDialog({
                              keyvalue: e.giataid,
                              key: "giataid",
                              field: "hotelproperties",
                            });
                            setEditField(
                              JSON.stringify(e.hotelproperties, null, 4)
                            );
                          }}
                        >
                          show
                        </Button>
                      )}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {e.giataid && (
                        <Button
                          style={{
                            color: e.hotelattributes ? "blue" : undefined,
                          }}
                          onClick={() => {
                            setDialog({
                              keyvalue: e.giataid,
                              key: "giataid",
                              field: "hotelattributes",
                            });
                            setEditField(
                              JSON.stringify(e.hotelattributes, null, 4)
                            );
                          }}
                        >
                          show
                        </Button>
                      )}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {e.giataid && (
                        <Button
                          style={{ color: e.hotelratings ? "blue" : undefined }}
                          onClick={() => {
                            setDialog({
                              keyvalue: e.giataid,
                              key: "giataid",
                              field: "hotelratings",
                            });
                            setEditField(
                              JSON.stringify(e.hotelratings, null, 4)
                            );
                          }}
                        >
                          show
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
