import * as React from 'react';
import { FormattedDate } from 'react-intl';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, MenuItem, DialogTitle, Paper, Popper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, FormControlLabel, Checkbox } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { getTopSellerInfos, saveTopsellers, duplicateTopseller, newTopseller, deleteTopseller, scanOffers } from '../features/dataApiCalls';
import { setTopsellers } from '../features/dataSlice';
import { dateToInputString } from "../utils";

export default function TopsellerPage(props) {


    const dispatch = useDispatch();
    const topsellers = useSelector((state) => state.data.topsellers);
    const pictures = useSelector((state) => state.data.pictures);
    const [currentId, setCurrentId] = React.useState("");
    const [dialog, setDialog] = React.useState({});
    const [editField, setEditField] = React.useState("");
    const role = useSelector((state) => state.system.role);
    const [search, setSearch] = React.useState("");
    const [qrlink, setQrlink] = React.useState("");
    console.log(pictures);
    let dialogObject = {};

    if (dialog.id) {
        dialogObject = topsellers.find((o) => o[dialog.key] === dialog.id);
    }
    return (
        < >
            {dialog.id && <EditDialog setDialog={setDialog} dialog={dialog} topsellers={topsellers} dialogObject={dialogObject}
                editField={editField} />}
            <Search topsellers={topsellers} role={role} currentId={currentId} qrlink={qrlink} search={search} setSearch={setSearch}/>
            <DataTable setDialog={setDialog} pictures={pictures} dialog={dialog} topsellers={topsellers} 
            setEditField={setEditField} role={role} currentId={currentId} setCurrentId={setCurrentId} setSearch={setSearch} 
            setQrlink={setQrlink}/>


        </ >
    );
}

const Search = (props) => {
    const { search, setSearch, qrlink , role, currentId} = props;
    const dispatch = useDispatch();
    const [allitems, setAllitems] = React.useState("0");

    return (<div style={{ display: "flex" }}>
        <div style={{ display: "flex",flexGrow: 1 }}>
            <TextField
                variant="standard"
                label={"shared search link"}
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                helperText= {"qrcode:  "+qrlink}
            />
            <Button style={{ marginBottom: 16, width:200 , marginLeft:20}} variant="contained"
                onClick={() => {
                    dispatch(newTopseller({ link: search })).then(() => dispatch(getTopSellerInfos({ allitems: "N" })));
                }}
            >
                Create Deeplink
            </Button>
        </div>
        <div style={{ flexGrow: 1 }}><form onSubmit={(evt) => {
            evt.preventDefault();
            dispatch(getTopSellerInfos({ allitems }));
        }
        } ><div style={{ display: "flex", flexGrow: 1 }}>
                <div style={{ flexGrow: 1 }}>
                    &nbsp;</div><div style={{ marginLeft: 16 }}>
                    <FormControlLabel control={<Checkbox checked={allitems === "1"} onChange={() => setAllitems(allitems == "0" ? "1" : "0")} />} label="all items" />
                    <Button style={{ marginBottom: 16 }} type="submit" variant="contained">Get Data</Button></div> </div></form ></div>
        {(role == "agencyadmin" || role == "admin" || role == "master") && (
            <><div style={{ marginLeft: 16 }}>
                <Button style={{ marginBottom: 16 }} onClick={() => dispatch(saveTopsellers()).then(() =>
                    dispatch(getTopSellerInfos({ allitems })))} variant="contained">Save all</Button>
            </div>
                <div style={{ marginLeft: 16 }}>
                    <Button style={{ marginBottom: 16 }} variant="contained"
                        onClick={() => {
                            dispatch(duplicateTopseller({ id: currentId })).then(() =>
                                dispatch(getTopSellerInfos({ allitems: "N" })));
                        }}

                    >
                        Duplicate Data
                    </Button>

                    <Button onClick={() => dispatch(scanOffers())}>
              ScanOffers
            </Button>
                </div ></>)}
    </div >)
}

const EditDialog = (props) => {
    const { setDialog, dialog, topsellers, dialogObject, editField } = props;
    const dispatch = useDispatch();
    const [dialogEditField, setDialogEditField] = React.useState(editField);

    React.useEffect(() => {
        setDialogEditField(editField);
    }, [editField])

    return (<>{dialog.id && <Dialog
        fullWidth={true}
        open={dialog.id ? true : false}
        onClose={() => setDialog({})}
    >
        <DialogTitle>{dialog.field}</DialogTitle>
        <DialogContent>
            <TextField
                multiline
                fullWidth
                rows={30}
                value={

                    dialog.field === "memoproperties" ||
                        dialog.field === "properties" ||
                        dialog.field === "description"
                        ? dialogEditField
                        : dialogObject[dialog.field]

                }
                onChange={(evt) => {
                    if (
                        dialog.field === "memoproperties" ||
                        dialog.field === "properties" ||
                        dialog.field === "description"

                    ) {
                        setDialogEditField(evt.target.value);
                        return;
                    }
                }}
            />
        </DialogContent>
        <DialogActions>
            {(dialog.field === "properties" || dialog.field === "description" ||
                dialog.field === "memoproperties")
                && (
                    <Button
                        onClick={() => {
                            try {
                                let jsontext = dialogEditField;
                                if (dialog.field === 'memoproperties' || dialog.field === "properties")
                                    jsontext = JSON.parse(jsontext);
                                setDialog({});

                                if (dialog.field === 'properties' || dialog.field === "description") {

                                    dispatch(setTopsellers([...topsellers].map(
                                        (o) => o[dialog.key] === dialog.id ? { ...o, [dialog.field]: jsontext, dirty: true } : o
                                    )))
                                }
                                if (dialog.field === 'memoproperties') {
                                    //saveMemoproperties
                                    dispatch(setTopsellers([...topsellers].map(
                                        (o) => o[dialog.key] === dialog.id ? { ...o, [dialog.field]: jsontext, dirty: true } : o
                                    )))
                                }

                            } catch (e) { }
                        }}
                    >
                        save
                    </Button>
                )}
            <Button
                onClick={() => {
                    setDialog({});
                }}
            >
                close
            </Button>
        </DialogActions>
    </Dialog>}</>)
}

const DataTable = (props) => {
    const { setDialog, dialog, topsellers, setEditField, pictures, currentId, setCurrentId,setSearch, setQrlink } = props;
    const dispatch = useDispatch();
    const role = useSelector((state) => state.system.role);
    let typ = ["package", "accommodation", "cruise", "fewo"];
    let odd = true;
    return (<Paper sx={{ width: '100%', overflow: 'hidden' }}>

        <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
            <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: "15%" }}>location</TableCell>
                        <TableCell style={{ width: "5%" }}>views</TableCell>
                        <TableCell style={{ width: "5%" }}>from</TableCell>
                        <TableCell style={{ width: "5%" }}>until</TableCell>
                        <TableCell style={{ width: "5%" }}>typ</TableCell>
                        <TableCell style={{ width: "5%" }}>rank</TableCell>
                        <TableCell style={{ width: "20%" }}>headline</TableCell>
                        <TableCell style={{ width: "20%" }}>pictureurl</TableCell>
                        <TableCell style={{ width: "5%" }}>description</TableCell>
                        <TableCell style={{ width: "5%" }}>properties</TableCell>
                        <TableCell style={{ width: "15%" }}>link</TableCell>
                        <TableCell style={{ width: "5%" }}>link</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(topsellers) &&
                        topsellers.map((obj, idx) => {
                            odd = !odd;
                            return (
                                <TableRow
                                    onClick={() => {
                                        console.log(obj.properties);
                                        setCurrentId(obj.id);
                                        setSearch(obj.properties.link);
                                        setQrlink(obj.properties.qrcode);
                                    }}
                                    key={idx}
                                    style={{
                                        backgroundColor:
                                            currentId === obj.id
                                                ? "#029aee"
                                                : odd
                                                    ? "#f0f0f0"
                                                    : undefined,
                                    }}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={{ width: "15%" }}>

                                        {obj.fullname ? <><div>{obj.fullname + " :"}</div>
                                            <div>{obj.ids}</div></> : ""}
                                    </TableCell>
                                    <TableCell style={{ width: "5%" }}>
                                        {obj.views ? obj.views : ""}
                                    </TableCell>
                                    <TableCell style={{ width: "5%" }}>
                                        <TextField
                                            fullWidth
                                            type="date"
                                            value={
                                                obj.valid_from
                                                    ? dateToInputString(new Date(obj.valid_from))
                                                    : ""
                                            }
                                            onKeyDown={(e) => e.preventDefault()}
                                            onChange={(evt) => {
                                                try {
                                                    let tmp = new Date(evt.target.value);
                                                    let act = topsellers.map((ele) =>
                                                        ele.id === obj.id
                                                            ? {
                                                                ...ele,
                                                                valid_from: tmp,
                                                                dirty: true,
                                                            }
                                                            : ele
                                                    );
                                                    dispatch(setTopsellers(act));
                                                } catch (e) { }
                                            }}

                                        />
                                    </TableCell>
                                    <TableCell style={{ width: "5%" }}>
                                        <TextField
                                            fullWidth
                                            type="date"
                                            value={
                                                obj.valid_until
                                                    ? dateToInputString(new Date(obj.valid_until))
                                                    : ""
                                            }
                                            onKeyDown={(e) => e.preventDefault()}
                                            onChange={(evt) => {
                                                try {
                                                    let tmp = new Date(evt.target.value);
                                                    let act = topsellers.map((ele) =>
                                                        ele.id === obj.id
                                                            ? {
                                                                ...ele,
                                                                valid_until: tmp,
                                                                dirty: true,
                                                            }
                                                            : ele
                                                    );
                                                    dispatch(setTopsellers(act));
                                                } catch (e) { }
                                            }}


                                        />
                                    </TableCell>
                                    <TableCell style={{ width: "5%" }}>
                                        <Select
                                            fullWidth
                                            labelId="select-typesl"
                                            id="select-types"
                                            value={
                                                obj.typ
                                                    ? obj.typ
                                                    : ""
                                            }
                                            variant="standard"

                                            onChange={(evt) => {
                                                let tmp = topsellers.map((o, i) => {
                                                    if (o.id === obj.id) {
                                                        return {
                                                            ...o,
                                                            typ: evt.target.value,
                                                            dirty: true,
                                                        };
                                                    } else {
                                                        return o;
                                                    }
                                                });
                                                dispatch(setTopsellers(tmp));
                                            }}
                                        >
                                            {typ.map((o) => (
                                                <MenuItem key={o} value={o}>
                                                    {o}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>
                                    <TableCell style={{ width: "5%" }}>
                                        <TextField variant="standard"
                                            value={obj.rank ? obj.rank : ""}
                                            onChange={(evt) => {
                                                let tmp = topsellers.map((o, i) => {
                                                    if (o.id === obj.id) {
                                                        return {
                                                            ...o,
                                                            rank: evt.target.value,
                                                            dirty: true,
                                                        };
                                                    } else {
                                                        return o;
                                                    }
                                                });
                                                dispatch(setTopsellers(tmp));
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: "20%" }}>

                                        <TextField variant="standard"
                                            value={obj.properties.headline ? obj.properties.headline : ""}

                                            onChange={(evt) => {
                                                let tmp = topsellers.map((ele) =>
                                                    ele.id === obj.id
                                                        ? {
                                                            ...ele,
                                                            properties: {
                                                                ...ele.properties,
                                                                headline: evt.target.value,
                                                            },
                                                            dirty: true,
                                                        }
                                                        : ele
                                                );
                                                dispatch(setTopsellers(tmp));
                                            }}

                                        />
                                    </TableCell>
                                    <TableCell style={{ width: "20%" }}>
                                        <Select
                                            fullWidth
                                            labelId="select-typesl"
                                            id="select-types"
                                            value={
                                                obj.pictureurl ? obj.pictureurl : ""
                                            }
                                            variant="standard"

                                            onChange={(evt) => {
                                                let tmp = topsellers.map((o, i) => {
                                                    if (o.id === obj.id) {
                                                        return {
                                                            ...o,
                                                            pictureurl: evt.target.value,
                                                            dirty: true
                                                        };
                                                    } else {
                                                        return o;
                                                    }
                                                });
                                                dispatch(setTopsellers(tmp));
                                            }}
                                        >
                                            {(Array.isArray(pictures[obj.folderid]) ? pictures[obj.folderid] : []).map((o) => (
                                                <MenuItem key={o.url} value={o.url}>
                                                    {o.filename}
                                                </MenuItem>
                                            ))}
                                        </Select>


                                    </TableCell>
                                    <TableCell style={{ width: "5%" }}>
                                        <Button
                                            onClick={() => {
                                                setDialog({
                                                    id: obj.id,
                                                    key: "id",
                                                    field: "description",
                                                });
                                                setEditField(obj.description);

                                            }
                                            }
                                        >
                                            edit
                                        </Button>
                                    </TableCell>
                                    <TableCell style={{ width: "5%" }}>
                                        <Button
                                            onClick={() => {
                                                setDialog({
                                                    id: obj.id,
                                                    key: "id",
                                                    field: "properties",

                                                });
                                                setEditField(
                                                    JSON.stringify(obj.properties, null, 4)
                                                );

                                            }}
                                        >
                                            edit
                                        </Button>
                                    </TableCell>
                                    <TableCell style={{ width: "20%" }}>
                                        <Button
                                            onClick={() => {
                                                setDialog({
                                                    id: obj.linkid,
                                                    field: "memoproperties",
                                                    key: "linkid",
                                                });
                                                setEditField(
                                                    JSON.stringify(obj.memoproperties, null, 4)
                                                );

                                            }}
                                        >
                                            edit memo
                                        </Button>
                                        <TextField variant="standard"
                                            fullWidth
                                            value={obj.linkid ? obj.linkid : ""}
                                            onChange={(evt) => {
                                                let tmp = topsellers.map((o, i) => {
                                                    if (o.id === obj.id) {
                                                        return {
                                                            ...o,
                                                            linkid: evt.target.value,
                                                            dirty: true
                                                        };
                                                    } else {
                                                        return o;
                                                    }
                                                });
                                                dispatch(setTopsellers(tmp));
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: "5%" }}>
                                        <Button
                                            onClick={() => {
                                                dispatch(deleteTopseller({ id: obj.id })).then(() => dispatch(getTopSellerInfos({ allitems: "1" })));

                                            }}
                                        >
                                            del
                                        </Button>

                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Paper >
    )
}