import * as React from "react";
import Button from "@mui/material/Button";
import { FormattedDate } from "react-intl";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TouroperatorActionsGrid from "./TouroperatorActionsGrid";
import { getHost } from "../utils";
import {
  duplicateTouroperatoraction,
  getProperty,
  getHotellists,
  getTouroperatoractions,
  savetouroperatoractions,
  getPictures,
} from "../features/dataApiCalls";
import { setTouroperatoractions } from "../features/dataSlice";

export default function TouroperatorActionsPage(props) {
  const dispatch = useDispatch();
  //const [search, setSearch] = React.useState("");
  const touroperatoractions = useSelector(
    (state) => state.data.touroperatoractions
  );
  const [currentTouroperatorId, setCurrentTouroperatorId] = React.useState("");

  const currentAction = touroperatoractions.find(
    (e) => e.id === currentTouroperatorId
  )
    ? touroperatoractions.find((e) => e.id === currentTouroperatorId)
    : {};

  const [touroperatorfilter, setTouroperatorFilter] = React.useState("");
  const [touroperatornamefilter, setTouroperatornameFilter] = React.useState("");
  const [touroperatorcouponfilter, setTouroperatorcouponFilter] = React.useState("");
  const [labelfilter, setLabelFilter] = React.useState("");
  const [typfilter, setTypFilter] = React.useState("");
  const [docfilter, setDocFilter] = React.useState("");
  const [allitems, setAllitems] = React.useState("0");
  const [dialog, setDialog] = React.useState({});
  const [editField, setEditField] = React.useState("");
  const role = useSelector((state) => state.system.role);
  const hotellists = useSelector((state) => state.data.hotellists);
  const agencyid = useSelector((state) => state.system.agencyid);
  const [moreRows, setMorerows] = React.useState(false);
  let dialogObject = {};

  if (dialog.number) {
    dialogObject = touroperatoractions.find(
      (o) => o[dialog.key] === dialog.number
    );
  }

  const properties = useSelector((state) => state.data.properties);

  //TODO: HIer ist noch was zu tun
  React.useEffect(() => {
    dispatch(getProperty({ tag: "touroperatoractiontyp" }));
    dispatch(getProperty({ tag: "touroperatorlabel" }));
    dispatch(getProperty({ tag: "onpage" }));
    dispatch(getHotellists("00000000-0000-0000-0000-000000000000"));
    dispatch(getTouroperatoractions({ allitems: "N" }))
    //dispatch(getPictures({ folderid: "stickers", dropdown: "1" }));
  }, []);

  let onpage = properties.onpage;
  if (!Array.isArray(onpage)) onpage = [];
  let touroperatoractiontyp = properties.touroperatoractiontyp;
  let touroperatorcoupon = properties.touroperatorcoupon;

  if (!Array.isArray(touroperatoractiontyp)) touroperatoractiontyp = [];
  if (!Array.isArray(touroperatorcoupon)) touroperatorcoupon = [];

  let filtered = touroperatoractions.filter(
    (e) => e.touroperator.toLowerCase().indexOf(touroperatorfilter.toLowerCase()) > -1
  );
  filtered = filtered.filter(
    (e) => e.typlabel.toLowerCase().indexOf(typfilter.toLowerCase()) > -1
  );

  if (touroperatorcouponfilter)
    filtered = filtered.filter(
      (e) => e.touroperatorcoupon && e.touroperatorcoupon.toLowerCase().indexOf(touroperatorcouponfilter.toLowerCase()) > -1
    );
  if (touroperatornamefilter)
    filtered = filtered.filter(
      (e) => e.name && e.name.toLowerCase().indexOf(touroperatornamefilter.toLowerCase()) > -1
    );
  if (labelfilter)
    filtered = filtered.filter(
      (e) => e.label && e.label.toLowerCase().indexOf(labelfilter.toLowerCase()) > -1
    );
  if (docfilter)
    filtered = filtered.filter(
      (e) => e.doc && e.doc.toLowerCase().indexOf(docfilter.toLowerCase()) > -1
    );
  let odd = true;
  return (
    <>

      {dialog.number && (
        <EditDialog
          setDialog={setDialog}
          dialog={dialog}
          touroperatoractions={touroperatoractions}
          dialogObject={dialogObject}
          editField={editField}
          role={role}
        />
      )}

      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <div><FormControlLabel control={<Checkbox checked={allitems === "1"} onChange={() => setAllitems(allitems == "0" ? "1" : "0")} />} label="all items" /> <ButtonGroup style={{ marginBottom: 16 }} variant="contained">
          <Button onClick={() => dispatch(getTouroperatoractions({ allitems: allitems }))}>
            Get Data
          </Button>
          {(role == "admin" || role == "master") && (
            <>
              <Button onClick={() => dispatch(savetouroperatoractions())}>
                Save Data
              </Button>
              <Button
                onClick={() => {
                  dispatch(duplicateTouroperatoraction({ id: currentTouroperatorId })).then(() =>
                    dispatch(getTouroperatoractions({ allitems: "N" })));
                }}

              >
                Duplicate Data
              </Button></>)}
          <>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={() => {
                const fileInput = document.querySelector(
                  "#raised-button-file"
                );
                const formData = new FormData();

                formData.append("file", fileInput.files[0]);

                const options = {
                  method: "POST",
                  body: formData,
                };

                fetch(
                  getHost() + "upload/" + agencyid + "/stickers/picture",
                  options
                )
                  .then((response) => {
                    return response.json();
                  })
                  .then((data) => {

                    dispatch(getPictures({ folderid: "stickers", dropdown: "1"}));
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }}
            />

            <label htmlFor="raised-button-file">
              <Button variant="contained" component="span">
                Upload Sticker
              </Button>
            </label>
          </>
        </ButtonGroup></div></div>

      <Paper
        style={{ marginBottom: 8 }}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        <TableContainer sx={{ maxHeight: moreRows ? 800 : 200 }}>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "3%" }}>
                  Operator
                  <TextField
                    variant="standard"
                    onChange={(e) => setTouroperatorFilter(e.target.value)}
                  ></TextField>
                </TableCell>
                <TableCell style={{ width: "10%" }}>
                  Operatorname
                  <TextField
                    variant="standard"
                    onChange={(e) => setTouroperatornameFilter(e.target.value)}
                  ></TextField>
                </TableCell>
                <TableCell style={{ width: "7%" }}>
                  MarketingBoxLabel
                  <TextField
                    variant="standard"
                    onChange={(e) => setLabelFilter(e.target.value)}
                  ></TextField>
                </TableCell>
                <TableCell style={{ width: "8%" }}>
                  TouroperatorCoupon<br />
                  <TextField
                    variant="standard"
                    onChange={(e) => setTouroperatorcouponFilter(e.target.value)}
                  ></TextField>
                </TableCell>
                <TableCell style={{ width: "7%" }}>group</TableCell>
                <TableCell style={{ width: "12%" }}>booking</TableCell>
                <TableCell style={{ width: "13%" }}>using</TableCell>
                <TableCell style={{ width: "7%" }}>
                  Typ
                  <TextField
                    variant="standard"
                    onChange={(e) => setTypFilter(e.target.value)}
                  ></TextField>
                </TableCell>
                <TableCell style={{ width: "7%" }}>Plazierung</TableCell>
                <TableCell style={{ width: "6%" }}>Sticker</TableCell>
                <TableCell style={{ width: "5%" }}>Status</TableCell>
                <TableCell style={{ width: "5%" }}>checked</TableCell>
                <TableCell style={{ width: "10%" }}>
                  DOC
                  <span onClick={() => {
                    setMorerows(!moreRows)
                  }}>
                    {moreRows ? " [-]" : " [+]"}
                  </span>
                  <TextField
                    variant="standard"
                    onChange={(e) => setDocFilter(e.target.value)}
                  ></TextField>

                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filtered.map((e) => {
                odd = !odd;
                return (
                  <TableRow
                    onClick={() => {
                      console.log(e.id);
                      setCurrentTouroperatorId(e.id);

                    }}
                    style={{
                      backgroundColor:
                        currentTouroperatorId === e.id
                          ? "#029aee"
                          : odd
                            ? "#f0f0f0"
                            : undefined,
                    }}
                  >
                    <TableCell style={{ width: "3%" }}>
                      {e.touroperator ? e.touroperator : ""}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {e.name ? e.name : ""}
                    </TableCell>
                    <TableCell style={{ width: "7%" }}>
                      {e.label ? e.label : ""}
                    </TableCell>
                    <TableCell style={{ width: "8%" }}>
                      {e.touroperatorcoupon ? e.touroperatorcoupon : ""}
                    </TableCell>
                    <TableCell style={{ width: "7%" }}>
                      {e.actiongroup ? e.actiongroup : ""}
                    </TableCell>
                    <TableCell style={{ width: "12%" }}>
                      {e.booking_period ? e.booking_period : ""}
                    </TableCell>
                    <TableCell style={{ width: "13%" }}>
                      {e.using_period ? e.using_period : ""}
                    </TableCell>
                    <TableCell style={{ width: "7%" }}>
                      {e.typlabel ? e.typlabel : ""}
                    </TableCell>
                    <TableCell style={{ width: "7%" }}>
                      {e.onpagelabel ? e.onpagelabel : ""}
                    </TableCell>
                    <TableCell style={{ width: "6%" }}>
                      {e.sticker ? e.stickername : ""}
                    </TableCell>
                    <TableCell style={{ width: "5%" }}>
                      {e.status ? e.status : ""}
                    </TableCell>
                    <TableCell style={{ width: "5%" }}>
                      {e.checked ? e.checked : ""}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {e.doc ? e.doc : ""}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {currentAction.id && (
        <TouroperatorActionsGrid
          touroperatorcoupon={touroperatorcoupon}
          touroperatoractions={touroperatoractions}
          currentTouroperatorAction={currentAction}
          touroperatoractiontyp={touroperatoractiontyp}
          setDialog={setDialog}
          setEditField={setEditField}
          onpage={onpage}
          hotellists={hotellists}
          setTouroperatoractions={(obj) =>
            dispatch(setTouroperatoractions(obj))
          }
        />
      )}
    </>
  );
}


const EditDialog = (props) => {
  const { setDialog, dialog, touroperatoractions, dialogObject, editField, role } = props;
  const dispatch = useDispatch();
  const [dialogEditField, setDialogEditField] = React.useState(editField);

  React.useEffect(() => {
    setDialogEditField(editField);
  }, [editField]);

  return (
    <>
      {dialog.number && (
        <Dialog
          fullWidth={true}
          open={dialog.number ? true : false}
          onClose={() => setDialog({})}
        >
          <DialogTitle>{dialog.field}</DialogTitle>
          <DialogContent>
            <TextField
              multiline
              fullWidth
              rows={30}

              value={
                dialog.field === "properties"

                  ? dialogEditField
                  : dialogObject[dialog.field]
              }
              onChange={(evt) => {
                if (

                  dialog.field === "properties"
                ) {
                  setDialogEditField(evt.target.value);
                  return;
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            {(dialog.field === "properties") && (
              <Button
                onClick={() => {
                  try {
                    let jsontext = JSON.parse(dialogEditField);
                    setDialog({});

                    if (dialog.field === "properties") {

                      dispatch(
                        setTouroperatoractions(
                          [...touroperatoractions].map((o) =>
                            o[dialog.key] === dialog.number
                              ? { ...o, properties: jsontext, dirty: true, }
                              : o
                          )
                        )
                      );
                    }


                  } catch (e) { }
                }}
              >
                übernehmen
              </Button>
            )}
            <Button
              onClick={() => {
                setDialog({});
              }}
            >
              schliessen
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};