import { configureStore } from "@reduxjs/toolkit";
import systemReducer from "../features/systemSlice";
import dataReducer from "../features/dataSlice";


//import logger from "redux-logger";

export const store = configureStore({
  reducer: {
    system: systemReducer,
    data: dataReducer,

  }, middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    })
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  //devTools: process.env.NODE_ENV !== "production",
});
