import * as React from "react";
import Button from "@mui/material/Button";
import { FormattedDate } from "react-intl";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getHost } from "../utils";
import HotelActionsGrid from "./HotelActionsGrid";
import {
  duplicateAgencyaction,
  getHotelowneractions,
  getProperty,
  getHotellists,
  saveHotelactions,
  getPictures,
} from "../features/dataApiCalls";
import { setHotelactions } from "../features/dataSlice";

export default function HotelActionsPage(props) {
  const dispatch = useDispatch();
  //const [search, setSearch] = React.useState("");
  const role = useSelector((state) => state.system.role);
  const hotelactions = useSelector((state) => state.data.hotelactions);
  
  const [currentId, setCurrentId] = React.useState("");
  const [allitems, setAllitems] = React.useState("0");
  const agencyid = useSelector((state) => state.system.agencyid);
  const currentAction = hotelactions.find((e) => e.id === currentId)
    ? hotelactions.find((e) => e.id === currentId)
    : {};

  const properties = useSelector((state) => state.data.properties);
  const hotellists = useSelector((state) => state.data.hotellists);
  //TODO: HIer ist noch was zu tun
  React.useEffect(() => {
    dispatch(getProperty({ tag: "promotyp" }));
    dispatch(getProperty({ tag: "promoclass" }));
    dispatch(getProperty({ tag: "onpage" }));
    dispatch(getHotellists(""));
    dispatch(getPictures({ folderid: "" , common:1}));
  }, []);

  let odd = true;

  let promotyp = properties.promotyp;
  if (!Array.isArray(promotyp)) promotyp = [];
  let promoclass = properties.promoclass;
  if (!Array.isArray(promoclass)) promoclass = [];
  let onpage = properties.onpage;
  if (!Array.isArray(onpage)) onpage = [];

  console.log(currentAction);
 // wenn sich hotels einloggen ist ihre agencyid die giataid des hotels
  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <div><FormControlLabel control={<Checkbox checked={allitems==="1"} onChange={() => setAllitems(allitems=="0"?"1":"0")} />} label="all items" />
          <ButtonGroup style={{ marginBottom: 16 }} variant="contained">
            <Button onClick={() => dispatch(getHotelowneractions({ giataid: agencyid }))}>
              Get Data
            </Button>
            {(role == "admin" || role == "master" ) && (
              <>
            <Button onClick={() => dispatch(saveHotelactions()).then(dispatch(getHotelowneractions({ giataid: agencyid })))}>
              Save Data 
            </Button>
            <Button
              onClick={() => dispatch(duplicateAgencyaction({ id: currentId })).then(dispatch(getHotelowneractions({ giataid: agencyid })))}
            >
              Duplicate Data
            </Button></>)}
            <>
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      multiple
                      type="file"
                      onChange={() => {
                        const fileInput = document.querySelector(
                          "#raised-button-file"
                        );
                        const formData = new FormData();

                        formData.append("file", fileInput.files[0]);

                        const options = {
                          method: "POST",
                          body: formData,
                        };

                        fetch(
                          getHost() + "upload/00000000-0000-0000-0000-000000000000/picture",
                          options
                        )
                          .then((response) => {
                            return response.json();
                          })
                          .then((data) => {

                            dispatch(getPictures({ folderid: "00000000-0000-0000-0000-000000000000" }));
                          })
                          .catch((e) => {
                            console.log(e);
                          });
                      }}
                    />

                    <label htmlFor="raised-button-file">
                      <Button variant="contained" component="span">
                        Upload Sticker
                      </Button>
                    </label>
                    </>
          </ButtonGroup>
        </div>
      </div>
      <Paper
        style={{ marginBottom: 8 }}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        <TableContainer sx={{ maxHeight: 200 }}>
          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "15%" }}>Code</TableCell>
                <TableCell style={{ width: "20%" }}>Label</TableCell>
                <TableCell style={{ width: "10%" }}>Group</TableCell>
                <TableCell style={{ width: "20%" }}>WebText</TableCell>
                <TableCell style={{ width: "10%" }}>BookingPeriodTyp</TableCell>
                <TableCell style={{ width: "10%" }}>Typ</TableCell>
                <TableCell style={{ width: "10%" }}>PlaceOnPage</TableCell>
                <TableCell style={{ width: "5%" }}>Rank</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(hotelactions) &&
                hotelactions.map((e, idx) => {
                  odd = !odd;
                  return (
                    <TableRow
                      onClick={() => {
                        setCurrentId(e.id);

                      }}

                      key={idx}
                      style={{
                        backgroundColor:
                          currentId === e.id
                            ? "#029aee"
                            : odd
                              ? "#f0f0f0"
                              : undefined,
                      }}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell style={{ width: "15%" }}>
                        {e.code ? e.code : ""}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        {e.label ? e.label : ""}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                      {e.actiongroup ? e.actiongroup : ""}
                    </TableCell>    
                      <TableCell style={{ width: "20%" }}>
                        {e.properties
                          ? e.properties.text
                            ? e.properties.text
                            : ""
                          : ""}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {e.bookingperiod ? e.bookingperiod : ""}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {e.typlabel ? e.typlabel : ""}
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        {e.onpagelabel ? e.onpagelabel : ""}
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        {e.rank ? (e.rank ? e.rank : "") : ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {currentAction.id && (
        <HotelActionsGrid
          actions={hotelactions}
          currentAction={currentAction}
          promoclass={promoclass}
          promotyp={promotyp}
          onpage={onpage}
          hotellists = {hotellists}
          setActions={(obj) => dispatch(setHotelactions(obj))}
        />
      )}
    </>
  );
}
