import { Button, Grid, TextField } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import * as React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { askOpenAI } from "../features/dataApiCalls";

export default function AskOpenaiPage(props) {
  const promptRef = React.useRef();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const error = useSelector((state) => state.system.error);
  const chatanswer = useSelector((state) => state.data.chatanswer);

  return (<>
    <form
      onSubmit={(evt) => {
        evt.preventDefault();
        dispatch(
          askOpenAI(promptRef)
        )

          .catch((rejectedValueOrSerializedError) => {
            console.log(rejectedValueOrSerializedError);
          });
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={1} md={5}></Grid>
        <Grid item xs={10} md={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {error ? error : <span>&nbsp;</span>}
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="prompt" inputRef={promptRef} />
            </Grid>


            <Grid item xs={12}>
              <Button type="submit" variant="contained">
                send
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={5}></Grid>
      </Grid>
    </form>
    <div>  {chatanswer} </div>
  </>);
}
