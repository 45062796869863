import * as React from "react";

import { FormattedDate } from "react-intl";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getHost } from "../utils";
import { getCityInfo, getGiatainfos, saveCityProperties, saveHotelProperties } from "../features/dataApiCalls";
import { setGiatainfos } from "../features/dataSlice";

export default function GiataInfosPage(props) {
  const dispatch = useDispatch();
  const giatainfos = useSelector((state) => state.data.giatainfos);
  const [dialog, setDialog] = React.useState({});
  const [editField, setEditField] = React.useState("");
  const role = useSelector((state) => state.system.role);
  const [hotels, setHotels] = React.useState(false);
  let dialogObject = {};

  if (dialog.keyvalue) {
    dialogObject = giatainfos.find((o) => o[dialog.key] === dialog.keyvalue);
  }
  return (
    <>

      <Search setHotels={setHotels} hotels={hotels} />
      <DataTable
        setDialog={setDialog}
        dialog={dialog}
        giatainfos={giatainfos}
        setEditField={setEditField}
      />
    </>
  );
}

const Search = (props) => {
  const { setHotels, hotels } = props;
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState("");
  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault();
        dispatch(getGiatainfos({ search: search, hotels: hotels }));
      }}
    >
      <div style={{ display: "flex" }}>

        <div style={{ flexGrow: 1 }}>
          <TextField
            variant="standard"
            label={"search"}
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div><FormControlLabel control={<Checkbox checked={hotels} onChange={() => setHotels(!hotels)} />} label="Hotels" /></div>
        <div style={{ marginLeft: 16 }}>
          <Button
            style={{ marginBottom: 16 }}
            type="submit"
            variant="contained"
          >
            Get Data
          </Button>
        </div>
      </div>
    </form>
  );
};



const DataTable = (props) => {
  const { setDialog, dialog, giatainfos, setEditField } = props;
  const dispatch = useDispatch();

  let odd = true;
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
        <Table
          size="small"
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "50%" }}>fullname</TableCell>
              <TableCell style={{ width: "10%" }}>groupID</TableCell>
              <TableCell style={{ width: "10%" }}>regionID</TableCell>
              <TableCell style={{ width: "10%" }}>cityID</TableCell>
              <TableCell style={{ width: "10%" }}>giataID</TableCell>
              <TableCell style={{ width: "10%" }}>hotelGroup</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(giatainfos) &&
              giatainfos.map((e, idx) => {
                odd = !odd;
                return (
                  <TableRow
                    key={idx}
                    style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ width: "50%" }}>
                      {e.fullname ? e.fullname : ""}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {e.groupid ? e.groupid : ""}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {e.regionid ? e.regionid : ""}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {e.cityid ? e.cityid : ""}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {e.giataid ? e.giataid : ""}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {e.hotelgroup ? e.hotelgroup : ""}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
