import * as React from "react";

import { FormattedDate } from "react-intl";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
} from "@mui/material";
import { dateToInputString } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import CouponcodesTable from "./CouponcodesTable";
import { getHost } from "../utils";
import { getCoupons, getCouponcodes, saveCoupons, duplicateCoupon, getAgencyactions, generateCouponcodes } from "../features/dataApiCalls";
import { setCoupons } from "../features/dataSlice";

export default function CouponPage(props) {
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = React.useState("");
  const coupons = useSelector((state) => state.data.coupons);
  const [dialog, setDialog] = React.useState({});
  const [editField, setEditField] = React.useState("");
  const role = useSelector((state) => state.system.role);
  const agencyid = useSelector((state) => state.system.agencyid);
  const currentCoupon = coupons.find((e) => e.id === currentId)

  let dialogObject = {};

  React.useEffect(() => {
    dispatch(getAgencyactions(agencyid));
    dispatch(getCoupons({ allitems: "0" }));
  }, []);


  if (dialog.keyvalue) {
    dialogObject = coupons.find((o) => o[dialog.key] === dialog.keyvalue);
  }
  return (
    <>
      {dialog.keyvalue && (
        <EditDialog
          setDialog={setDialog}
          dialog={dialog}
          coupons={coupons}
          dialogObject={dialogObject}
          editField={editField}
          role={role}
        />
      )}

      <DataTable
        setDialog={setDialog}
        dialog={dialog}
        coupons={coupons}
        generateCouponcodes={generateCouponcodes}
        setCurrentId={setCurrentId}
        currentId={currentId}
        currentCoupon={currentCoupon}
        setEditField={setEditField}
      />
    </>
  );
}



const EditDialog = (props) => {
  const { setDialog, dialog, coupons, dialogObject, editField, role } = props;
  const dispatch = useDispatch();
  const [dialogEditField, setDialogEditField] = React.useState(editField);

  React.useEffect(() => {
    setDialogEditField(editField);
  }, [editField]);

  return (
    <>
      {dialog.keyvalue && (
        <Dialog
          fullWidth={true}
          open={dialog.keyvalue ? true : false}
          onClose={() => setDialog({})}
        >
          <DialogTitle>{dialog.field}</DialogTitle>
          <DialogContent>
            <TextField
              multiline
              fullWidth
              rows={30}

              value={
                dialogEditField

              }
              onChange={(evt) => {
                setDialogEditField(evt.target.value);
                return;

              }}
            />
          </DialogContent>
          <DialogActions>

            <Button
              onClick={() => {
                try {



                  if (dialog.field === "properties") {
                    let jsontext = JSON.parse(dialogEditField);

                    dispatch(
                      setCoupons(
                        [...coupons].map((o) =>
                          o[dialog.key] === dialog.keyvalue
                            ? { ...o, properties: jsontext, dirty: true }
                            : o
                        )
                      )
                    );
                  }

                  setDialog({});
                } catch (e) { }
              }}
            >
              save
            </Button>

            <Button
              onClick={() => {
                setDialog({});
              }}
            >
              close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const DataTable = (props) => {
  const { setDialog, dialog, coupons, currentCoupon, setEditField, currentId, setCurrentId, generateCouponcodes } = props;
  const dispatch = useDispatch();

  const [allitems, setAllitems] = React.useState("0");
  const agencyactions = useSelector((state) => state.data.agencyactions);
  const role = useSelector((state) => state.system.role);
  const couponcodes = useSelector((state) => state.data.couponcodes);
  const [moreRows, setMorerows] = React.useState(false);
  let odd = true;
  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>

        <div><FormControlLabel control={<Checkbox checked={allitems === "1"} onChange={() => setAllitems(allitems == "0" ? "1" : "0")} />} label="all items" />
          <ButtonGroup style={{ marginBottom: 16 }} variant="contained">
            <Button onClick={() => dispatch(getCoupons({ allitems: allitems }))}>
              Get Data
            </Button>

            {(role == "agencyadmin" || role == "admin" || role == "master") && (
              <>
                <Button onClick={() => dispatch(saveCoupons())}>
                  Save Data
                </Button>
                <Button
                  onClick={() => {
                    dispatch(duplicateCoupon({ id: currentId })).then(() =>
                      dispatch(getCoupons({ allitems: "N" })));
                  }}

                >
                  Duplicate Data
                </Button></>)}

          </ButtonGroup>
        </div>
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: moreRows ? 800 : 400 }}>

          <Table
            size="small"
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "5%" }}>id</TableCell>
                <TableCell style={{ width: "20%" }}>label</TableCell>
                <TableCell style={{ width: "20%" }}>code</TableCell>
                <TableCell style={{ width: "20%" }}>action</TableCell>
                <TableCell style={{ width: "10%" }}>validuntil</TableCell>
                <TableCell style={{ width: "10%" }}>properties</TableCell>
                <TableCell style={{ width: "10%" }}>status</TableCell>
                <TableCell style={{ width: "5%" }}></TableCell>
                <TableCell style={{ width: "5%" }}>
                  <span onClick={() => {
                    setMorerows(!moreRows)
                  }}>
                    {moreRows ? " [-]" : " [+]"}
                  </span></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(coupons) &&
                coupons.map((e, idx) => {
                  odd = !odd;
                  return (
                    <TableRow onClick={() => {
                      console.log(currentId);

                      console.log(e.id);
                      setCurrentId(e.id);
                      dispatch(getCouponcodes({ "id": e.id }));
                      console.log(currentId);
                    }}

                      key={idx}
                      style={{
                        backgroundColor:
                          currentId === e.id
                            ? "#029aee"
                            : odd
                              ? "#f0f0f0"
                              : undefined,
                      }}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell style={{ width: "5%" }}>
                        {e.id ? e.id : ""}
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        <TextField
                          fullWidth

                          value={e.label ? e.label : ""}
                          onChange={(evt) => {
                            let tmp = coupons.map((o, i) => {
                              if (o.id === e.id) {
                                return {
                                  ...o,
                                  label: evt.target.value,
                                  dirty: true,

                                };
                              } else {
                                return o;
                              }
                            });
                            dispatch(setCoupons(tmp));

                          }}
                        />
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        <TextField
                          fullWidth
                          value={e.coupon ? e.coupon : ""}
                          onChange={(evt) => {
                            let tmp = coupons.map((o, i) => {
                              if (o.id === e.id) {
                                return {
                                  ...o,
                                  coupon: evt.target.value,
                                  dirty: true,

                                };
                              } else {
                                return o;
                              }
                            });
                            dispatch(setCoupons(tmp));

                          }}
                        />
                      </TableCell>

                      <TableCell style={{ width: "20%" }}>

                        <Select
                          fullWidth
                          labelId="select-listid"
                          id="select-list-id"
                          value={e.actionid ? e.actionid : ""}
                          variant="standard"
                          onChange={(evt) => {
                            let tmp = coupons.map((o, i) => {
                              if (o.id === e.id) {
                                return {
                                  ...o,
                                  actionid: evt.target.value,
                                  dirty: true,
                                };
                              } else {
                                return o;
                              }
                            });
                            dispatch(setCoupons(tmp));
                          }}
                        >
                          {agencyactions.map((o) => (
                            <MenuItem key={o.id} value={o.id}>
                              {o.code}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        <TextField
                          fullWidth
                          label="validuntil"
                          type="date"

                          value={
                            e.validuntil
                              ? dateToInputString(e.validuntil)
                              : ""
                          }
                          onKeyDown={(e) => e.preventDefault()}

                          //value={e.validuntil ? e.validuntil : ""}
                          onChange={(evt) => {
                            let tmpdate = new Date(evt.target.value);
                            let tmp = coupons.map((o, i) => {
                              if (o.id === e.id) {
                                return {
                                  ...o,
                                  validuntil: tmpdate,
                                  dirty: true,

                                };
                              } else {
                                return o;
                              }
                            });
                            dispatch(setCoupons(tmp));

                          }}
                        />
                      </TableCell>
                      <TableCell style={{ width: "10%" }}>
                        <Button
                          style={{ color: e.properties ? "blue" : undefined }}
                          onClick={() => {
                            setDialog({
                              keyvalue: e.id,
                              key: "id",
                              field: "properties",
                            });
                            setEditField(
                              JSON.stringify(e.properties, null, 4)
                            );
                          }}
                        >
                          edit
                        </Button>
                      </TableCell>

                      <TableCell style={{ width: "10%" }}>
                        <TextField
                          fullWidth

                          value={e.status ? e.status : ""}
                          onChange={(evt) => {
                            let tmp = coupons.map((o, i) => {
                              if (o.id === e.id) {
                                return {
                                  ...o,
                                  status: evt.target.value,
                                  dirty: true,

                                };
                              } else {
                                return o;
                              }
                            });
                            dispatch(setCoupons(tmp));

                          }}

                        />

                      </TableCell>
                      <TableCell style={{ width: "5%" }}>
                        <Button disabled={!e.dirty}
                          onClick={() => {
                            dispatch(saveCoupons({ data: e })).then(e => dispatch(getCoupons()));
                            /*
                            dispatch(
                              setCoupons(
                                [...coupons].map((o) =>
                                  o.id === e.id
                                    ? {...o, dirty: false }
                        : o
                        )
  
                        )
                        );*/
                          }}
                        >
                          save
                        </Button>
                        <TableCell style={{ width: "5%" }}>
                          <Button disabled={e.coupon}
                            onClick={() => {
                              dispatch(generateCouponcodes({ "couponid": e.id }));
                            }}
                          >
                            generateCodes
                          </Button>
                        </TableCell>
                      </TableCell>

                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper >

      {currentCoupon?.id && (
        <CouponcodesTable
          couponcodes={couponcodes}
        />
      )}

    </>
  );
};

