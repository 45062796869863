import * as React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { dateToInputString } from "../utils";
import { useSelector } from 'react-redux';
export default function HotelAOwnerGrid(props) {
  const pictures = useSelector((state) => state.data.pictures);
  const { actions, currentAction, setActions, promotyp, promoclass, onpage, hotellists } =
    props;
  return (
    <Paper style={{ padding: 16 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={1}>
          <TextField
            fullWidth
            label="rank"
            value={currentAction.rank ? currentAction.rank : ""}
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? {
                    ...ele,
                    rank: evt.target.value,
                    dirty: true,
                  }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label="code"
            value={currentAction.code ? currentAction.code : ""}
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? { ...ele, code: evt.target.value, dirty: true }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="label"
            value={currentAction.label ? currentAction.label : ""}
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? { ...ele, label: evt.target.value, dirty: true }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="actioncode"
            value={
              currentAction.properties
                ? currentAction.properties.actioncode
                  ? currentAction.properties.actioncode
                  : ""
                : ""
            }
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? {
                    ...ele,
                    properties: {
                      ...ele.properties,
                      actioncode: evt.target.value,
                    },
                    dirty: true,
                  }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="actiongroup ( same group customer must choice )"
            value={currentAction.actiongroup ? currentAction.actiongroup : ""}
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? { ...ele, actiongroup: evt.target.value, dirty: true }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="text"
            value={
              currentAction.properties
                ? currentAction.properties.text
                  ? currentAction.properties.text
                  : ""
                : ""
            }
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? {
                    ...ele,
                    properties: {
                      ...ele.properties,
                      text: evt.target.value,
                    },
                    dirty: true,
                  }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="description"
            value={
              currentAction.properties
                ? currentAction.properties.description
                  ? currentAction.properties.description
                  : ""
                : ""
            }
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? {
                    ...ele,
                    properties: {
                      ...ele.properties,
                      description: evt.target.value,
                    },
                    dirty: true,
                  }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="mouseover"
            value={
              currentAction.properties
                ? currentAction.properties.mouseover
                  ? currentAction.properties.mouseover
                  : ""
                : ""
            }
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? {
                    ...ele,
                    properties: {
                      ...ele.properties,
                      mouseover: evt.target.value,
                    },
                    dirty: true,
                  }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label="bookingFrom"
            type="date"
            value={
              currentAction.booking_from
                ? dateToInputString(currentAction.booking_from)
                : ""
            }
            onKeyDown={(e) => e.preventDefault()}
            onChange={(evt) => {
              try {
                let tmp = new Date(evt.target.value);
                let act = actions.map((ele) =>
                  ele.id === currentAction.id
                    ? {
                      ...ele,
                      booking_from: tmp,
                      dirty: true,
                    }
                    : ele
                );
                setActions(act);
              } catch (e) { }
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label="bookingUntil"
            type="date"
            value={
              currentAction.booking_until
                ? dateToInputString(currentAction.booking_until)
                : ""
            }
            onKeyDown={(e) => e.preventDefault()}
            onChange={(evt) => {
              try {
                let tmp = new Date(evt.target.value);
                let act = actions.map((ele) =>
                  ele.id === currentAction.id
                    ? {
                      ...ele,
                      booking_until: tmp,
                      dirty: true,
                    }
                    : ele
                );
                setActions(act);
              } catch (e) { }
            }}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label="usingFrom"
            type="date"
            value={
              currentAction.using_from
                ? dateToInputString(currentAction.using_from)
                : ""
            }
            onKeyDown={(e) => e.preventDefault()}
            onChange={(evt) => {
              try {
                let tmp = new Date(evt.target.value);
                let act = actions.map((ele) =>
                  ele.id === currentAction.id
                    ? {
                      ...ele,
                      using_from: tmp,
                      dirty: true,
                    }
                    : ele
                );
                setActions(act);
              } catch (e) { }
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label="usingUntil"
            type="date"
            value={
              currentAction.using_until
                ? dateToInputString(currentAction.using_until)
                : ""
            }
            onKeyDown={(e) => e.preventDefault()}
            onChange={(evt) => {
              try {
                let tmp = new Date(evt.target.value);
                let act = actions.map((ele) =>
                  ele.id === currentAction.id
                    ? {
                      ...ele,
                      using_until: tmp,
                      dirty: true,
                    }
                    : ele
                );
                setActions(act);
              } catch (e) { }
            }}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label="validFrom"
            type="date"
            value={
              currentAction.valid_from
                ? dateToInputString(currentAction.valid_from)
                : ""
            }
            onKeyDown={(e) => e.preventDefault()}
            onChange={(evt) => {
              try {
                let tmp = new Date(evt.target.value);
                let act = actions.map((ele) =>
                  ele.id === currentAction.id
                    ? {
                      ...ele,
                      valid_from: tmp,
                      dirty: true,
                    }
                    : ele
                );
                setActions(act);
              } catch (e) { }
            }}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label="validUntil"
            type="date"
            value={
              currentAction.valid_until
                ? dateToInputString(currentAction.valid_until)
                : ""
            }
            onKeyDown={(e) => e.preventDefault()}
            onChange={(evt) => {
              try {
                let tmp = new Date(evt.target.value);
                let act = actions.map((ele) =>
                  ele.id === currentAction.id
                    ? {
                      ...ele,
                      valid_until: tmp,
                      dirty: true,
                    }
                    : ele
                );
                setActions(act);
              } catch (e) { }
            }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            multiline
            rows={2}
            label="condition"
            value={currentAction.condition ? currentAction.condition : ""}
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? {
                    ...ele,
                    condition: evt.target.value,
                    dirty: true,
                  }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            multiline
            rows={2}
            label="pricerange  ( > x , < x , between x and y )"
            value={currentAction.pricerange ? currentAction.pricerange : ""}
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? {
                    ...ele,
                    pricerange: evt.target.value,
                    dirty: true,
                  }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            multiline
            rows={2}
            label="touroperators  (comma sperated list e.g. 'DER','TUI')"
            value={
              currentAction.touroperators ? currentAction.touroperators : ""
            }
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? {
                    ...ele,
                    touroperators: evt.target.value,
                    dirty: true,
                  }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            multiline
            rows={2}
            label="departures  (comma sperated list e.g. 'HAM','BER')"
            value={
              currentAction.departures ? currentAction.departures : ""
            }
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? {
                    ...ele,
                    departures: evt.target.value,
                    dirty: true,
                  }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            multiline
            rows={2}
            label="destinations  (comma sperated list e.g. 'ATY','KZY')"
            value={
              currentAction.destinations ? currentAction.destinations : ""
            }
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? {
                    ...ele,
                    destinations: evt.target.value,
                    dirty: true,
                  }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <FormControl fullWidth>
            <InputLabel id="select-typesl">Typ</InputLabel>
            <Select
              fullWidth
              labelId="select-typesl"
              id="select-types"
              value={
                currentAction.properties
                  ? currentAction.properties.typ
                    ? currentAction.properties.typ
                    : ""
                  : ""
              }
              variant="standard"
              onChange={(evt) => {
                let act = actions.map((ele) =>
                  ele.id === currentAction.id
                    ? {
                      ...ele,
                      properties: {
                        ...ele.properties,
                        typ: evt.target.value,
                      },
                      dirty: true,
                    }
                    : ele
                );
                setActions(act);
              }}
            >
              {promotyp.map((o) => (
                <MenuItem key={o.propertyid} value={o.propertyid}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={1}>
          <FormControl fullWidth>
            <InputLabel id="select-typid">Typ</InputLabel>
            <Select
              fullWidth
              labelId="select-typid"
              id="select-typ-id"
              value={currentAction.typ_id ? currentAction.typ_id : ""}
              variant="standard"
              onChange={(evt) => {
                let act = actions.map((ele) =>
                  ele.id === currentAction.id
                    ? {
                      ...ele,

                      typ_id: evt.target.value,
                      dirty: true,
                    }
                    : ele
                );
                setActions(act);
              }}
            >
              {promoclass.map((o) => (
                <MenuItem key={o.propertyid} value={o.propertyid}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={1}>
          <FormControl fullWidth>
            <InputLabel id="select-typid">Plazierung</InputLabel>
            <Select
              fullWidth
              labelId="select-onpage"
              id="select-onpage"
              value={currentAction.onpage ? currentAction.onpage : ""}
              variant="standard"
              onChange={(evt) => {
                let act = actions.map((ele) =>
                  ele.id === currentAction.id
                    ? {
                      ...ele,

                      onpage: evt.target.value,
                      dirty: true,
                    }
                    : ele
                );
                setActions(act);
              }}
            >
              {onpage.map((o) => (
                <MenuItem key={o.propertyid} value={o.propertyid}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <InputLabel id="select-typid">Sticker</InputLabel>
          <Select
            fullWidth
            labelId="select-typesl"
            id="select-types"
            value={
              currentAction.sticker ? currentAction.sticker : ""
            }
            variant="standard"

            onChange={(evt) => {
              let act = actions.map((ele, i) => {
                if (ele.id === currentAction.id) {
                  return {
                    ...ele,
                    sticker: evt.target.value,
                    dirty: true
                  };
                } else {
                  return ele;
                }
              });
              setActions(act);
            }}
          >
            {(Array.isArray(pictures[currentAction.folderid]) ? pictures[currentAction.folderid] : []).map((o) => (
              <MenuItem key={o.url} value={o.url}>
                {o.filename}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel id="select-listid">Hotellist</InputLabel>
            <Select
              fullWidth
              labelId="select-listid"
              id="select-list-id"
              value={currentAction.hotellistid ? currentAction.hotellistid : ""}
              variant="standard"
              onChange={(evt) => {
                let act = actions.map((ele) =>
                  ele.id === currentAction.id
                    ? {
                      ...ele,

                      hotellistid: evt.target.value,
                      dirty: true,
                    }
                    : ele
                );
                setActions(act);
              }}
            >
              {hotellists.map((o) => (
                <MenuItem key={o.id} value={o.id}>
                  {o.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={1}>
          <TextField
            fullWidth
            label="retention (x hour, x day)"
            value={currentAction.retention ? currentAction.retention : ""}
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? {
                    ...ele,
                    retention: evt.target.value,
                    dirty: true,
                  }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>

        <Grid item xs={12} md={1}>
          <TextField
            fullWidth
            label="coupons"
            value={currentAction.coupons ? currentAction.coupons : ""}
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? {
                    ...ele,
                    coupons: evt.target.value,
                    dirty: true,
                  }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label="value"
            value={
              currentAction.properties
                ? currentAction.properties.value
                  ? currentAction.properties.value
                  : ""
                : ""
            }
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? {
                    ...ele,
                    properties: {
                      ...ele.properties,
                      value: evt.target.value,
                    },
                    dirty: true,
                  }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            multiline
            rows={2}
            label="request"
            value={currentAction.request ? currentAction.request : ""}
            onChange={(evt) => {
              let act = actions.map((ele) =>
                ele.id === currentAction.id
                  ? {
                    ...ele,
                    request: evt.target.value,
                    dirty: true,
                  }
                  : ele
              );
              setActions(act);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {currentAction.errormsg}
        </Grid>
      </Grid>
    </Paper>
  );
}
