import * as React from "react";

import { useSelector } from 'react-redux';
import {
  FormControl,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";

import { useDispatch } from "react-redux";
import {
  makeStyles
}
  from "@mui/styles";

import {
  resetPwd,
} from "../features/dataApiCalls";


const useStyles = makeStyles(theme => ({
  textarea: {
    resize: "both"
  }
}));


export default function UserGrid(props) {
  const classes = useStyles();
  const pictures = useSelector((state) => state.data.pictures);
  const dispatch = useDispatch();
  const { users, currentUser, setUsers, userrole,role } =
    props;
  return (
    <Paper style={{ padding: 16 }}>
      <Grid container spacing={3}>

        <Grid item xs={12} md={1}>
          <TextField
            fullWidth
            label="DisplayOrder"
            value={currentUser.properties?.displayorder ? currentUser.properties.displayorder : ""}
            onChange={(evt) => {
              let act = users.map((ele) =>
                ele.userhash === currentUser.userhash
                  ? {
                    ...ele, properties: { ...ele.properties, displayorder: evt.target.value },
                    dirty: true,
                  }
                  : ele
              );
              setUsers(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label="NickName"
            value={currentUser.nickname ? currentUser.nickname : ""}
            onChange={(evt) => {
              let act = users.map((ele) =>
                ele.userhash === currentUser.userhash
                  ? {
                    ...ele,
                    nickname: evt.target.value,
                    dirty: true,
                  }
                  : ele
              );
              setUsers(act);
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="DisplayName"
            value={currentUser.properties?.displayname ? currentUser.properties.displayname : ""}
            onChange={(evt) => {
              let act = users.map((ele) =>
                ele.userhash === currentUser.userhash
                  ? {
                    ...ele, properties: { ...ele.properties, displayname: evt.target.value },
                    dirty: true,
                  }
                  : ele
              );
              setUsers(act);
            }}
          />
        </Grid>


        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="DisplayProfession"
            value={currentUser.properties?.displayprofession ? currentUser.properties.displayprofession : ""}
            onChange={(evt) => {
              let act = users.map((ele) =>
                ele.userhash === currentUser.userhash
                  ? {
                    ...ele, properties: { ...ele.properties, displayprofession: evt.target.value },
                    dirty: true,
                  }
                  : ele
              );
              setUsers(act);
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="DisplayEmail"
            value={currentUser.properties?.displayemail ? currentUser.properties.displayemail : ""}
            onChange={(evt) => {
              let act = users.map((ele) =>
                ele.userhash === currentUser.userhash
                  ? {
                    ...ele, properties: { ...ele.properties, displayemail: evt.target.value },
                    dirty: true,
                  }
                  : ele
              );
              setUsers(act);
            }}
          />
        </Grid>


        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="DisplayPhone"
            value={currentUser.properties?.displayphone ? currentUser.properties.displayphone : ""}
            onChange={(evt) => {
              let act = users.map((ele) =>
                ele.userhash === currentUser.userhash
                  ? {
                    ...ele, properties: { ...ele.properties, displayphone: evt.target.value },
                    dirty: true,
                  }
                  : ele
              );
              setUsers(act);
            }}
          />
        </Grid>


        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="ShortSalutaion"
            value={currentUser.properties?.shortsalutaion ? currentUser.properties.shortsalutaion : ""}
            onChange={(evt) => {
              let act = users.map((ele) =>
                ele.userhash === currentUser.userhash
                  ? {
                    ...ele, properties: { ...ele.properties, shortsalutaion: evt.target.value },
                    dirty: true,
                  }
                  : ele
              );
              setUsers(act);
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="LongSalutaion"
            value={currentUser.properties?.longsalutaion ? currentUser.properties.longsalutaion : ""}
            onChange={(evt) => {
              let act = users.map((ele) =>
                ele.userhash === currentUser.userhash
                  ? {
                    ...ele, properties: { ...ele.properties, longsalutaion: evt.target.value },
                    dirty: true,
                  }
                  : ele
              );
              setUsers(act);
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputLabel id="select-typid">Portrait</InputLabel>
          <Select
            fullWidth
            labelId="select-typesl"
            id="select-types"
            value={
              currentUser.properties?.portrait ? currentUser.properties.portrait : ""
            }
            variant="standard"

            onChange={(evt) => {
              let usr = users.map((ele, i) => {
                if (ele.userhash === currentUser.userhash) {
                  return {
                    ...ele, properties: { ...ele.properties, portrait: evt.target.value },
                    dirty: true
                  };
                } else {
                  return ele;
                }
              });
              setUsers(usr);
            }}
          >
            {(Array.isArray(pictures[currentUser.folderid]) ? pictures[currentUser.folderid] : []).map((o) => (
              <MenuItem key={o.url} value={o.url}>
                {o.filename}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={4}>
          <p>Login/Logout Code</p>
          <img src={currentUser.properties?.checkincode} width="150px"></img><br />
          {currentUser.properties?.checkincode}
        </Grid>

        <Grid item xs={12} md={4}>
          <p>vCard</p>
          <img src={currentUser.properties?.vcfcode} width="150px"></img><br />
          {currentUser.properties?.vcfcode}
        </Grid>
        <Grid item xs={12} md={4}>
          <p>Portrait</p>
          <img src={currentUser.properties?.portrait} width="150px"></img><br />
          {currentUser.properties?.portrait}
        </Grid>
        <Grid item xs={10} md={10}>
          <TextField
            fullWidth
            label="StopperConditions"
            value={currentUser.properties?.stopperconditions ? currentUser.properties.stopperconditions : ""}
            onChange={(evt) => {
              let act = users.map((ele) =>
                ele.userhash === currentUser.userhash
                  ? {
                    ...ele, properties: { ...ele.properties, stopperconditions: evt.target.value },
                    dirty: true,
                  }
                  : ele
              );
              setUsers(act);
            }}
          />
        </Grid>
        <Grid item xs={2} md={2}>
          
          {(role == "agencyadmin" || role == "admin" || role == "master") && (
            <>
              <Button onClick={() => { dispatch(resetPwd(currentUser.userhash)) }}>
                Reset Password
              </Button>
            </>)}
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            multiline
            rows={6}
            inputProps={{ className: classes.textarea }}
            label="vcf"
            value={
              currentUser.properties?.vcf
                ? currentUser.properties?.vcf
                : ""
            }
            onChange={(evt) => {
              let act = users.map((ele) =>
                ele.userhash === currentUser.userhash
                  ? {
                    ...ele, properties: { ...ele.properties, vcf: evt.target.value },
                    dirty: true,
                  }
                  : ele
              );
              setUsers(act);
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
