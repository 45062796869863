import * as React from 'react';
import { FormattedDate } from 'react-intl';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, MenuItem, DialogTitle, Paper, Popper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { getTranslations, saveTranslations } from '../features/dataApiCalls';
import { setTranslations } from '../features/dataSlice';


export default function TranslationPage(props) {


    const dispatch = useDispatch();
    const translations = useSelector((state) => state.data.translations);
    const [dialog, setDialog] = React.useState({});
    const [editField, setEditField] = React.useState("");
    const role = useSelector((state) => state.system.role);

    let dialogObject = {};

    if (dialog.number) {
        dialogObject = translations.find((o) => o[dialog.key] === dialog.number);
    }
    return (
        < >
            <Search translations={translations} role={role} />
            <DataTable translations={translations} setEditField={setEditField} role={role} />


        </ >
    );
}

const Search = (props) => {
    const { role } = props;
    const dispatch = useDispatch();
    const [search, setSearch] = React.useState("");
    return (<div style={{ display: "flex" }}><div style={{ flexGrow: 1 }}><form onSubmit={(evt) => {
        evt.preventDefault();
        dispatch(getTranslations({ search: search }));
    }
    } ><div style={{ display: "flex", flexGrow: 1 }}>
            <div style={{ flexGrow: 1 }}>
                <TextField variant="standard" label={"search "} fullWidth value={search} onChange={(e) => setSearch(e.target.value)} /></div><div style={{ marginLeft: 16 }}>
                <Button style={{ marginBottom: 16 }} type="submit" variant="contained">Get Data</Button></div></div></form ></div>
        {(role == "admin" || role == "master") && (
            <>
                <div style={{ marginLeft: 16 }}>
                    <Button style={{ marginBottom: 16 }} onClick={() => dispatch(saveTranslations())} variant="contained">Save all</Button></div ></>)}
    </div >)
}



const DataTable = (props) => {
    const { setDialog, dialog, translations, setEditField, role } = props;
    const dispatch = useDispatch();
    let typ = ["package", "accommodation", "cruise", "fewo"];
    let odd = true;
    return (<Paper sx={{ width: '100%', overflow: 'hidden' }}>

        <TableContainer sx={{ maxHeight: window.innerHeight - 150 }}>
            <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: "40%" }}>key</TableCell>
                        <TableCell style={{ width: "20%" }}>language</TableCell>
                        <TableCell style={{ width: "40%" }}>value</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(translations) &&
                        translations.map((obj, idx) => {
                            odd = !odd;
                            return (
                                <TableRow
                                    key={idx}
                                    style={{ backgroundColor: odd ? "#f0f0f0" : undefined }}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >

                                    <TableCell style={{ width: "40%" }}>
                                        {obj.key ? obj.key : ""}
                                    </TableCell>
                                    <TableCell style={{ width: "20%" }}>
                                        {obj.language ? obj.language : ""}
                                    </TableCell>
                                    <TableCell style={{ width: "40%" }}>
                                        <TextField variant="standard"
                                            value={obj.value ? obj.value : ""}
                                            onChange={(evt) => {
                                                let tmp = translations.map((o, i) => {
                                                    if (o.id === obj.id) {
                                                        return {
                                                            ...o,
                                                            value: evt.target.value,
                                                            dirty: true
                                                        };
                                                    } else {
                                                        return o;
                                                    }
                                                });
                                                dispatch(setTranslations(tmp));
                                            }}
                                        />
                                    </TableCell>

                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Paper >
    )
}